// Dialogs
//
// Display an action that triggers an overlay with content. Useful for photo/video galleries, prompts, etc.
// 
// Markup:
// <!-- Inline Content -->
// <div id="test-popup" class="white-popup mfp-hide">
//   Popup content
// </div>
// <a href="#test-popup" class="open-popup-link">Show inline popup</a><br />
// <!-- Single Image -->
// <a href="img/p_kitten.jpg" title="Caption for image" data-toggle="modal image" data-effect="mfp-zoom-out">Open image</a><br />
// <!-- Ajax Content -->
// <a href="../ajax-demo.html" class="ajax-popup-link">Show ajax content</a>
// 
// Styleguide 4.8

// Magnific Popup overrides
.mfp-bg {
}

.mfp-image-holder {
  position: relative;
  .mfp-bottom-bar {
    position: absolute;
    bottom: 40px;
    width: 100%;
    padding: 10px;
    margin-top: 0;
    top: auto;
    background: rgba(0, 0, 0, 0.6);
  }
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

.mfp-zoom-out {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(1.3);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }
}