// Search Results Stats
.filter-results-stats {
  @include rem(margin, -30px 0 15px);
  @include rem(font-size, 18px);

  @include media($tablet) {
    @include rem(font-size, 24px);
  }
  
  strong {
    @include rem(font-size, 24px);
  
    @include media($tablet) {
      @include rem(font-size, 36px);
    }
  }
}

// Search Options
.filter-options-toggle {
  padding-right: 15px !important;

  @include media($desktop) {
    display: none;
  }

  &:before {
    display: none;
  }
}
.filter-options {
  border-bottom: 3px solid $color_core_off_white;
  @include rem(padding, 30px 0);
  @include rem(margin, 0 0 60px); 

  @include media($desktop) {
    @include rem(padding, 0 0 30px);
  }

  &.is-hidden {
    height: 0;
    padding: 0;
    overflow: hidden;
    @include rem(margin, 30px 0);
  }
  .fieldgroup {
    @include media($tablet) {
      width: 100%;
      @include display(flex);
      @include flex-wrap(wrap);
    }
  }
  .fieldgroup + div {
    @include media($tablet) {
      clear: left;
    }
  }
  .field {
    @include media($tablet) {
      width: 50%;
    }
    @include media($desktop) {
      width: auto;
      @include rem(margin-left, 30px);
    }
  }
  .field.flex {
    @include media($desktop) {
      @include flex-grow(2);
    }
  }
  .field:first-child {
    @include media($desktop) {
      margin-left: 0;
    }
  }
  .section-title {
    color: $color_core_white;
    @include rem(font-size, 24px);
  }
}

.filter-results-stats {
  span {
    color: $accent_link;
  }
}
.filter-options {
  &.vertical-filter {
    border: 0;
    
    @include media($desktop) {
      @include span-columns(4 of 12);
    }
    .fieldgroup {
      background: $color_core_blue;
      @include rem(padding, 0 30px);
      @include rem(padding-bottom, 30px);
      border-radius: $border-radius;
      color: $color_core_white;

      
    }
    ul {
      margin: 0;
      padding:0;
      
    }
    .section-title {
      &:after {
        background-color: $color_core_white;
      }
    }

    
    .field {
        margin-left: 0;
        width: 100%;
      }
  }
  
}

// Datepicker
.ui-datepicker .ui-datepicker-title select {
  width: auto;
  margin: 0 5px;
}

// Multi-select
.select2 {
  background-color: $color_core_white;
  min-width: 150px;
  width: 100%;

  .selection, input { width: 100%; }

  .select2-selection__choice__remove {
    vertical-align: baseline;
    font-size: 18px;
    margin-right: 4px;
  }

  .select2-selection,
  &.select2-container--open .select2-selection,
  &.select2-container--focus .select2-selection {
    border-radius: 4px;
    border: 2px solid #092940;
  }

  &.select2-container--open .select2-selection,
  &.select2-container--focus .select2-selection {
    border-color: #397aac;
  }

  .select2-selection:hover {
    background-color: #ebf3f9;
    border-color: #397aac;
  }

  .select2-search {
    margin: 5px 0 0;
    .select2-search__field { margin: 0; }
  }
}
.select2-container{
  .select2-results__option--highlighted[aria-selected] {
    background-color: #397aac;
  }
  .select2-dropdown {
    border: 2px solid #397aac;
  }
  .select2-dropdown--above {
    border-bottom: none;
  }
  .select2-dropdown--below {
    border-top: none;
  }
  li.select2-results__option {
    font-size: 14px;
    line-height: 1.5em;
    margin: 0;
    padding: 5px 0 5px 15px;
  }
  li.select2-results__option span:before {
    content: " ";
    display: inline-block;
    width: 20px;
  }
  li.select2-results__option[aria-selected=true] span:before {
    content: "\2713";
  }
}