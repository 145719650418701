// Search Results
.results {
  @include media($desktop) {
    @include span-columns(6 of 12);
  }

  article {
    border-bottom: 1px solid $color_core_off_white;
    @include rem(padding, 30px 0);

    [itemprop="name"] {
      font-family: $primaryFontMedium;
      @include rem(margin, 0 0 10px);
      @include rem(font-size, 21px);
    }
    .meta {
      @include rem(margin, 0 0 10px);
    }
    [itemprop="description"] {
      margin: 0;
    }
  }
  article.featured {
    position: relative;
    background-color: lighten($color_accent_blue, 50%);
    border-color: $color_core_white;
    @include rem(padding, 30px);
  }

  .results-images {
    border-top: 3px solid $color_core_off_white;
    border-bottom: 4px solid $color_core_off_white;
    @include rem(padding, 30px 0);

    h3 {
      margin: 0;
    }
    ul {
      padding: 0;
      list-style: none;
      font-size: 0;
      line-height: 0;
      @include rem(margin, 30px 0);

      li {
        display: inline-block;
        width: 49%;
        margin: 0 1% 1% 0;

        @include media($tablet) {
          width: 24%;
        }
      }

    }

  }
  .results-trending {
    border-top: 3px solid $color_core_off_white;
    @include rem(padding, 30px 0);

    h3 {
      margin: 0;
    }
    ul {
      padding: 0;
      list-style: none;
      @include rem(margin, 30px 0);

      @include media($tablet) {
        font-size: 0;
        line-height: 0;
      }

      li {
        @include media($tablet) {
          display: inline-block;
          width: 49%;
          margin: 0 1% 1% 0;
          line-height: 1.5;
          @include rem(font-size, 16px);
        }
      }

    }

  }

}