//==================================================================
// Navigations Partial
// Styles for various types of navigation componenets.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Primary Navigation
// =================================================================
nav.primary {
  background-image: url('../img/bg_poly-core-blue.jpg');
  background-color: #092940;
  background-image: url(../img/bg_poly-core-blue.jpg);
  background-position: center;
  height: 80px;
  height: 5rem;
  width: 100%;
  ul {
    list-style: none;
    li {

    }
  }
}

// Footer Navigation
// =================================================================
nav.footer {
}

// Utility Navigation
// =================================================================
nav.utility {
}

// Breadcrumbs
// 
// Breadcrumbs come in handy to show a navigation trail for users clicking through a site or app.
// 
// Markup:
// <div id="breadcrumbs" class="breadcrumbs">
//   <div class="wrapper" itemscope itemtype="http://data-vocabulary.org/Breadcrumb">
//     <a href="http://www.example.com/books" itemprop="url">
//       <span itemprop="title">Books</span>
//     </a> &raquo;
//     <div itemprop="child" itemscope itemtype="http://data-vocabulary.org/Breadcrumb">
//       <a href="http://www.example.com/books/authors" itemprop="url">
//         <span itemprop="title">Authors</span>
//       </a> &raquo;
//       <div itemprop="child" itemscope itemtype="http://data-vocabulary.org/Breadcrumb">
//           <span itemprop="title">Stephen King</span>
//       </div>
//     </div>
//   </div>
//   <button data-toggle="breadcrumbs icon-add" aria-hidden="true"></button>
// </div>
// 
// Styleguide 4.4

.breadcrumbs {
  position: relative;
  border-bottom: 1px solid $color_core_off_white;
  font-size: 0;
  line-height: 0;
  color: $color_core_gray;

  @include media($desktop) {
    border: 0;
    line-height: 1.2;
    @include rem(font-size, 14px);
  }

  > .wrapper { padding: 0; }
  > .wrapper > [itemprop="url"] {
    @include media($desktop) {
      padding-left: 0;
    }
  }

  [itemprop="url"] {
    display: block;
    @include rem(padding, 24px 30px 24px 70px);

    @include media($desktop) {
      display: inline-block;
      vertical-align: middle;
      @include rem(padding, 24px 15px);
    }

    [itemprop="title"] {
      padding: 0;
    }
  }
  [itemprop="title"] {
    display: block;
    @include rem(padding, 24px 30px 24px 70px);
    @include rem(font-size, 14px);
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media($desktop) {
      @include rem(padding, 24px 15px);
    }
  }
  [itemprop="child"] {
    border-top: 1px solid $color_core_off_white;

    @include media($desktop) {
      display: inline-block;
      border: 0;
      vertical-align: middle;
    }

    > [itemprop="title"] {
      @extend .icon-label-outline;
      position: relative;
      @include rem(padding-left, 70px);

      @include media($desktop) {
        @include rem(padding, 24px 15px);
      }

      &:before {
        position: absolute;
        top: 50%;
        @include rem(left, 30px);
        margin: -18px 0 0 0;
        font-size: 30px;
        color: $color_core_gray;
        font-family: 'icomoon';

        @include media($desktop) {
          display: none;
        }
      }
    }
  }

  [data-toggle*="breadcrumbs"] {
    @extend .icon-add;
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 0;
    padding: 0;
    background: none;
    border-radius: 0;
    line-height: 1.2;
    @include rem(width, 66px);
    @include rem(height, 66px);
    @include rem(font-size, 14px);

    @include media($desktop) {
      display: none;
    }

    &.active {
      @extend .icon-remove;
    }
    &:before {
      position: absolute;
      font-size: 24px;
      left: 50%;
      top: 50%;
      margin: -12px 0 0 -12px;
      color: $color_core_gray;
      font-family: 'icomoon';
    }
  }

}