// Document Types
//
// <ul class="styleguide doctypes">
//   <li>
//     <span class="icon-doctype icon-doctype-doc" aria-hidden="true"></span>
//     <p>doc</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-doc-alt" aria-hidden="true"></span>
//     <p>doc alt</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-pdf" aria-hidden="true"></span>
//     <p>pdf</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-pdf-alt" aria-hidden="true"></span>
//     <p>pdf alt</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-photo" aria-hidden="true"></span>
//     <p>photo</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-photo-alt" aria-hidden="true"></span>
//     <p>photo alt</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-ppt" aria-hidden="true"></span>
//     <p>ppt</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-ppt-alt" aria-hidden="true"></span>
//     <p>ppt alt</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-text" aria-hidden="true"></span>
//     <p>text</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-text-alt" aria-hidden="true"></span>
//     <p>text alt</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-xls" aria-hidden="true"></span>
//     <p>xls</p>
//   </li>
//   <li>
//     <span class="icon-doctype icon-doctype-xls-alt" aria-hidden="true"></span>
//     <p>xls alt</p>
//   </li>
// </ul>
//
// Markup:
// <span class="icon-doctype icon-doctype-doc" aria-hidden="true"></span>
//
// Styleguide 1.4.2

.icon-doctype {
  display: inline-block;
  @include rem(width, 32px);
  @include rem(height, 32px);

  &.icon-doctype-doc { background-image: url('../img/document-types/i_doctype-doc.svg'); }
  &.icon-doctype-doc-alt { background-image: url('../img/document-types/i_doctype-doc-alt.svg'); }
  &.icon-doctype-pdf { background-image: url('../img/document-types/i_doctype-pdf.svg'); }
  &.icon-doctype-pdf-alt { background-image: url('../img/document-types/i_doctype-pdf-alt.svg'); }
  &.icon-doctype-photo { background-image: url('../img/document-types/i_doctype-photo.svg'); }
  &.icon-doctype-photo-alt { background-image: url('../img/document-types/i_doctype-photo-alt.svg'); }
  &.icon-doctype-ppt { background-image: url('../img/document-types/i_doctype-ppt.svg'); }
  &.icon-doctype-ppt-alt { background-image: url('../img/document-types/i_doctype-ppt-alt.svg'); }
  &.icon-doctype-text { background-image: url('../img/document-types/i_doctype-text.svg'); }
  &.icon-doctype-text-alt { background-image: url('../img/document-types/i_doctype-text-alt.svg'); }
  &.icon-doctype-xls { background-image: url('../img/document-types/i_doctype-xls.svg'); }
  &.icon-doctype-xls-alt { background-image: url('../img/document-types/i_doctype-xls-alt.svg'); }
}