// Variables
//--------------------------------------------------------------*/
$table_caption_color: $color_core_gray;
$table_caption_padding: 0;
$table_caption_margin: 0 0 10px;

$table_cell_padding: 4px 10px 6px;
$table_cell_border: 1px solid $color_core_light_gray;

$table_header_background: $color_core_blue;
$table_header_color: #fff;
$table_header_padding: 6px 8px;

$table_frozen_column_width: 300px;

$table_alt_row_background: $color_core_off_white;
//--------------------------------------------------------------*/

table {
  width: 100% !important;
  @include rem(margin, 30px 0);

  caption {
    padding: $table_caption_padding;
    margin: $table_caption_margin;
    color: $table_caption_color;
    text-align: left;
  }

  th, td {
    padding: $table_cell_padding;
    border: $table_cell_border;
    font-weight: normal;
    text-align: left;
    @include rem(font-size, 14px);
    white-space: nowrap;
  }

  th {
    font-family: $primaryFontMedium;
  }
  thead {
    th, td {
      background: $table_header_background;
      border-color: lighten($table_header_background, 10);
      color: $table_header_color;
    }
  }

  tbody tr:nth-child(even) th,
  tbody tr:nth-child(even) td {
    background: $table_alt_row_background;
  }

  &.equal-cols {
    table-layout: fixed;
  }
}

.table-wrapper {
  clear: both;
  width: 100%;
  overflow-x: auto;

  background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)), linear-gradient(to left, white, rgba(255, 255, 255, 0)),
                    linear-gradient(to right, #c3c3c5, rgba(195, 195, 197, 0)), linear-gradient(to left, #c3c3c5, rgba(195, 195, 197, 0));
  background-position: 0 0, 100% 0, 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 4em 100%, 4em 100%, 1em 100%, 1em 100%;
  background-attachment: local, local, scroll, scroll;

  table {
    width: 100%;
    margin: 0;

    th, td {
      max-width: 600px;
      overflow: hidden;
      text-overflow: ellipsis;
      input {
        margin-right: 2px;
        vertical-align: middle;
      }
    }

    thead {
      th, td {
        @include rem(height, 30px);
        @include rem(font-size, 12px);
        padding: $table_header_padding;
      }
    }
  }

  &::-webkit-scrollbar {
    background-color: #e9e9e9;
    z-index: 9999;
    position: relative;
  }
  &::-webkit-scrollbar:hover {
    background-color: #e9e9e9;
  }
  &::-webkit-scrollbar-thumb {
    min-height: 14px;
    min-width: 14px;
    -webkit-border-radius: 8px;
    background-color: #bbb;
    border: 2px solid #e9e9e9;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #888;
  }
  &::-webkit-resizer {
    -webkit-border-radius: 8px;
    background-color: #666;
  }

  a.editable-click {
    &:hover {
      cursor: pointer;
      border-bottom: 0;
    }
  }
}

.freeze-wrapper {
  padding-left: $table_frozen_column_width;
  position: relative;

  table {
    th, td {
      &:first-child {
        -webkit-margin-before: .5px;
        position: absolute;
        left: 0;
        width: $table_frozen_column_width + 1;
        z-index: 9;
      }
    }
    td {
      border-top: none;
      &:first-child {
        background: white;
      }
    }
    tr:nth-child(even) td:first-child {
      background: $table_alt_row_background;
    }
    thead {
      th, td {
        &:first-child {
          -webkit-margin-before: 0;
          @include rem(height, 32px);
          @include rem(padding-left, 25px);
        }
      }
    }
  }
}