// Tooltips
//
// Notifications provide lightweight feedback about an operation. They show a brief message at the top of the screen. Notifications can contain an action.
// 
// Markup: 
// <span title="Hey, I'm a Tooltip!" rel="tooltip" class="{$modifiers}">
//   <span class="icon-info" aria-hidden="true"></span>
//   <span class="visuallyhidden">More Info</span>
// </span>
//
// .info                - Info alert
// .success             - Success alert
// .warning             - Warning alert
// .breaking            - Breaking news
// 
// Styleguide 4.7

[rel~=tooltip] {
  color: $color_core_light_gray;
  transition: all .15s ease-in-out;
  
  span {
    font-size: 24px;
    line-height: 24px;
    box-shadow: 0 0 0 0 rgba($color_core_gray, .2);
    border-radius: 50%;

  }
  &:hover {
    color: $color_core_blue;
    &.info {
      color: $color_alert_blue;
    }
    &.warning {
      color: $color_alert_orange;
    }
    &.success {
      color: $color_alert_green;
    }
    &.breaking {
      color: $color_alert_red;
    }
  }

}

#tooltip {
  text-align: center;
  color: #fff;
  background: $color_core_blue;
  position: absolute;
  z-index: 100;
  padding: 10px 20px;
  border-radius: $border_radius;
  line-height: 1.2;
  @include rem(font-size, 14px);

  &.info {
    background: $color_alert_blue;
    &:after {
      border-top: 10px solid $color_alert_blue;
    }
  }
  &.warning {
    background: $color_alert_orange;
    &:after {
      border-top: 10px solid $color_alert_orange;
    }
  }
  &.success {
    background: $color_alert_green;
    &:after {
      border-top: 10px solid $color_alert_green;
    }
  }
  &.breaking {
    background: $color_alert_red;
    &:after {
      border-top: 10px solid $color_alert_red;
    }
  }
}

#tooltip:after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $color_core_blue;
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  margin-left: -10px;
}

#tooltip.top:after {
  border-top-color: transparent;
  border-bottom: 10px solid $color_core_blue;
  top: -20px;
  bottom: auto;
}

#tooltip.left:after {
  left: 10px;
  margin: 0;
}

#tooltip.right:after {
  right: 10px;
  left: auto;
  margin: 0;
}

.ui-tooltip {
  background: $color_core_white;

  .editable-input {
    width: 100%;
    select {
      width: 100%;
    }
    [type="checkbox"] {
      margin-right: 5px;
    }
  }

  .ui-datepicker-title {
    select {
      display: inline-block;
      width: auto;
      @include rem(margin, 0 5px);
    }
  }

}


