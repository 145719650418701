// Iconography
//
// All glyphs are inteded to be single-color icons that can be used to represent
// an item. Our primary icon family will be Google’s Material Design set, but 
// will have IcoMoon’s set supplement, as needed. Both showcase a heavy, but clean
// style that is easy to read on mobile.
// 
// Styleguide 1.4

// Glyphs
// 
// The following are just a few of the icons in the glyph family. <a href="templates/icons.html">View Entire Glyph Family</a>.
// 
// <ul class="styleguide glyphs">
// <li><span class="icon-flickr" aria-hidden="true"></span><p>flickr</p></li>
// <li><span class="icon-pinterest" aria-hidden="true"></span><p>pinterest</p></li>
// <li><span class="icon-youtube2" aria-hidden="true"></span><p>youtube</p></li>
// <li><span class="icon-googleplus" aria-hidden="true"></span><p>googleplus</p></li>
// <li><span class="icon-facebook" aria-hidden="true"></span><p>facebook</p></li>
// <li><span class="icon-instagram" aria-hidden="true"></span><p>instagram</p></li>
// <li><span class="icon-twitter" aria-hidden="true"></span><p>twitter</p></li>
// <li><span class="icon-feed" aria-hidden="true"></span><p>feed</p></li>
// <li><span class="icon-youtube" aria-hidden="true"></span><p>youtube</p></li>
// <li><span class="icon-linkedin" aria-hidden="true"></span><p>linkedin</p></li>
// <li><span class="icon-event" aria-hidden="true"></span><p>event</p></li>
// <li><span class="icon-launch" aria-hidden="true"></span><p>launch</p></li>
// <li><span class="icon-settings" aria-hidden="true"></span><p>settings</p></li>
// <li><span class="icon-turned-in" aria-hidden="true"></span><p>turned-in</p></li>
// <li><span class="icon-turned-in-not" aria-hidden="true"></span><p>turned-in-not</p></li>
// <li><span class="icon-view-list" aria-hidden="true"></span><p>view-list</p></li>
// <li><span class="icon-view-module" aria-hidden="true"></span><p>view-module</p></li>
// <li><span class="icon-wallet-travel" aria-hidden="true"></span><p>wallet-travel</p></li>
// <li><span class="icon-work" aria-hidden="true"></span><p>work</p></li>
// <li><span class="icon-email" aria-hidden="true"></span><p>email</p></li>
// <li><span class="icon-add" aria-hidden="true"></span><p>add</p></li>
// <li><span class="icon-remove" aria-hidden="true"></span><p>remove</p></li>
// <li><span class="icon-vertical-align-top" aria-hidden="true"></span><p>vertical-align-top</p></li>
// <li><span class="icon-attachment" aria-hidden="true"></span><p>attachment</p></li>
// <li><span class="icon-phonelink" aria-hidden="true"></span><p>phonelink</p></li>
// <li><span class="icon-more-horiz" aria-hidden="true"></span><p>more-horiz</p></li>
// <li><span class="icon-more-vert" aria-hidden="true"></span><p>more-vert</p></li>
// <li><span class="icon-notifications" aria-hidden="true"></span><p>icon-notifications</p></li>
// <li><span class="icon-account-balance" aria-hidden="true"></span><p>account-balance</p></li>
// <li><span class="icon-help" aria-hidden="true"></span><p>help</p></li>
// <li><span class="icon-highlight-remove" aria-hidden="true"></span><p>highlight-remove</p></li>
// <li><span class="icon-info" aria-hidden="true"></span><p>info</p></li>
// <li><span class="icon-info-outline" aria-hidden="true"></span><p>info-outline</p></li>
// <li><span class="icon-label" aria-hidden="true"></span><p>label</p></li>
// <li><span class="icon-label-outline" aria-hidden="true"></span><p>label-outline</p></li>
// <li><span class="icon-language" aria-hidden="true"></span><p>language</p></li>
// <li><span class="icon-query-builder" aria-hidden="true"></span><p>query-builder</p></li>
// <li><span class="icon-search" aria-hidden="true"></span><p>search</p></li>
// <li><span class="icon-error" aria-hidden="true"></span><p>error</p></li>
// <li><span class="icon-warning" aria-hidden="true"></span><p>warning</p></li>
// <li><span class="icon-report" aria-hidden="true"></span><p>report</p></li>
// <li><span class="icon-dehaze" aria-hidden="true"></span><p>dehaze</p></li>
// <li><span class="icon-my-location" aria-hidden="true"></span><p>my-location</p></li>
// <li><span class="icon-arrow-back" aria-hidden="true"></span><p>arrow-back</p></li>
// <li><span class="icon-arrow-forward" aria-hidden="true"></span><p>arrow-forward</p></li>
// <li><span class="icon-cancel" aria-hidden="true"></span><p>cancel</p></li>
// <li><span class="icon-check" aria-hidden="true"></span><p>check</p></li>
// <li><span class="icon-chevron-left" aria-hidden="true"></span><p>chevron-left</p></li>
// <li><span class="icon-chevron-right" aria-hidden="true"></span><p>chevron-right</p></li>
// <li><span class="icon-close" aria-hidden="true"></span><p>close</p></li>
// <li><span class="icon-expand-less" aria-hidden="true"></span><p>expand-less</p></li>
// <li><span class="icon-expand-more" aria-hidden="true"></span><p>expand-more</p></li>
// <li><span class="icon-radio-button-off" aria-hidden="true"></span><p>radio-button-off</p></li>
// <li><span class="icon-radio-button-on" aria-hidden="true"></span><p>radio-button-on</p></li>
// <li><span class="icon-comment" aria-hidden="true"></span><p>comment</p></li>
// </ul>
// 
//  Markup:
//  <span class="icon-googleplus" aria-hidden="true"></span> 
//
// Styleguide 1.4.1

@font-face {
  font-family: 'icomoon';
  src:url('fonts/icomoon.eot?-5j2ael');
  src:url('fonts/icomoon.eot?#iefix-5j2ael') format('embedded-opentype'),
    url('fonts/icomoon.woff?-5j2ael') format('woff'),
    url('fonts/icomoon.ttf?-5j2ael') format('truetype'),
    url('fonts/icomoon.svg?-5j2ael#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-linkedin:before {
  content: "\e931";
}
.icon-nc-medical:before {
  content: "\e926";
}

.icon-nc-deaf-blind:before {
  content: "\e927";
}

.icon-nc-vision:before {
  content: "\e928";
}

.icon-nc-veteran:before {
  content: "\e929";
}

.icon-nc-seniors:before {
  content: "\e92a";
}

.icon-nc-campfire:before {
  content: "\e92b";
}

.icon-nc-baby:before {
  content: "\e92c";
}

.icon-nc-mental:before {
  content: "\e92d";
}

.icon-nc-no-home:before {
  content: "\e92e";
}

.icon-nc-handicap:before {
  content: "\e92f";
}

.icon-nc-compass:before {
  content: "\e917";
}

.icon-nc-donate:before {
  content: "\e918";
}

.icon-nc-eco-house:before {
  content: "\e919";
}

.icon-nc-faces-culture:before {
  content: "\e91a";
}

.icon-nc-family:before {
  content: "\e91b";
}

.icon-nc-government-building:before {
  content: "\e91c";
}

.icon-nc-group-people:before {
  content: "\e91d";
}

.icon-nc-justice:before {
  content: "\e91e";
}

.icon-nc-libraries:before {
  content: "\e91f";
}

.icon-nc-newspaper:before {
  content: "\e920";
}

.icon-nc-online-service:before {
  content: "\e921";
}

.icon-nc-pie-chart:before {
  content: "\e922";
}

.icon-nc-service-budget:before {
  content: "\e923";
}

.icon-nc-service-families:before {
  content: "\e924";
}

.icon-pinterest:before {
  content: "\e623";
}

.icon-leaf:before {
  content: "\e925";
}

.icon-youtube2:before {
  content: "\e624";
}

.icon-flickr:before {
  content: "\e930";
}

.icon-googleplus:before {
  content: "\e600";
}

.icon-facebook:before {
  content: "\e601";
}

.icon-instagram:before {
  content: "\e602";
}

.icon-twitter:before {
  content: "\e603";
}

.icon-feed:before {
  content: "\e604";
}

.icon-youtube:before {
  content: "\e605";
}

.icon-3d-rotation:before {
  content: "\e635";
}

.icon-accessibility:before {
  content: "\e636";
}

.icon-account-balance2:before {
  content: "\e637";
}

.icon-account-balance-wallet:before {
  content: "\e638";
}

.icon-account-box:before {
  content: "\e639";
}

.icon-account-child:before {
  content: "\e63a";
}

.icon-account-circle:before {
  content: "\e63b";
}

.icon-add-shopping-cart:before {
  content: "\e63c";
}

.icon-alarm:before {
  content: "\e63d";
}

.icon-alarm-add:before {
  content: "\e63e";
}

.icon-alarm-off:before {
  content: "\e63f";
}

.icon-alarm-on:before {
  content: "\e640";
}

.icon-android:before {
  content: "\e641";
}

.icon-announcement:before {
  content: "\e642";
}

.icon-aspect-ratio:before {
  content: "\e643";
}

.icon-assessment:before {
  content: "\e644";
}

.icon-assignment:before {
  content: "\e645";
}

.icon-assignment-ind:before {
  content: "\e646";
}

.icon-assignment-late:before {
  content: "\e647";
}

.icon-assignment-return:before {
  content: "\e648";
}

.icon-assignment-returned:before {
  content: "\e649";
}

.icon-assignment-turned-in:before {
  content: "\e64a";
}

.icon-autorenew:before {
  content: "\e64b";
}

.icon-backup:before {
  content: "\e64c";
}

.icon-book:before {
  content: "\e64d";
}

.icon-bookmark:before {
  content: "\e64e";
}

.icon-bookmark-outline:before {
  content: "\e64f";
}

.icon-bug-report:before {
  content: "\e650";
}

.icon-cached:before {
  content: "\e651";
}

.icon-class:before {
  content: "\e652";
}

.icon-credit-card:before {
  content: "\e653";
}

.icon-dashboard:before {
  content: "\e654";
}

.icon-delete:before {
  content: "\e655";
}

.icon-description:before {
  content: "\e656";
}

.icon-dns:before {
  content: "\e657";
}

.icon-done:before {
  content: "\e658";
}

.icon-done-all:before {
  content: "\e659";
}

.icon-event:before {
  content: "\e625";
}

.icon-exit-to-app:before {
  content: "\e65a";
}

.icon-explore:before {
  content: "\e65b";
}

.icon-extension:before {
  content: "\e65c";
}

.icon-face-unlock:before {
  content: "\e65d";
}

.icon-favorite:before {
  content: "\e65e";
}

.icon-favorite-outline:before {
  content: "\e65f";
}

.icon-find-in-page:before {
  content: "\e660";
}

.icon-find-replace:before {
  content: "\e661";
}

.icon-flip-to-back:before {
  content: "\e662";
}

.icon-flip-to-front:before {
  content: "\e663";
}

.icon-get-app:before {
  content: "\e664";
}

.icon-grade:before {
  content: "\e665";
}

.icon-group-work:before {
  content: "\e666";
}

.icon-help2:before {
  content: "\e667";
}

.icon-highlight-remove2:before {
  content: "\e668";
}

.icon-history:before {
  content: "\e669";
}

.icon-home:before {
  content: "\e66a";
}

.icon-https:before {
  content: "\e66b";
}

.icon-info2:before {
  content: "\e66c";
}

.icon-info-outline2:before {
  content: "\e66d";
}

.icon-input:before {
  content: "\e66e";
}

.icon-invert-colors:before {
  content: "\e66f";
}

.icon-label2:before {
  content: "\e670";
}

.icon-label-outline2:before {
  content: "\e671";
}

.icon-language2:before {
  content: "\e672";
}

.icon-launch:before {
  content: "\e626";
}

.icon-list:before {
  content: "\e673";
}

.icon-lock:before {
  content: "\e674";
}

.icon-lock-open:before {
  content: "\e675";
}

.icon-lock-outline:before {
  content: "\e676";
}

.icon-loyalty:before {
  content: "\e677";
}

.icon-markunread-mailbox:before {
  content: "\e678";
}

.icon-note-add:before {
  content: "\e679";
}

.icon-open-in-browser:before {
  content: "\e67a";
}

.icon-open-in-new:before {
  content: "\e67b";
}

.icon-open-with:before {
  content: "\e67c";
}

.icon-pageview:before {
  content: "\e67d";
}

.icon-payment:before {
  content: "\e67e";
}

.icon-perm-camera-m:before {
  content: "\e67f";
}

.icon-perm-contact-cal:before {
  content: "\e680";
}

.icon-perm-data-setting:before {
  content: "\e681";
}

.icon-perm-device-info:before {
  content: "\e682";
}

.icon-perm-identity:before {
  content: "\e683";
}

.icon-perm-media:before {
  content: "\e684";
}

.icon-perm-phone-msg:before {
  content: "\e685";
}

.icon-perm-scan-wifi:before {
  content: "\e686";
}

.icon-picture-in-picture:before {
  content: "\e687";
}

.icon-polymer:before {
  content: "\e688";
}

.icon-print:before {
  content: "\e689";
}

.icon-query-builder2:before {
  content: "\e68a";
}

.icon-question-answer:before {
  content: "\e68b";
}

.icon-receipt:before {
  content: "\e68c";
}

.icon-redeem:before {
  content: "\e68d";
}

.icon-reorder:before {
  content: "\e68e";
}

.icon-report-problem:before {
  content: "\e68f";
}

.icon-restore:before {
  content: "\e690";
}

.icon-room:before {
  content: "\e691";
}

.icon-schedule:before {
  content: "\e692";
}

.icon-search2:before {
  content: "\e693";
}

.icon-settings:before {
  content: "\e627";
}

.icon-settings-applications:before {
  content: "\e694";
}

.icon-settings-backup-restore:before {
  content: "\e695";
}

.icon-settings-bluetooth:before {
  content: "\e696";
}

.icon-settings-cell:before {
  content: "\e697";
}

.icon-settings-display:before {
  content: "\e698";
}

.icon-settings-ethernet:before {
  content: "\e699";
}

.icon-settings-input-antenna:before {
  content: "\e69a";
}

.icon-settings-input-component:before {
  content: "\e69b";
}

.icon-settings-input-composite:before {
  content: "\e69c";
}

.icon-settings-input-hdmi:before {
  content: "\e69d";
}

.icon-settings-input-svideo:before {
  content: "\e69e";
}

.icon-settings-overscan:before {
  content: "\e69f";
}

.icon-settings-phone:before {
  content: "\e6a0";
}

.icon-settings-power:before {
  content: "\e6a1";
}

.icon-settings-remote:before {
  content: "\e6a2";
}

.icon-settings-voice:before {
  content: "\e6a3";
}

.icon-shop:before {
  content: "\e6a4";
}

.icon-shop-two:before {
  content: "\e6a5";
}

.icon-shopping-basket:before {
  content: "\e6a6";
}

.icon-shopping-cart:before {
  content: "\e6a7";
}

.icon-speaker-notes:before {
  content: "\e6a8";
}

.icon-spellcheck:before {
  content: "\e6a9";
}

.icon-star-rate:before {
  content: "\e6aa";
}

.icon-stars:before {
  content: "\e6ab";
}

.icon-store:before {
  content: "\e6ac";
}

.icon-subject:before {
  content: "\e6ad";
}

.icon-supervisor-account:before {
  content: "\e6ae";
}

.icon-swap-horiz:before {
  content: "\e6af";
}

.icon-swap-vert:before {
  content: "\e6b0";
}

.icon-swap-vert-circle:before {
  content: "\e6b1";
}

.icon-system-update-tv:before {
  content: "\e6b2";
}

.icon-tab:before {
  content: "\e6b3";
}

.icon-tab-unselected:before {
  content: "\e6b4";
}

.icon-theaters:before {
  content: "\e6b5";
}

.icon-thumb-down:before {
  content: "\e6b6";
}

.icon-thumb-up:before {
  content: "\e6b7";
}

.icon-thumbs-up-down:before {
  content: "\e6b8";
}

.icon-toc:before {
  content: "\e6b9";
}

.icon-today:before {
  content: "\e6ba";
}

.icon-track-changes:before {
  content: "\e6bb";
}

.icon-translate:before {
  content: "\e6bc";
}

.icon-trending-down:before {
  content: "\e6bd";
}

.icon-trending-neutral:before {
  content: "\e6be";
}

.icon-trending-up:before {
  content: "\e6bf";
}

.icon-turned-in:before {
  content: "\e628";
}

.icon-turned-in-not:before {
  content: "\e629";
}

.icon-verified-user:before {
  content: "\e6c0";
}

.icon-view-agenda:before {
  content: "\e6c1";
}

.icon-view-array:before {
  content: "\e6c2";
}

.icon-view-carousel:before {
  content: "\e6c3";
}

.icon-view-column:before {
  content: "\e6c4";
}

.icon-view-day:before {
  content: "\e6c5";
}

.icon-view-headline:before {
  content: "\e6c6";
}

.icon-view-list:before {
  content: "\e62a";
}

.icon-view-module:before {
  content: "\e62b";
}

.icon-view-quilt:before {
  content: "\e6c7";
}

.icon-view-stream:before {
  content: "\e6c8";
}

.icon-view-week:before {
  content: "\e6c9";
}

.icon-visibility:before {
  content: "\e6ca";
}

.icon-visibility-off:before {
  content: "\e6cb";
}

.icon-wallet-giftcard:before {
  content: "\e6cc";
}

.icon-wallet-membership:before {
  content: "\e6cd";
}

.icon-wallet-travel:before {
  content: "\e62c";
}

.icon-work:before {
  content: "\e62d";
}

.icon-error2:before {
  content: "\e6ce";
}

.icon-warning2:before {
  content: "\e6cf";
}

.icon-album:before {
  content: "\e6d0";
}

.icon-av-timer:before {
  content: "\e6d1";
}

.icon-closed-caption:before {
  content: "\e6d2";
}

.icon-equalizer:before {
  content: "\e6d3";
}

.icon-explicit:before {
  content: "\e6d4";
}

.icon-fast-forward:before {
  content: "\e6d5";
}

.icon-fast-rewind:before {
  content: "\e6d6";
}

.icon-games:before {
  content: "\e6d7";
}

.icon-hearing:before {
  content: "\e6d8";
}

.icon-high-quality:before {
  content: "\e6d9";
}

.icon-loop:before {
  content: "\e6da";
}

.icon-mic:before {
  content: "\e6db";
}

.icon-mnone:before {
  content: "\e6dc";
}

.icon-moff:before {
  content: "\e6dd";
}

.icon-movie:before {
  content: "\e6de";
}

.icon-my-library-add:before {
  content: "\e6df";
}

.icon-my-library-books:before {
  content: "\e6e0";
}

.icon-my-library-mus:before {
  content: "\e6e1";
}

.icon-new-releases:before {
  content: "\e6e2";
}

.icon-not-interested:before {
  content: "\e6e3";
}

.icon-pause:before {
  content: "\e6e4";
}

.icon-pause-circle-fill:before {
  content: "\e6e5";
}

.icon-pause-circle-outline:before {
  content: "\e6e6";
}

.icon-play-arrow:before {
  content: "\e6e7";
}

.icon-play-circle-fill:before {
  content: "\e6e8";
}

.icon-play-circle-outline:before {
  content: "\e6e9";
}

.icon-play-shopping-bag:before {
  content: "\e6ea";
}

.icon-playlist-add:before {
  content: "\e6eb";
}

.icon-queue:before {
  content: "\e6ec";
}

.icon-queue-mus:before {
  content: "\e6ed";
}

.icon-radio:before {
  content: "\e6ee";
}

.icon-recent-actors:before {
  content: "\e6ef";
}

.icon-repeat:before {
  content: "\e6f0";
}

.icon-repeat-one:before {
  content: "\e6f1";
}

.icon-replay:before {
  content: "\e6f2";
}

.icon-shuffle:before {
  content: "\e6f3";
}

.icon-skip-next:before {
  content: "\e6f4";
}

.icon-skip-previous:before {
  content: "\e6f5";
}

.icon-snooze:before {
  content: "\e6f6";
}

.icon-stop:before {
  content: "\e6f7";
}

.icon-subtitles:before {
  content: "\e6f8";
}

.icon-surround-sound:before {
  content: "\e6f9";
}

.icon-video-collection:before {
  content: "\e6fa";
}

.icon-videocam:before {
  content: "\e6fb";
}

.icon-videocam-off:before {
  content: "\e6fc";
}

.icon-volume-down:before {
  content: "\e6fd";
}

.icon-volume-mute:before {
  content: "\e6fe";
}

.icon-volume-off:before {
  content: "\e6ff";
}

.icon-volume-up:before {
  content: "\e700";
}

.icon-web:before {
  content: "\e701";
}

.icon-business:before {
  content: "\e702";
}

.icon-call:before {
  content: "\e703";
}

.icon-call-end:before {
  content: "\e704";
}

.icon-call-made:before {
  content: "\e705";
}

.icon-call-merge:before {
  content: "\e706";
}

.icon-call-missed:before {
  content: "\e707";
}

.icon-call-received:before {
  content: "\e708";
}

.icon-call-split:before {
  content: "\e709";
}

.icon-chat:before {
  content: "\e70a";
}

.icon-clear-all:before {
  content: "\e70b";
}

.icon-comment:before {
  content: "\e634";
}

.icon-contacts:before {
  content: "\e70c";
}

.icon-dialer-sip:before {
  content: "\e70d";
}

.icon-dialpad:before {
  content: "\e70e";
}

.icon-dnd-on:before {
  content: "\e70f";
}

.icon-email:before {
  content: "\e62e";
}

.icon-forum:before {
  content: "\e710";
}

.icon-import-export:before {
  content: "\e711";
}

.icon-invert-colors-off:before {
  content: "\e712";
}

.icon-invert-colors-on:before {
  content: "\e713";
}

.icon-live-help:before {
  content: "\e714";
}

.icon-location-off:before {
  content: "\e715";
}

.icon-location-on:before {
  content: "\e716";
}

.icon-message:before {
  content: "\e717";
}

.icon-messenger:before {
  content: "\e718";
}

.icon-no-sim:before {
  content: "\e719";
}

.icon-phone:before {
  content: "\e71a";
}

.icon-portable-wifi-off:before {
  content: "\e71b";
}

.icon-quick-contacts-dialer:before {
  content: "\e71c";
}

.icon-quick-contacts-mail:before {
  content: "\e71d";
}

.icon-ring-volume:before {
  content: "\e71e";
}

.icon-stay-current-landscape:before {
  content: "\e71f";
}

.icon-stay-current-portrait:before {
  content: "\e720";
}

.icon-stay-primary-landscape:before {
  content: "\e721";
}

.icon-stay-primary-portrait:before {
  content: "\e722";
}

.icon-swap-calls:before {
  content: "\e723";
}

.icon-textsms:before {
  content: "\e724";
}

.icon-voicemail:before {
  content: "\e725";
}

.icon-vpn-key:before {
  content: "\e726";
}

.icon-add:before {
  content: "\e620";
}

.icon-add-box:before {
  content: "\e727";
}

.icon-add-circle:before {
  content: "\e728";
}

.icon-add-circle-outline:before {
  content: "\e729";
}

.icon-archive:before {
  content: "\e72a";
}

.icon-backspace:before {
  content: "\e72b";
}

.icon-block:before {
  content: "\e72c";
}

.icon-clear:before {
  content: "\e72d";
}

.icon-content-copy:before {
  content: "\e72e";
}

.icon-content-cut:before {
  content: "\e72f";
}

.icon-content-paste:before {
  content: "\e730";
}

.icon-create:before {
  content: "\e731";
}

.icon-drafts:before {
  content: "\e732";
}

.icon-filter-list:before {
  content: "\e733";
}

.icon-flag:before {
  content: "\e734";
}

.icon-forward:before {
  content: "\e735";
}

.icon-gesture:before {
  content: "\e736";
}

.icon-inbox:before {
  content: "\e737";
}

.icon-link:before {
  content: "\e738";
}

.icon-mail:before {
  content: "\e739";
}

.icon-markunread:before {
  content: "\e73a";
}

.icon-redo:before {
  content: "\e73b";
}

.icon-remove:before {
  content: "\e621";
}

.icon-remove-circle:before {
  content: "\e73c";
}

.icon-remove-circle-outline:before {
  content: "\e73d";
}

.icon-reply:before {
  content: "\e73e";
}

.icon-reply-all:before {
  content: "\e73f";
}

.icon-report2:before {
  content: "\e740";
}

.icon-save:before {
  content: "\e741";
}

.icon-select-all:before {
  content: "\e742";
}

.icon-send:before {
  content: "\e743";
}

.icon-sort:before {
  content: "\e744";
}

.icon-text-format:before {
  content: "\e745";
}

.icon-undo:before {
  content: "\e746";
}

.icon-access-alarm:before {
  content: "\e747";
}

.icon-access-alarms:before {
  content: "\e748";
}

.icon-access-time:before {
  content: "\e749";
}

.icon-add-alarm:before {
  content: "\e74a";
}

.icon-airplanemode-off:before {
  content: "\e74b";
}

.icon-airplanemode-on:before {
  content: "\e74c";
}

.icon-battery-20:before {
  content: "\e74d";
}

.icon-battery-30:before {
  content: "\e74e";
}

.icon-battery-50:before {
  content: "\e74f";
}

.icon-battery-60:before {
  content: "\e750";
}

.icon-battery-80:before {
  content: "\e751";
}

.icon-battery-90:before {
  content: "\e752";
}

.icon-battery-alert:before {
  content: "\e753";
}

.icon-battery-charging-20:before {
  content: "\e754";
}

.icon-battery-charging-30:before {
  content: "\e755";
}

.icon-battery-charging-50:before {
  content: "\e756";
}

.icon-battery-charging-60:before {
  content: "\e757";
}

.icon-battery-charging-80:before {
  content: "\e758";
}

.icon-battery-charging-90:before {
  content: "\e759";
}

.icon-battery-charging-full:before {
  content: "\e75a";
}

.icon-battery-full:before {
  content: "\e75b";
}

.icon-battery-std:before {
  content: "\e75c";
}

.icon-battery-unknown:before {
  content: "\e75d";
}

.icon-bluetooth:before {
  content: "\e75e";
}

.icon-bluetooth-connected:before {
  content: "\e75f";
}

.icon-bluetooth-disabled:before {
  content: "\e760";
}

.icon-bluetooth-searching:before {
  content: "\e761";
}

.icon-brightness-auto:before {
  content: "\e762";
}

.icon-brightness-high:before {
  content: "\e763";
}

.icon-brightness-low:before {
  content: "\e764";
}

.icon-brightness-medium:before {
  content: "\e765";
}

.icon-data-usage:before {
  content: "\e766";
}

.icon-developer-mode:before {
  content: "\e767";
}

.icon-devices:before {
  content: "\e768";
}

.icon-dvr:before {
  content: "\e769";
}

.icon-gps-fixed:before {
  content: "\e76a";
}

.icon-gps-not-fixed:before {
  content: "\e76b";
}

.icon-gps-off:before {
  content: "\e76c";
}

.icon-location-disabled:before {
  content: "\e76d";
}

.icon-location-searching:before {
  content: "\e76e";
}

.icon-multitrack-audio:before {
  content: "\e76f";
}

.icon-network-cell:before {
  content: "\e770";
}

.icon-network-wifi:before {
  content: "\e771";
}

.icon-nfc:before {
  content: "\e772";
}

.icon-now-wallpaper:before {
  content: "\e773";
}

.icon-now-widgets:before {
  content: "\e774";
}

.icon-screen-lock-landscape:before {
  content: "\e775";
}

.icon-screen-lock-portrait:before {
  content: "\e776";
}

.icon-screen-lock-rotation:before {
  content: "\e777";
}

.icon-screen-rotation:before {
  content: "\e778";
}

.icon-sd-storage:before {
  content: "\e779";
}

.icon-settings-system-daydream:before {
  content: "\e77a";
}

.icon-signal-cellular-0-bar:before {
  content: "\e77b";
}

.icon-signal-cellular-1-bar:before {
  content: "\e77c";
}

.icon-signal-cellular-2-bar:before {
  content: "\e77d";
}

.icon-signal-cellular-3-bar:before {
  content: "\e77e";
}

.icon-signal-cellular-4-bar:before {
  content: "\e77f";
}

.icon-signal-cellular-connected-no-internet-0-bar:before {
  content: "\e780";
}

.icon-signal-cellular-connected-no-internet-1-bar:before {
  content: "\e781";
}

.icon-signal-cellular-connected-no-internet-2-bar:before {
  content: "\e782";
}

.icon-signal-cellular-connected-no-internet-3-bar:before {
  content: "\e783";
}

.icon-signal-cellular-connected-no-internet-4-bar:before {
  content: "\e784";
}

.icon-signal-cellular-no-sim:before {
  content: "\e785";
}

.icon-signal-cellular-null:before {
  content: "\e786";
}

.icon-signal-cellular-off:before {
  content: "\e787";
}

.icon-signal-wifi-0-bar:before {
  content: "\e788";
}

.icon-signal-wifi-1-bar:before {
  content: "\e789";
}

.icon-signal-wifi-2-bar:before {
  content: "\e78a";
}

.icon-signal-wifi-3-bar:before {
  content: "\e78b";
}

.icon-signal-wifi-4-bar:before {
  content: "\e78c";
}

.icon-signal-wifi-off:before {
  content: "\e78d";
}

.icon-signal-wifi-statusbar-1-bar:before {
  content: "\e78e";
}

.icon-signal-wifi-statusbar-2-bar:before {
  content: "\e78f";
}

.icon-signal-wifi-statusbar-3-bar:before {
  content: "\e790";
}

.icon-signal-wifi-statusbar-4-bar:before {
  content: "\e791";
}

.icon-signal-wifi-statusbar-connected-no-internet-1:before {
  content: "\e792";
}

.icon-signal-wifi-statusbar-connected-no-internet-2:before {
  content: "\e793";
}

.icon-signal-wifi-statusbar-connected-no-internet-3:before {
  content: "\e794";
}

.icon-signal-wifi-statusbar-connected-no-internet-4:before {
  content: "\e795";
}

.icon-signal-wifi-statusbar-connected-no-internet:before {
  content: "\e796";
}

.icon-signal-wifi-statusbar-not-connected:before {
  content: "\e797";
}

.icon-signal-wifi-statusbar-null:before {
  content: "\e798";
}

.icon-storage:before {
  content: "\e799";
}

.icon-usb:before {
  content: "\e79a";
}

.icon-wifi-lock:before {
  content: "\e79b";
}

.icon-wifi-tethering:before {
  content: "\e79c";
}

.icon-attach-file:before {
  content: "\e79d";
}

.icon-attach-money:before {
  content: "\e79e";
}

.icon-border-all:before {
  content: "\e79f";
}

.icon-border-bottom:before {
  content: "\e7a0";
}

.icon-border-clear:before {
  content: "\e7a1";
}

.icon-border-color:before {
  content: "\e7a2";
}

.icon-border-horizontal:before {
  content: "\e7a3";
}

.icon-border-inner:before {
  content: "\e7a4";
}

.icon-border-left:before {
  content: "\e7a5";
}

.icon-border-outer:before {
  content: "\e7a6";
}

.icon-border-right:before {
  content: "\e7a7";
}

.icon-border-style:before {
  content: "\e7a8";
}

.icon-border-top:before {
  content: "\e7a9";
}

.icon-border-vertical:before {
  content: "\e7aa";
}

.icon-format-align-center:before {
  content: "\e7ab";
}

.icon-format-align-justify:before {
  content: "\e7ac";
}

.icon-format-align-left:before {
  content: "\e7ad";
}

.icon-format-align-right:before {
  content: "\e7ae";
}

.icon-format-bold:before {
  content: "\e7af";
}

.icon-format-clear:before {
  content: "\e7b0";
}

.icon-format-color-fill:before {
  content: "\e7b1";
}

.icon-format-color-reset:before {
  content: "\e7b2";
}

.icon-format-color-text:before {
  content: "\e7b3";
}

.icon-format-indent-decrease:before {
  content: "\e7b4";
}

.icon-format-indent-increase:before {
  content: "\e7b5";
}

.icon-format-ital:before {
  content: "\e7b6";
}

.icon-format-line-spacing:before {
  content: "\e7b7";
}

.icon-format-list-bulleted:before {
  content: "\e7b8";
}

.icon-format-list-numbered:before {
  content: "\e7b9";
}

.icon-format-paint:before {
  content: "\e7ba";
}

.icon-format-quote:before {
  content: "\e7bb";
}

.icon-format-size:before {
  content: "\e7bc";
}

.icon-format-strikethrough:before {
  content: "\e7bd";
}

.icon-format-textdirection-l-to-r:before {
  content: "\e7be";
}

.icon-format-textdirection-r-to-l:before {
  content: "\e7bf";
}

.icon-format-underline:before {
  content: "\e7c0";
}

.icon-functions:before {
  content: "\e7c1";
}

.icon-insert-chart:before {
  content: "\e7c2";
}

.icon-insert-comment:before {
  content: "\e7c3";
}

.icon-insert-drive-file:before {
  content: "\e7c4";
}

.icon-insert-emoticon:before {
  content: "\e7c5";
}

.icon-insert-invitation:before {
  content: "\e7c6";
}

.icon-insert-link:before {
  content: "\e7c7";
}

.icon-insert-photo:before {
  content: "\e7c8";
}

.icon-merge-type:before {
  content: "\e7c9";
}

.icon-mode-comment:before {
  content: "\e7ca";
}

.icon-mode-edit:before {
  content: "\e7cb";
}

.icon-publish:before {
  content: "\e7cc";
}

.icon-vertical-align-bottom:before {
  content: "\e7cd";
}

.icon-vertical-align-center:before {
  content: "\e7ce";
}

.icon-vertical-align-top:before {
  content: "\e622";
}

.icon-wrap-text:before {
  content: "\e7cf";
}

.icon-attachment:before {
  content: "\e62f";
}

.icon-cloud:before {
  content: "\e7d0";
}

.icon-cloud-circle:before {
  content: "\e7d1";
}

.icon-cloud-done:before {
  content: "\e7d2";
}

.icon-cloud-download:before {
  content: "\e7d3";
}

.icon-cloud-off:before {
  content: "\e7d4";
}

.icon-cloud-queue:before {
  content: "\e7d5";
}

.icon-cloud-upload:before {
  content: "\e7d6";
}

.icon-file-download:before {
  content: "\e7d7";
}

.icon-file-upload:before {
  content: "\e7d8";
}

.icon-folder:before {
  content: "\e7d9";
}

.icon-folder-open:before {
  content: "\e7da";
}

.icon-folder-shared:before {
  content: "\e7db";
}

.icon-cast:before {
  content: "\e7dc";
}

.icon-cast-connected:before {
  content: "\e7dd";
}

.icon-computer:before {
  content: "\e7de";
}

.icon-desktop-mac:before {
  content: "\e7df";
}

.icon-desktop-windows:before {
  content: "\e7e0";
}

.icon-dock:before {
  content: "\e7e1";
}

.icon-gamepad:before {
  content: "\e7e2";
}

.icon-headset:before {
  content: "\e7e3";
}

.icon-headset-m:before {
  content: "\e7e4";
}

.icon-keyboard:before {
  content: "\e7e5";
}

.icon-keyboard-alt:before {
  content: "\e7e6";
}

.icon-keyboard-arrow-down:before {
  content: "\e7e7";
}

.icon-keyboard-arrow-left:before {
  content: "\e7e8";
}

.icon-keyboard-arrow-right:before {
  content: "\e7e9";
}

.icon-keyboard-arrow-up:before {
  content: "\e7ea";
}

.icon-keyboard-backspace:before {
  content: "\e7eb";
}

.icon-keyboard-capslock:before {
  content: "\e7ec";
}

.icon-keyboard-control:before {
  content: "\e7ed";
}

.icon-keyboard-hide:before {
  content: "\e7ee";
}

.icon-keyboard-return:before {
  content: "\e7ef";
}

.icon-keyboard-tab:before {
  content: "\e7f0";
}

.icon-keyboard-voice:before {
  content: "\e7f1";
}

.icon-laptop:before {
  content: "\e7f2";
}

.icon-laptop-chromebook:before {
  content: "\e7f3";
}

.icon-laptop-mac:before {
  content: "\e7f4";
}

.icon-laptop-windows:before {
  content: "\e7f5";
}

.icon-memory:before {
  content: "\e7f6";
}

.icon-mouse:before {
  content: "\e7f7";
}

.icon-phone-android:before {
  content: "\e7f8";
}

.icon-phone-iphone:before {
  content: "\e7f9";
}

.icon-phonelink:before {
  content: "\e630";
}

.icon-phonelink-off:before {
  content: "\e7fa";
}

.icon-security:before {
  content: "\e7fb";
}

.icon-sim-card:before {
  content: "\e7fc";
}

.icon-smartphone:before {
  content: "\e7fd";
}

.icon-speaker:before {
  content: "\e7fe";
}

.icon-tablet:before {
  content: "\e7ff";
}

.icon-tablet-android:before {
  content: "\e800";
}

.icon-tablet-mac:before {
  content: "\e801";
}

.icon-tv:before {
  content: "\e802";
}

.icon-watch:before {
  content: "\e803";
}

.icon-add-to-photos:before {
  content: "\e804";
}

.icon-adjust:before {
  content: "\e805";
}

.icon-assistant-photo:before {
  content: "\e806";
}

.icon-audiotrack:before {
  content: "\e807";
}

.icon-blur-circular:before {
  content: "\e808";
}

.icon-blur-linear:before {
  content: "\e809";
}

.icon-blur-off:before {
  content: "\e80a";
}

.icon-blur-on:before {
  content: "\e80b";
}

.icon-brightness-1:before {
  content: "\e80c";
}

.icon-brightness-2:before {
  content: "\e80d";
}

.icon-brightness-3:before {
  content: "\e80e";
}

.icon-brightness-4:before {
  content: "\e80f";
}

.icon-brightness-5:before {
  content: "\e810";
}

.icon-brightness-6:before {
  content: "\e811";
}

.icon-brightness-7:before {
  content: "\e812";
}

.icon-brush:before {
  content: "\e813";
}

.icon-camera:before {
  content: "\e814";
}

.icon-camera-alt:before {
  content: "\e815";
}

.icon-camera-front:before {
  content: "\e816";
}

.icon-camera-rear:before {
  content: "\e817";
}

.icon-camera-roll:before {
  content: "\e818";
}

.icon-center-focus-strong:before {
  content: "\e819";
}

.icon-center-focus-weak:before {
  content: "\e81a";
}

.icon-collections:before {
  content: "\e81b";
}

.icon-color-lens:before {
  content: "\e81c";
}

.icon-colorize:before {
  content: "\e81d";
}

.icon-compare:before {
  content: "\e81e";
}

.icon-control-point:before {
  content: "\e81f";
}

.icon-control-point-duplicate:before {
  content: "\e820";
}

.icon-crop-3-2:before {
  content: "\e821";
}

.icon-crop-5-4:before {
  content: "\e822";
}

.icon-crop-7-5:before {
  content: "\e823";
}

.icon-crop-16-9:before {
  content: "\e824";
}

.icon-crop:before {
  content: "\e825";
}

.icon-crop-din:before {
  content: "\e826";
}

.icon-crop-free:before {
  content: "\e827";
}

.icon-crop-landscape:before {
  content: "\e828";
}

.icon-crop-original:before {
  content: "\e829";
}

.icon-crop-portrait:before {
  content: "\e82a";
}

.icon-crop-square:before {
  content: "\e82b";
}

.icon-dehaze2:before {
  content: "\e82c";
}

.icon-details:before {
  content: "\e82d";
}

.icon-edit:before {
  content: "\e82e";
}

.icon-exposure:before {
  content: "\e82f";
}

.icon-exposure-minus-1:before {
  content: "\e830";
}

.icon-exposure-minus-2:before {
  content: "\e831";
}

.icon-exposure-plus-1:before {
  content: "\e832";
}

.icon-exposure-plus-2:before {
  content: "\e833";
}

.icon-exposure-zero:before {
  content: "\e834";
}

.icon-filter-1:before {
  content: "\e835";
}

.icon-filter-2:before {
  content: "\e836";
}

.icon-filter-3:before {
  content: "\e837";
}

.icon-filter-4:before {
  content: "\e838";
}

.icon-filter-5:before {
  content: "\e839";
}

.icon-filter-6:before {
  content: "\e83a";
}

.icon-filter-7:before {
  content: "\e83b";
}

.icon-filter-8:before {
  content: "\e83c";
}

.icon-filter-9:before {
  content: "\e83d";
}

.icon-filter-9-plus:before {
  content: "\e83e";
}

.icon-filter:before {
  content: "\e83f";
}

.icon-filter-b-and-w:before {
  content: "\e840";
}

.icon-filter-center-focus:before {
  content: "\e841";
}

.icon-filter-drama:before {
  content: "\e842";
}

.icon-filter-frames:before {
  content: "\e843";
}

.icon-filter-hdr:before {
  content: "\e844";
}

.icon-filter-none:before {
  content: "\e845";
}

.icon-filter-tilt-shift:before {
  content: "\e846";
}

.icon-filter-vintage:before {
  content: "\e847";
}

.icon-flare:before {
  content: "\e848";
}

.icon-flash-auto:before {
  content: "\e849";
}

.icon-flash-off:before {
  content: "\e84a";
}

.icon-flash-on:before {
  content: "\e84b";
}

.icon-flip:before {
  content: "\e84c";
}

.icon-gradient:before {
  content: "\e84d";
}

.icon-grain:before {
  content: "\e84e";
}

.icon-grid-off:before {
  content: "\e84f";
}

.icon-grid-on:before {
  content: "\e850";
}

.icon-hdr-off:before {
  content: "\e851";
}

.icon-hdr-on:before {
  content: "\e852";
}

.icon-hdr-strong:before {
  content: "\e853";
}

.icon-hdr-weak:before {
  content: "\e854";
}

.icon-healing:before {
  content: "\e855";
}

.icon-image:before {
  content: "\e856";
}

.icon-image-aspect-ratio:before {
  content: "\e857";
}

.icon-iso:before {
  content: "\e858";
}

.icon-landscape:before {
  content: "\e859";
}

.icon-leak-add:before {
  content: "\e85a";
}

.icon-leak-remove:before {
  content: "\e85b";
}

.icon-lens:before {
  content: "\e85c";
}

.icon-looks-3:before {
  content: "\e85d";
}

.icon-looks-4:before {
  content: "\e85e";
}

.icon-looks-5:before {
  content: "\e85f";
}

.icon-looks-6:before {
  content: "\e860";
}

.icon-looks:before {
  content: "\e861";
}

.icon-looks-one:before {
  content: "\e862";
}

.icon-looks-two:before {
  content: "\e863";
}

.icon-loupe:before {
  content: "\e864";
}

.icon-movie-creation:before {
  content: "\e865";
}

.icon-nature:before {
  content: "\e866";
}

.icon-nature-people:before {
  content: "\e867";
}

.icon-navigate-before:before {
  content: "\e868";
}

.icon-navigate-next:before {
  content: "\e869";
}

.icon-palette:before {
  content: "\e86a";
}

.icon-panorama:before {
  content: "\e86b";
}

.icon-panorama-fisheye:before {
  content: "\e86c";
}

.icon-panorama-horizontal:before {
  content: "\e86d";
}

.icon-panorama-vertical:before {
  content: "\e86e";
}

.icon-panorama-wide-angle:before {
  content: "\e86f";
}

.icon-photo:before {
  content: "\e870";
}

.icon-photo-album:before {
  content: "\e871";
}

.icon-photo-camera:before {
  content: "\e872";
}

.icon-photo-library:before {
  content: "\e873";
}

.icon-portrait:before {
  content: "\e874";
}

.icon-remove-red-eye:before {
  content: "\e875";
}

.icon-rotate-left:before {
  content: "\e876";
}

.icon-rotate-right:before {
  content: "\e877";
}

.icon-slideshow:before {
  content: "\e878";
}

.icon-straighten:before {
  content: "\e879";
}

.icon-style:before {
  content: "\e87a";
}

.icon-switch-camera:before {
  content: "\e87b";
}

.icon-switch-video:before {
  content: "\e87c";
}

.icon-tag-faces:before {
  content: "\e87d";
}

.icon-texture:before {
  content: "\e87e";
}

.icon-timelapse:before {
  content: "\e87f";
}

.icon-timer-3:before {
  content: "\e880";
}

.icon-timer-10:before {
  content: "\e881";
}

.icon-timer:before {
  content: "\e882";
}

.icon-timer-auto:before {
  content: "\e883";
}

.icon-timer-off:before {
  content: "\e884";
}

.icon-tonality:before {
  content: "\e885";
}

.icon-transform:before {
  content: "\e886";
}

.icon-tune:before {
  content: "\e887";
}

.icon-wb-auto:before {
  content: "\e888";
}

.icon-wb-cloudy:before {
  content: "\e889";
}

.icon-wb-incandescent:before {
  content: "\e88a";
}

.icon-wb-irradescent:before {
  content: "\e88b";
}

.icon-wb-sunny:before {
  content: "\e88c";
}

.icon-beenhere:before {
  content: "\e88d";
}

.icon-directions:before {
  content: "\e88e";
}

.icon-directions-bike:before {
  content: "\e88f";
}

.icon-directions-bus:before {
  content: "\e890";
}

.icon-directions-car:before {
  content: "\e891";
}

.icon-directions-ferry:before {
  content: "\e892";
}

.icon-directions-subway:before {
  content: "\e893";
}

.icon-directions-train:before {
  content: "\e894";
}

.icon-directions-transit:before {
  content: "\e895";
}

.icon-directions-walk:before {
  content: "\e896";
}

.icon-flight:before {
  content: "\e897";
}

.icon-hotel:before {
  content: "\e898";
}

.icon-layers:before {
  content: "\e899";
}

.icon-layers-clear:before {
  content: "\e89a";
}

.icon-local-airport:before {
  content: "\e89b";
}

.icon-local-atm:before {
  content: "\e89c";
}

.icon-local-attraction:before {
  content: "\e89d";
}

.icon-local-bar:before {
  content: "\e89e";
}

.icon-local-cafe:before {
  content: "\e89f";
}

.icon-local-car-wash:before {
  content: "\e8a0";
}

.icon-local-convenience-store:before {
  content: "\e8a1";
}

.icon-local-drink:before {
  content: "\e8a2";
}

.icon-local-florist:before {
  content: "\e8a3";
}

.icon-local-gas-station:before {
  content: "\e8a4";
}

.icon-local-grocery-store:before {
  content: "\e8a5";
}

.icon-local-hospital:before {
  content: "\e8a6";
}

.icon-local-hotel:before {
  content: "\e8a7";
}

.icon-local-laundry-service:before {
  content: "\e8a8";
}

.icon-local-library:before {
  content: "\e8a9";
}

.icon-local-mall:before {
  content: "\e8aa";
}

.icon-local-movies:before {
  content: "\e8ab";
}

.icon-local-offer:before {
  content: "\e8ac";
}

.icon-local-parking:before {
  content: "\e8ad";
}

.icon-local-pharmacy:before {
  content: "\e8ae";
}

.icon-local-phone:before {
  content: "\e8af";
}

.icon-local-pizza:before {
  content: "\e8b0";
}

.icon-local-play:before {
  content: "\e8b1";
}

.icon-local-post-office:before {
  content: "\e8b2";
}

.icon-local-print-shop:before {
  content: "\e8b3";
}

.icon-local-restaurant:before {
  content: "\e8b4";
}

.icon-local-see:before {
  content: "\e8b5";
}

.icon-local-shipping:before {
  content: "\e8b6";
}

.icon-local-taxi:before {
  content: "\e8b7";
}

.icon-location-history:before {
  content: "\e8b8";
}

.icon-map:before {
  content: "\e8b9";
}

.icon-my-location2:before {
  content: "\e8ba";
}

.icon-navigation:before {
  content: "\e8bb";
}

.icon-pin-drop:before {
  content: "\e8bc";
}

.icon-place:before {
  content: "\e8bd";
}

.icon-rate-review:before {
  content: "\e8be";
}

.icon-restaurant-menu:before {
  content: "\e8bf";
}

.icon-satellite:before {
  content: "\e8c0";
}

.icon-store-mall-directory:before {
  content: "\e8c1";
}

.icon-terrain:before {
  content: "\e8c2";
}

.icon-traff:before {
  content: "\e8c3";
}

.icon-apps:before {
  content: "\e8c4";
}

.icon-arrow-back2:before {
  content: "\e8c5";
}

.icon-arrow-drop-down:before {
  content: "\e8c6";
}

.icon-arrow-drop-down-circle:before {
  content: "\e8c7";
}

.icon-arrow-drop-up:before {
  content: "\e8c8";
}

.icon-arrow-forward2:before {
  content: "\e8c9";
}

.icon-cancel2:before {
  content: "\e8ca";
}

.icon-check2:before {
  content: "\e8cb";
}

.icon-chevron-left2:before {
  content: "\e8cc";
}

.icon-chevron-right2:before {
  content: "\e8cd";
}

.icon-close2:before {
  content: "\e8ce";
}

.icon-expand-less2:before {
  content: "\e8cf";
}

.icon-expand-more2:before {
  content: "\e8d0";
}

.icon-fullscreen:before {
  content: "\e8d1";
}

.icon-fullscreen-exit:before {
  content: "\e8d2";
}

.icon-menu:before {
  content: "\e8d3";
}

.icon-more-horiz:before {
  content: "\e631";
}

.icon-more-vert:before {
  content: "\e632";
}

.icon-refresh:before {
  content: "\e8d4";
}

.icon-unfold-less:before {
  content: "\e8d5";
}

.icon-unfold-more:before {
  content: "\e8d6";
}

.icon-adb:before {
  content: "\e8d7";
}

.icon-bluetooth-audio:before {
  content: "\e8d8";
}

.icon-disc-full:before {
  content: "\e8d9";
}

.icon-dnd-forwardslash:before {
  content: "\e8da";
}

.icon-do-not-disturb:before {
  content: "\e8db";
}

.icon-drive-eta:before {
  content: "\e8dc";
}

.icon-event-available:before {
  content: "\e8dd";
}

.icon-event-busy:before {
  content: "\e8de";
}

.icon-event-note:before {
  content: "\e8df";
}

.icon-folder-special:before {
  content: "\e8e0";
}

.icon-mms:before {
  content: "\e8e1";
}

.icon-more:before {
  content: "\e8e2";
}

.icon-network-locked:before {
  content: "\e8e3";
}

.icon-phone-bluetooth-speaker:before {
  content: "\e8e4";
}

.icon-phone-forwarded:before {
  content: "\e8e5";
}

.icon-phone-in-talk:before {
  content: "\e8e6";
}

.icon-phone-locked:before {
  content: "\e8e7";
}

.icon-phone-missed:before {
  content: "\e8e8";
}

.icon-phone-paused:before {
  content: "\e8e9";
}

.icon-play-download:before {
  content: "\e8ea";
}

.icon-play-install:before {
  content: "\e8eb";
}

.icon-sd-card:before {
  content: "\e8ec";
}

.icon-sim-card-alert:before {
  content: "\e8ed";
}

.icon-sms:before {
  content: "\e8ee";
}

.icon-sms-failed:before {
  content: "\e8ef";
}

.icon-sync:before {
  content: "\e8f0";
}

.icon-sync-disabled:before {
  content: "\e8f1";
}

.icon-sync-problem:before {
  content: "\e8f2";
}

.icon-system-update:before {
  content: "\e8f3";
}

.icon-tap-and-play:before {
  content: "\e8f4";
}

.icon-time-to-leave:before {
  content: "\e8f5";
}

.icon-vibration:before {
  content: "\e8f6";
}

.icon-voice-chat:before {
  content: "\e8f7";
}

.icon-vpn-lock:before {
  content: "\e8f8";
}

.icon-cake:before {
  content: "\e8f9";
}

.icon-domain:before {
  content: "\e8fa";
}

.icon-group:before {
  content: "\e8fb";
}

.icon-group-add:before {
  content: "\e8fc";
}

.icon-location-city:before {
  content: "\e8fd";
}

.icon-mood:before {
  content: "\e8fe";
}

.icon-notifications:before {
  content: "\e633";
}

.icon-notifications-none:before {
  content: "\e8ff";
}

.icon-notifications-off:before {
  content: "\e900";
}

.icon-notifications-on:before {
  content: "\e901";
}

.icon-notifications-paused:before {
  content: "\e902";
}

.icon-pages:before {
  content: "\e903";
}

.icon-party-mode:before {
  content: "\e904";
}

.icon-people:before {
  content: "\e905";
}

.icon-people-outline:before {
  content: "\e906";
}

.icon-person:before {
  content: "\e907";
}

.icon-person-add:before {
  content: "\e908";
}

.icon-person-outline:before {
  content: "\e909";
}

.icon-plus-one:before {
  content: "\e90a";
}

.icon-poll:before {
  content: "\e90b";
}

.icon-publ:before {
  content: "\e90c";
}

.icon-school:before {
  content: "\e90d";
}

.icon-share:before {
  content: "\e90e";
}

.icon-whatshot:before {
  content: "\e90f";
}

.icon-check-box:before {
  content: "\e910";
}

.icon-check-box-outline-blank:before {
  content: "\e911";
}

.icon-radio-button-off2:before {
  content: "\e912";
}

.icon-radio-button-on2:before {
  content: "\e913";
}

.icon-star:before {
  content: "\e914";
}

.icon-star-half:before {
  content: "\e915";
}

.icon-star-outline:before {
  content: "\e916";
}

.icon-account-balance:before {
  content: "\e606";
}

.icon-help:before {
  content: "\e607";
}

.icon-highlight-remove:before {
  content: "\e619";
}

.icon-info:before {
  content: "\e61a";
}

.icon-info-outline:before {
  content: "\e61b";
}

.icon-label:before {
  content: "\e608";
}

.icon-label-outline:before {
  content: "\e609";
}

.icon-language:before {
  content: "\e60a";
}

.icon-query-builder:before {
  content: "\e61c";
}

.icon-search:before {
  content: "\e60b";
}

.icon-error:before {
  content: "\e60c";
}

.icon-warning:before {
  content: "\e60d";
}

.icon-report:before {
  content: "\e61d";
}

.icon-dehaze:before {
  content: "\e60e";
}

.icon-my-location:before {
  content: "\e60f";
}

.icon-arrow-back:before {
  content: "\e610";
}

.icon-arrow-forward:before {
  content: "\e611";
}

.icon-cancel:before {
  content: "\e612";
}

.icon-check:before {
  content: "\e613";
}

.icon-chevron-left:before {
  content: "\e614";
}

.icon-chevron-right:before {
  content: "\e615";
}

.icon-close:before {
  content: "\e616";
}

.icon-expand-less:before {
  content: "\e617";
}

.icon-expand-more:before {
  content: "\e618";
}

.icon-radio-button-off:before {
  content: "\e61e";
}

.icon-radio-button-on:before {
  content: "\e61f";
}