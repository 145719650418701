// Images
// 
// Display a photograph/graphic in the content. You can optionally configure image sources for multi-device support.
// 
// Markup:
// <!-- Basic Image -->
// <picture>
//   <source media="(min-width: 650px)" srcset="img/kitten-large.png">
//   <source media="(min-width: 465px)" srcset="img/kitten-medium.png">
//   <!-- img tag for browsers that do not support picture element -->
//   <img src="img/kitten-small.png" alt="a cute kitten">
// </picture>
// 
// Styleguide 3.10

img {
  @include rem(border-radius, 4px);
}

picture {}

figure {

  a {
  }
  a > img {
  }
  a:hover > img {
  }

}

// Video Embeds
//
// Displays a video from an external source, such as YouTube or Vimeo, without forcing the user to leave the current web page.
//
// Markup:
// <!-- YouTube -->
// <iframe width="560" height="315" src="https://www.youtube.com/embed/jnk15Wf6xMU?rel=0" frameborder="0" allowfullscreen></iframe>
// <br>
// <!-- Vimeo -->
// <iframe src="https://player.vimeo.com/video/105432007" width="560" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
//
// Styleguide 3.11

video {}
iframe {}
canvas {}