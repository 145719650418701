// Labels
//
// Displays text that associates with an object. Useful for publish dates, author, categories, tags, etc.
// 
// Markup:
// <span class="label {$modifiers}">Label name</span>
//
// .tag 		- Tag formatting
// .category	- Category formatting
// .date 		- Date formatting
//
// Styleguide 3.12

.label {
  display: inline-block;
  @include rem(font-size, 14px);
  transition: all .15s ease-in-out;

  &.tag {
    @include rem(padding, 14px 20px);
    @include rem(margin, 0 10px 10px 0);
    background-color: $color_core_gray;
    border-radius: 4px;
    color: #fff;
    font-family: $primaryFont;
    line-height: 1.2;
  }

  &.category,
  &.date {
    color: $color_core_gray;
    font-family: $primaryFontMedium;
    text-transform: uppercase;
  }
}

span.required {
  color: $color_alert_red;
  font-size: 18px;
  margin-right: 1px;
}