// Bands
//
// Full-width containers that can be used to display grouped content blocks. Bands have 3 configurations: Theme, Pad, and Parts.
//
// Markup:
// <section class="band {$modifiers}">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Band Title</h1>
//     </header>
//     <section>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// .theme-core-black				  - Core Black background
// .theme-core-blue           - Core Blue background
// .theme-core-gray           - Core Gray background
// .theme-core-light-gray     - Core Light Gray background
// .theme-core-off-white      - Core Off White background
// .theme-accent-cool-gray    - Accent Cool Gray background
// .theme-accent-warm-gray    - Accent Warm Gray background
// .theme-accent-light-blue   - Accent Light Blue background
// .theme-accent-blue         - Accent Blue background
// .theme-accent-indigo       - Accent Indigo background
// .theme-accent-deep-purple  - Accent Deep Purple background
// .theme-accent-purple       - Accent Purple background
// .theme-accent-red          - Accent Red background
// .theme-accent-orange       - Accent Orange background
// .theme-accent-olive        - Accent Olive background
// .theme-accent-green        - Accent Green background
// .theme-accent-turquoise    - Accent Turquoise background
//
// Styleguide 2.6

// Background Image
//
// Bands that use a background image for its theme. For accessibility purposes, use a theme color in case images are disabled. Images will adjust to the size of the band.
//
// Markup:
// <section class="band theme-core-black {$modifiers}" style="background-image: url(../img/sample.jpg);">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Band Title</h1>
//     </header>
//     <section>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
//
// .full-bleed        - Allows image to fill the container 
//
// Styleguide 2.6.1

// Padding
//
// Bands can use various padding configurations for spacing purposes.
//
// Markup:
// <section class="band theme-core-gray {$modifiers}">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Band Title</h1>
//     </header>
//     <section>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// .pad-none          - No top/bottom padding
// .pad-small         - 30px top/bottom padding
// .pad-large         - 120px top/bottom padding
// .pad-top           - 60px top padding only
// .pad-top-large     - 120px top padding only
// .pad-bottom        - 60px bottom padding only
// .pad-bottom-large  - 120px bottom padding only
//
// Styleguide 2.6.2

// Header
//
// Band header classes that configure various sizes.
//
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header class="{$modifiers}">
//       <h1 class="section-title">Band Title</h1>
//     </header>
//   </div>
// </section>
// 
// .parts-span-half               - One-Half
// .parts-span-third              - One-Third
// .parts-span-fourth             - One-Fourth
// .parts-span-half.shifted       - One-Half (Shifted)
// .parts-span-two-third          - Two-Third
// .parts-span-two-third.shifted  - Two-Third (Shifted)
//
// Styleguide 2.6.3

// Full
//
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Full Width (Default)</h1>
//     </header>
//     <section>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
//
// Styleguide 2.6.4


// One-Half
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Half</h1>
//     </header>
//     <section class="parts-span-half">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.5

// One-Third
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Third</h1>
//     </header>
//     <section class="parts-span-third">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.6

// One-Fourth
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Fourth</h1>
//     </header>
//     <section class="parts-span-fourth">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.7

// One-Half (1 Part)
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Half (1 Part)</h1>
//     </header>
//     <section class="parts-span-half">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.8

// One-Half (Shifted)
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Half (1 Part - Shifted)</h1>
//     </header>
//     <section class="parts-span-half shifted">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.9

// Two-Third
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Two Third</h1>
//     </header>
//     <section class="parts-span-two-third">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.10

// Two-Third (Reverse)
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Two Third (Reverse)</h1>
//     </header>
//     <section class="parts-span-two-third reverse">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.11

// Two-Third (1 Part)
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Two Third (1 Part)</h1>
//     </header>
//     <section class="parts-span-two-third">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.12

// Two-Third (Shifted)
// 
// Markup:
// <section class="band theme-core-gray">
//   <div class="wrapper">
//     <header>
//       <h1 class="section-title">Span Two Third (1 Part - Shifted)</h1>
//     </header>
//     <section class="parts-span-two-third shifted">
//       <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</div>
//     </section>
//   </div>
// </section>
// 
// Styleguide 2.6.13

.band {
  margin: 0;
  @include row();
  @include rem(padding, 30px 0);

  // Band Colors
  &.theme-core-black { background-color: $color_core_black; }
  &.theme-core-blue { background-color: $color_core_blue; }
  &.theme-core-gray { background-color: $color_core_gray; }
  &.theme-core-light-gray { background-color: $color_core_light_gray; }
  &.theme-core-off-white { background-color: $color_core_off_white; }
  
  &.theme-accent-cool-gray { background-color: $color_accent_cool_gray; }
  &.theme-accent-warm-gray { background-color: $color_accent_warm_gray; }
  &.theme-accent-light-blue { background-color: $color_accent_light_blue; }
  &.theme-accent-blue { background-color: $color_accent_blue; }
  &.theme-accent-indigo { background-color: $color_accent_indigo; }
  &.theme-accent-deep-purple { background-color: $color_accent_deep_purple; }
  &.theme-accent-purple { background-color: $color_accent_purple; }
  &.theme-accent-red { background-color: $color_accent_red; }
  &.theme-accent-orange { background-color: $color_accent_orange; }
  &.theme-accent-olive { background-color: $color_accent_olive; }
  &.theme-accent-green { background-color: $color_accent_green; }
  &.theme-accent-turquoise { background-color: $color_accent_turquoise; }

  // Band Inverted Text
  &.theme-core-black,
  &.theme-core-blue,
  &.theme-core-gray,
  &.theme-core-light-gray,
  &.theme-accent-cool-gray,
  &.theme-accent-warm-gray,
  &.theme-accent-light-blue,
  &.theme-accent-blue,
  &.theme-accent-indigo,
  &.theme-accent-deep-purple,
  &.theme-accent-purple,
  &.theme-accent-red,
  &.theme-accent-orange,
  &.theme-accent-olive,
  &.theme-accent-green,
  &.theme-accent-turquoise {
    color: $color_core_white;

    h1, h2, h3, h4, h5, h6 {
      color: $color_core_white;
    }
    a {
      color: $color_core_white;
    }
    .page-title:after {
      background-color: $color_core_white;
    }
    .section-title:after {
      background-color: $color_core_white;
    }
  }  
  .section-title {
    margin-top:0;
  }
  h1, h2, h3, h4, h5, h6 {
    &:first-of-type {
      margin-top: 0;
    }
  }
  // Band Spacing
  &.pad-none { padding: 0; }
  &.pad-small { @include rem(padding, 30px 0); }
  &.pad-large { @include rem(padding, 120px 0); }
  &.pad-top { @include rem(padding, 60px 0 0); }
  &.pad-top-large { @include rem(padding, 120px 0 0); }
  &.pad-bottom { @include rem(padding, 0 0 60px); }
  &.pad-bottom-large { @include rem(padding, 0 0 120px); }

  // Band Header & Section
  .wrapper > header,
  .wrapper > section { @include row(); }
  .wrapper > header > * { margin-top: 0; }
  .wrapper > header h1 { @include rem(margin-bottom, 30px); }

  // Band Header & Section Configuration
  .parts-span-half > * {
      @include span-columns(6 of 12);
      @include omega(2n);
  }
  .parts-span-half.shifted > * {
      @include span-columns(6 of 12);
      @include omega-reset(2n);
      @include shift(6 of 12);
  }
  .parts-span-third > * {
    @include span-columns(4 of 12);
    @include omega(3n); 
  }
  .parts-span-two-third > * {

    @include span-columns(4 of 12);
  
    &:nth-child(2) {
      @include span-columns(8 of 12);
    }
  }
  .parts-span-two-third.reverse > * {
    &:nth-child(odd) {
      @include span-columns(4 of 12);
    }
    &:nth-child(even) {
      @include span-columns(8 of 12);
    }
  }

  .parts-span-two-third.shifted > * {
    @include span-columns(8 of 12);
    @include shift(4 of 12);
  }
  .parts-span-fourth > * {
    @include span-columns(3 of 12);
    @include omega(4n);
  }

}
