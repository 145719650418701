// Back to Top
//
// Displays a link/button when a user is no longer at the top of the document, allowing them to quickly scroll back to the top.
// 
// Markup:
// <!-- we'll point href to #content-container for website -->
// <a id="back-to-top" class="back-to-top" href="#kss-node" title="Back to top">
//   <span class="icon-vertical-align-top" aria-hidden="true"></span>
//   <span class="visuallyhidden">Back to top</span>
// </a>
// 
// Styleguide 4.9

.back-to-top {
  display: inline-block;
  background-color: $color_core_gray;
  box-shadow: 0 0 0 0 rgba($color_core_gray, .2);
  color: #fff;
  line-height: 64px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  @include rem(width, 64px);
  @include rem(height, 64px);
  @include rem(font-size, 24px);
  transition: all .15s ease-in-out;

  span {
    display: inline-block;
    transition: all .15s ease-in-out;
    //transform: translateY(3px);
  }

  &:hover {
    box-shadow: 0 0 0 10px rgba($color_core_gray, .2);
    color: #fff;
  }
  &:hover span {
    //transform: translateY(1px);
  }
}