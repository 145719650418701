// Header
// 
// Every page will contain a header. It consists of a configurable <strong>Topical Area</strong> and a persistent <strong>Enterprise Area</strong>.
// 
// Styleguide 2.3

.header-container {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // z-index: 100;

  @include media($desktop) {
    //position: relative;  
  }

  .logo {
    float: left;
    margin: 0;
    a {
      display: block;
      // @include rem(width, 148px);
      // @include rem(height, 39px);
      margin: 0;

      img {
        margin: 0;
        float: left;
      }
    }
  }  
}

.login-logout {
  float: right;
  clear: none;
}



// Header Navigation
// 
// Markup:
// <div class="header-container">
//   <header class="group">
//     <div class="utility-wrapper wrapper">
//       <div class="header-search">
//         <div class="search">
//           <form>
//             <input type="search" placeholder="Search..." />
//             <input type="submit" value="" /><span class="icon-search" aria-hidden="true"></span>
//           </form>
//           <div class="popular">
//             <h3>Popular Searches on ncdcr.gov:</h3>
//             <ul class="trending">
//               <li><a href="#">Historical Newspapers</a></li>
//               <li><a href="#">Shop NC Art</a></li>
//               <li><a href="#">Raleigh Exhibits</a></li>
//               <li><a href="#">History Museum</a></li>
//               <li><a href="#">Blackbeard</a></li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div class="alert-widget">
//         <button>
//           <span class="icon-notifications" aria-hidden="true"></span>
//           <div class="alert-count">2</div>
//         </button>
//       </div>
//     </div>
//     <div class="mobile-nav">
//       <button class="flyout-trigger open-trigger"><span class="icon-dehaze" aria-hidden="true"></span><span> Menu</span></button>
//       <h1 class="site-title"><a href="/" rel="home" title="NC DCR Home">NC DCR</a></h1>
//       <button class="search-trigger"><span>Search </span><span class="icon-search" aria-hidden="true"></span></button>
//     </div>
//     <div class="flyout-panel menu-container mainMenu" id="mainMenu">
//       <div aria-label="First level" class="">
//         <button href="#" class="flyout-trigger close-trigger" aria-hidden="true"><span class="icon-close" aria-hidden="true"></span><span>Close Menu</span></button>
//         <nav class="topical-nav " role="navigation" aria-label="Topical Navigation">
//           <ul class="wrapper">
//             <li class="home"><a href="#"><span>Home</span></a></li>
//             <li class="has-mega has-dropdown">
//               <a href="#">About <span class="icon-chevron-right" aria-hidden="true"></span></a>
//               <div aria-label="Second level">
//                 <a href="#" class="parent-title wrapper">About</a>
//                 <ul class="wrapper">
//                   <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                   <li>
//                     <a href="#">Level 2 Link Name <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Thirdlevel">
//                       <span class="parent-title" aria-hidden="true">Level 2 Link Name</span>
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                   <li>
//                     <a href="#">Level 2 Link Name <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Thirdlevel">
//                       <span class="parent-title" aria-hidden="true">Level 2 Link Name</span>
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                   <li>
//                     <a href="#">Level 2 Link Name <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Thirdlevel">
//                       <span class="parent-title" aria-hidden="true">Level 2 Link Name</span>
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                   <li>
//                     <a href="#">Level 2 Link Name <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Thirdlevel">
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                   <!-- <li class="promo"><a href="#"><img src="http://placehold.it/150x100"></a><a href="#">Promotional Graphic Link</a><p>Text here</p></li>
//                   <li class="promo"><img src="http://placehold.it/150x100"></li> -->
//                 </ul>
//               </div>
//             </li>
//             <li class="expanded has-dropdown"><a href="#">Education <span class="icon-chevron-right" aria-hidden="true"></span></a>
//               <div aria-label="Second level">
//                 <span class="parent-title" aria-hidden="true">About</span>
//                 <ul>
//                   <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                   <li><a href="#">Overview</a></li>
//                   <li><a href="#">Our Divisions</a></li>
//                   <li>
//                     <a href="#">Leadership Team <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Third level">
//                       <span class="parent-title">Leadership Team</span>
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Overview</a></li>
//                         <li><a href="#">Contact</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                 </ul>
//               </div>
//             </li>
//             <li><a href="#">Resources</a></li>
//             <li><a href="#">News</a></li>
//           </ul>
//         </nav>
//         <nav class="enterprise-nav" role="navigation" aria-label="Enterprise Navigation">
//           <ul class="wrapper">
//             <li><a href="#" title="NC Gov"><span class="icon-nc-brand" aria-hidden="true"></span><span>NC.Gov</span></a></li>
//             <li><a href="#" title="Agencies"><span class="icon-account-balance" aria-hidden="true"></span><span>Agencies</span></a></li>
//             <li><a href="#" title="Jobs"><span class="icon-wallet-travel" aria-hidden="true"></span><span>Jobs</span></a></li>
//             <li><a href="#" title="Services"><span class="icon-phonelink" aria-hidden="true"></span><span>Services</span></a></li>
//           </ul>
//         </nav>
//       </div>
//     </div>
//   </header>
// </div>
//
// Styleguide 2.3.1

// Mobile Nav
.mobile-nav {
  background: $color_core_blue;
  text-align: center;
  @include rem(height, 60px);
  @include rem(font-size, 20px);

  @include media($desktop) {
    height: 0;
  }
  
  h1, div, a, button {
    color: $color_core_white;
    background: transparent;
    display: inline-block;
    outline: none;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    text-decoration: none;
    @include rem(font-size, 18px);
    @include rem(line-height, 60px);

    @include media($desktop) {
      display: none;
    }
  }
  h1, div {
    width: 40%;
    height: 100%;
    overflow: hidden;

    @include media($desktop) {
      position: absolute;
      left: 3.125%;
      display: block;
      z-index: 10000;
      @include rem(top, 20px);
      @include rem(width, 290px);
      @include rem(height, 40px);
    }
    @include media($desktop_wide) {
      left: 50%;
      @include rem(margin-left, -570px);
    }

    a {
      display: block;
      width: 100%;
      height: 100%;

      @include media($desktop) {
        display: block;
        font-size: 0;
        line-height: 0;
        width: 100%;
        height: 100%;
        
        background-image: $agency_logo;
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: auto;

        transition: opacity $animation-speed $animation-easing;
        .no-svg & {
          background-image: $agency_logo_png;
        }
      }
    }
    a:hover {
      @include media($desktop) {
        opacity: .8;
      }
    }
  }
  span {
    display: inline-block;
  }
  
  // Open trigger
  .open-trigger {
    float: left;

    *:first-child {
      float: left;
    }
    *:last-child {
      display: none;
      
      @include media($tablet) {
        display: inline-block;
      }
    }
  }
  
  // Search trigger
  .search-trigger {
    float: right;
    
    *:first-child {
      display: none;
      
      @include media($tablet) {
        display: inline-block;
      }
    }
    *:last-child {
      float: right;
      @include rem(line-height, 60px);
    }
  }

  // Icons
  [class^="icon-"], [class*=" icon-"] {
    @include rem(font-size, 24px);
    @include rem(line-height, 60px);
    @include rem(padding, 0 20px);
  }
}

// Close Trigger
.close-trigger {
  background: $color_core_blue;
  display: inline-block;
  width: 100%;
  padding: 0;
  color: $color_core_white;
  text-align: left;
  line-height: 0;
  text-transform: uppercase;
  font-size: 0;
  @include rem(height, 60px);

  span {
    display: inline-block;
    vertical-align: middle;
    @include rem(font-size, 14px);
    @include rem(line-height, 60px);
  }
  [class^="icon-"], [class*=" icon-"] {
    font-size: 28px;
    padding: 0 20px;
  }
}

// Back Trigger
.back-trigger {
  button {
    background: darken($color_core_blue, 5%);
    display: inline-block;
    width: 100%;
    padding: 0;
    color: $color_core_white;
    text-align: left;
    text-transform: uppercase;
    @include rem(line-height, 60px);
    @include rem(font-size, 14px);
  }
  span {
    display: inline-block;
    vertical-align: middle;
    @include rem(line-height, 58px);
  }
  [class^="icon-"], [class*=" icon-"] {
    vertical-align: top;
    @include rem(font-size, 36px);
    @include rem(padding, 0 20px);
    @include rem(line-height, 54px);
  }
}

// Menu Container
.menu-container {
  background-color: $menu-bg-color;
  background-image: $menu-bg-poly;
  background-size: cover;
  background-position: center;

  @include media($desktop) {
    position: relative;
    background: none;
    @include rem(padding-top, 80px);
    
    .close-trigger, .back-trigger {
      display: none !important;
    }
  }
}

// Topical Nav
.topical-nav {
  @include media($desktop) {
    background-color: $menu-bg-color;
    background-image: $menu-bg-poly;
    //background-size: cover;
    background-position: center;
    @include rem(height, 80px);
    //fix for bug in scroll magic
    width: 100% !important;
  }

  h3, .parent-title {
    @extend h3;
    background: $color_core_white;
    margin: 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    color: $color_core_blue;
    text-transform: capitalize;
    @include rem(padding, 20px);

    @include media($desktop) {
      padding-left: 0;
      background: none;
    }
  }
  h3 {
    @include media($desktop) {
      float: left;
      text-align: left;
      width: auto;
    }
  }
  > ul {
    > li:hover {
      @include media($desktop) {
        background-color: rgba(#000, .25);
      }
    }
    > li > div {
      @include rem(top, 60px);
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media($desktop) {
      font-size: 0;
      display: block;
      margin: 0 auto;
    }
    
    li {
      margin: 0;
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid $color_core_white;
      vertical-align: middle;
      transition: all $animation-speed $animation-easing;

      @include media($desktop) {
        width: auto;
        border: none;
        // padding: 20px 15px;
        z-index: 100;
        line-height: 1;
        padding: 0;
      }
    } 
    li.home {
      background-color: $menu-bg-color;
      background-image: $menu-bg-poly;
      background-size: cover;
      background-position:center;
    
      @include media($desktop) {
        background: none;
        float:left;
      }
    }
    li.home:hover {
      @include media($desktop) {
        background-color: rgba(#000, .25);
      }
    }
    li.home a {
      @include media($desktop) {
        @include rem(padding, 32px 20px);
      }
    }
    li.home a {
      display: block;
      width: 100%;
      background: $agency_logo no-repeat 30px 50% / auto 40px;
      font-size: 0;
      line-height: 0;
      @include rem(height, 80px);

      @include media($desktop) {
        height: auto;
        background: none;
        line-height: 1;
        @include rem(padding, 32px 20px);
        @include rem(font-size, 16px);
      }
    }   
    li.current-page {
      > a {
        @include media($desktop) {
          color: $color_core_white;  
          background-color: rgba(#000, .25);
          text-decoration: none;
        }
      }
    } 
    li a {
      font-size: 14px;
      color: $color_core_white;
      display: inline-block;
      position: relative;
      width: 100%;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
      font-family: $primaryFontMedium;
      @include rem(padding, 20px 30px);

      @include media($desktop) {
        width: auto;
        padding: 0;
        display: block;
        // line-height: 80px;
        text-transform: capitalize;
        font-family: $primaryFontMedium;
        color: $color_core_off_white;
        font-size: 16px;
        text-decoration: underline;
        position: relative;
        @include rem(padding, 32px 20px);
      }
    }
    li a [class^="icon-"], li a [class*=" icon-"] {
      position: absolute;
      top: 50%;
      @include rem(font-size, 36px);
      @include rem(right, 20px);
      @include rem(margin-top, -18px);
    }
    li a span {
      @include media($desktop) {
        display: none;
      }
    }

    li.has-dropdown {
      @include media($desktop) {
        z-index: 100;
        position: relative;
        backface-visibility: hidden;
      }      
        
      > a {
        @include media($desktop) {
          padding-right: 45px;
          z-index: 100;
          text-decoration: none;  
        }        
        
        span[class^="icon-"] {
          @include media($desktop) {
            display: block;
            transition: all $animation-speed $animation-easing;
            transform: rotate(90deg);
            color: $color_core_off_white;
            font-size: 24px;
            right: 20px;
            top: 50%;
            @include rem(margin-top, -12px);  
          }          
        }
      }
      > div {
        @include media($desktop) {
          background: $color_core_white;
          border-width: 0;
        }
      }
      &.is-open {
        @include media($desktop) {
          z-index: 100;  
        }        

        a {
          >span[class^="icon-"] {
            @include media($desktop) {
              transform: rotate(270deg);
            }            
          }
        }
        ul li {
          a {
            @include media($desktop) {
              color: $accent_link;
              font-family: $primaryFontMedium;  
            }            
          }
        }
        > div {
          @include media($desktop) {
            display: block;
            border-width: 1px;
            border-color: lighten($color_core_gray, 35%);
            border-style: solid;
            border-top: 0;
            @include rem(max-height, 900px);  
          }
          div ul li a {
            @include media($desktop) {
              color: $color_core_light_gray;
              font-family: $primaryFont;

            }
          }          
        }
      }

      > div {
        @include media($desktop) {
          z-index: 99;
          top: 80px;
          //display: none;
          max-height: 0;
          line-height: 0;
          left: 0px;          
          border: 1px solid rgba($color_core_gray, 0);
          border-bottom: 0;
          border-top: 0;
          overflow: hidden;
          transition: all $animation-speed $animation-easing;
        }          

        .parent-title {
          @include media($desktop) {
            display: none;  
          }          
        }

        ul {
          @include media($desktop) {
            min-width: 200px;
            padding: 0;
            //margin-top: 5px;  
          }          
        
          li {
            @include media($desktop) {
              display: block;
              padding: 0 20px;
              border-top: 1px solid $color_core_off_white;
              line-height: 1.5;  
            }            

            a {
              @include media($desktop) {
                font-size: 14px;
                text-decoration: underline;
                padding: 16px 0;  
              }              
        
              &:after {
                @include media($desktop) {
                  border: none;  
                }                
              }
              &:hover {
                @include media($desktop) {
                  text-decoration: none;  
                }                
              }
            }

            div {
              @include media($desktop) {
                display: none;  
              }              
            }
          }
        }
      }
    }
    li.has-mega {
      @include media($desktop) {
        z-index: 100;
        position: relative;
        backface-visibility: hidden;
      }      

      > a {
        @include media($desktop) {
          padding-right: 45px;
          z-index: 100;
        }        

        > span[class^="icon-"] {
          @include media($desktop) {
            display: block;
            transition: all $animation-speed $animation-easing;
            transform: rotate(90deg);
            // color: $color_core_gray;
            font-size: 24px;
            right: 20px;
            top: 50%;
            @include rem(margin-top, -12px);  
          }          
        }
      }
      .promo {
        img {
          @include media($desktop) {
            border-radius:0;
            width: 100%;
            height: auto;  
          }          
        }
        a {
          @include media($desktop) {
            display:block;
            margin:0; 
            padding: 0; 
          }          
          &:after {
            @include media($desktop) {
              border-bottom:none;  
            }            
          }
        }
        p {
          @include media($desktop) {
            font-size: 14px;  
          }          
        }
      }
      &.is-open {
        @include media($desktop) {
          z-index: 100;
          overflow: visible;
        }
        
        a {
          >span[class^="icon-"] {
            @include media($desktop) {
              transform: rotate(270deg);
            }            
          }
        }
        > div {
          @include media($desktop) {
            display: block;
            border: 0;
            border-bottom: 1px solid lighten($color_core_gray, 35%);
            @include rem(max-height, 900px);
            @include rem(padding, 60px 0);  
          }          
        }
      }
      > div {
        @include media($desktop) {
          z-index: 99;
          top: 80px;
          min-width:400px;
          line-height: 0;
          left: -20px;
          background: $color_core_white;
          border: 0;
        }

        > .parent-title {
          @include rem(padding, 20px);
          
          @include media($desktop) {
            display: block;
            margin: 0 auto;
            text-align: left;
            color: $accent_link;
            line-height: 1.25;
            padding: 0;
            @include rem(font-size, 36px);
            @include rem(margin-bottom, 30px);  
          }          
        }
        > .parent-title:hover {
          @include media($desktop) {
            color: saturate(lighten($accent_link, 5), 10);
            text-decoration: none;
          }
        }

        > ul {
          @include media($desktop) {
            padding: 0;
            margin: 0 auto;
            @include row();  
          }          

          > li {
            @include media($desktop) {
              margin-top:0;
              padding: 0;
              line-height: 1.5;
              @include span-columns(3 of 12);
              &:nth-child(4n+1) {
                margin-right: 0;
              }
            }            
            
            a {
              @include media($desktop) {
                //margin: 10px 20px;
                color: $accent_link;
              }              
            }
            div {
              @include media($desktop) {
                position: relative;
                display: block;  
              }              
            }
            
            ul {  
              @include media($desktop) {
                margin:0;
                padding:0;  
              }              

              li {
                @include media($desktop) {
                  padding:0;  
                }                
                
                a {
                 @include media($desktop) {
                  color: $color_alert_blue;
                   font-size: 14px; 
                 }                 
                }
              }
            }
          }
        }
      }
    }
    li a {
      &:hover {
        &:after {
          border: none;
        }
      }

    }
    li div {
      // display: none;
      position: absolute;
    }
  }
  .jb-overflowmenu-menu-secondary .has-dropdown {

    > a {
        text-decoration: underline;
    }
    .icon-chevron-right {
      display: none;
    }
  }
}

// Header Navigation - Mega Menu
// 
// Markup:
// <div class="header-container">
//   <header class="group">
//     <div class="utility-wrapper wrapper">
//       <div class="header-search">
//         <div class="search">
//           <form>
//             <input type="search" placeholder="Search..." />
//             <input type="submit" value="" /><span class="icon-search" aria-hidden="true"></span>
//           </form>
//           <div class="popular">
//             <h3>Popular Searches on ncdcr.gov:</h3>
//             <ul class="trending">
//               <li><a href="#">Historical Newspapers</a></li>
//               <li><a href="#">Shop NC Art</a></li>
//               <li><a href="#">Raleigh Exhibits</a></li>
//               <li><a href="#">History Museum</a></li>
//               <li><a href="#">Blackbeard</a></li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div class="alert-widget">
//         <button>
//           <span class="icon-notifications" aria-hidden="true"></span>
//           <div class="alert-count">2</div>
//         </button>
//       </div>
//     </div>
//     <div class="mobile-nav">
//       <button class="flyout-trigger open-trigger"><span class="icon-dehaze" aria-hidden="true"></span><span> Menu</span></button>
//       <h1 class="site-title"><a href="/" rel="home" title="NC DCR Home">NC DCR</a></h1>
//       <button class="search-trigger"><span>Search </span><span class="icon-search" aria-hidden="true"></span></button>
//     </div>
//     <div class="flyout-panel menu-container mainMenu" id="mainMenu">
//       <div aria-label="First level" class="">
//         <button href="#" class="flyout-trigger close-trigger" aria-hidden="true"><span class="icon-close" aria-hidden="true"></span><span>Close Menu</span></button>
//         <nav class="topical-nav " role="navigation" aria-label="Topical Navigation">
//           <ul class="wrapper">
//             <li class="home"><a href="#">Home</a></li>
//             <li class="has-mega has-dropdown">
//               <a href="#">About <span class="icon-chevron-right" aria-hidden="true"></span></a>
//               <div aria-label="Second level">
//                 <a href="#" class="parent-title wrapper">About</a>
//                 <ul class="wrapper">
//                   <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                   <li>
//                     <a href="#">Level 2 Link Name <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Thirdlevel">
//                       <span class="parent-title" aria-hidden="true">Level 2 Link Name</span>
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                   <li>
//                     <a href="#">Level 2 Link Name <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Thirdlevel">
//                       <span class="parent-title" aria-hidden="true">Level 2 Link Name</span>
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                   <li>
//                     <a href="#">Level 2 Link Name <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Thirdlevel">
//                       <span class="parent-title" aria-hidden="true">Level 2 Link Name</span>
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                   <li>
//                     <a href="#">Level 2 Link Name <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Thirdlevel">
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Level 3 Link Name</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                   <!-- <li class="promo"><a href="#"><img src="http://placehold.it/150x100"></a><a href="#">Promotional Graphic Link</a><p>Text here</p></li>
//                   <li class="promo"><img src="http://placehold.it/150x100"></li> -->
//                 </ul>
//               </div>
//             </li>
//             <li class="expanded has-dropdown"><a href="#">Education <span class="icon-chevron-right" aria-hidden="true"></span></a>
//               <div aria-label="Second level">
//                 <span class="parent-title" aria-hidden="true">About</span>
//                 <ul>
//                   <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                   <li><a href="#">Overview</a></li>
//                   <li><a href="#">Our Divisions</a></li>
//                   <li>
//                     <a href="#">Leadership Team <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                     <div aria-label="Third level">
//                       <span class="parent-title">Leadership Team</span>
//                       <ul>
//                         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                         <li><a href="#">Overview</a></li>
//                         <li><a href="#">Contact</a></li>
//                       </ul>
//                     </div>
//                   </li>
//                 </ul>
//               </div>
//             </li>
//             <li><a href="#">Resources</a></li>
//             <li><a href="#">News</a></li>
//           </ul>
//         </nav>
//         <nav class="enterprise-nav" role="navigation" aria-label="Enterprise Navigation">
//           <ul class="wrapper">
//             <li><a href="#" title="NC Gov"><span class="icon-nc-brand" aria-hidden="true"></span><span>NC.Gov</span></a></li>
//             <li><a href="#" title="Agencies"><span class="icon-account-balance" aria-hidden="true"></span><span>Agencies</span></a></li>
//             <li><a href="#" title="Jobs"><span class="icon-wallet-travel" aria-hidden="true"></span><span>Jobs</span></a></li>
//             <li><a href="#" title="Services"><span class="icon-phonelink" aria-hidden="true"></span><span>Services</span></a></li>
//           </ul>
//         </nav>
//       </div>
//     </div>
//   </header>
// </div>
// 
// Styleguide 2.3.2

.enterprise-nav {
  background: $color_core_blue;
  font-family: $primaryFontMedium;
  @include rem(padding-top, 20px);

  @include media($desktop) {
    @include rem(height, 80px);
  }

  ul {
    margin: 0;
    padding: 0;
    
    li {
      display: inline-block;
      float: left;
      margin-top: 0;
      width: 50%;
      
      @include media($tablet) {
        width: 100%;
      }
      @include media($desktop) {
        width: auto;
      }

      &:nth-child(odd) {
        clear: left;
      }

      a {
        color: $color_core_white;
        text-transform: uppercase;
        text-decoration: none;
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        @include rem(padding, 10px 15px);
        @include rem(font-size, 12px);

        @include media($tablet) {
          text-align: left;
          @include rem(padding, 10px 30px);
        }

        img {
          @include media($tablet) {
            @include rem(margin-right, 10px);
            @include rem(margin-bottom, 5px);
          }
        }
        span {
          &:first-child {
            display: inline-block;
            border-radius: 50%;
            border: 3px solid $color_core_white;
            vertical-align: middle;
            @include rem(padding, 10px);
            @include rem(margin-bottom, 10px);
            @include rem(font-size, 25px);

            @include media($tablet) {
              @include rem(margin-right, 10px);
              @include rem(margin-bottom, 5px);              
            }
          }
          &:last-child {
            display: block;

            @include media($tablet) {
              display: inline-block;
            }
          }
        }

        // NC.Gov specific link
        span.icon-nc-brand {
          @extend [class^="icon-"];
        }
        span.icon-nc-brand:before {
          display: inline-block;
          content: "";
          background: url('../img/i_ncgov.png') no-repeat 50% 50% / 100% auto;
          background: url('../img/i_ncgov.svg') no-repeat 50% 50% / 100% auto;
          @include rem(width, 25px);
          @include rem(height, 25px);
        }
      }
    }
    // Agency Home
    // li:first-child {
    //   a {
    //     transition: opacity $animation-speed $animation-easing;

    //     span:first-child {
    //       @include rem(width, 51px);
    //       @include rem(height, 51px);
        
    //       @include media($desktop) {
    //         display: inline-block;
    //         width: auto;
    //         height: auto;
    //         padding: 0;
    //         border: 0;
    //         border-radius: 0;
    //         @include rem(margin, 0 0 0 20px);
    //       }
    //     }
    //     span:last-child {
    //       @include media($desktop) {
    //         display: none;
    //       }
    //     }
    //     img {
    //       display: block;
    //       border-radius: 0;
    //       @include rem(margin-top, 4px);
    //     }  
    //   }
    //   a:hover {
    //     @include media($desktop) {
    //       opacity: .8;
    //     }
    //   }      
    // }
  }
  @include media($tablet) {
    .contents-less & {
      position: absolute;
      bottom: 0;
    }
  }
  @include media($desktop) {
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 0;
    ul {
      margin: 0 auto;
      text-align: right;
      li {
        float: none;
        // &:first-child {
        //   float: left;
        // }
        &:last-child {
          margin-right: 80px;
        }
        a {
          line-height: 70px;
          padding: 5px;
          span:first-child {
            display: none;
          }

          span {
            line-height: 13px;
            border-bottom: 1px solid rgba($color_core_white, 0.5);
          }
          &:hover {
            opacity: .8;
            span {
              border: none;
            }
          }
        }
      }
    }
    .contents-less & {
      // position:relative;
      bottom: auto;
    }

  }

}

.header-container {
// .is-toggled
.utility-wrapper {
  position: relative;
  background-color: darken($color_core_blue, 2.5%);
}
  .header-search {
    width: 100%;
    max-height: 0;
    overflow:hidden;
    z-index: 120;
    transition: all $animation-speed $animation-easing;

    &.is-toggled {
      max-height: 500px;
    }

    .search {
      @include rem(padding, 15px 0);
      
      @include media($tablet) {
        @include rem(padding, 30px 0);
      }
      @include media($desktop) {
        @include rem(padding, 30px);
      }
    }

    @include media($desktop) {
      position: absolute;
      height: auto;
      top: 20px;
      max-height: 500px;
    
      @include span-columns(4 of 12);
      @include shift-in-context(4 of 12);
      
      .search {
        padding: 0;
      }
    }
    @include media($desktop_wide) {
      // max-width: 500px;
    }

  }
  .alert-widget {
    display: none;
    
    @include media($desktop) { 
      position: absolute;
      right: 0;
      top: 10px;
      display: block;
      z-index: 10;
    }

  }
  &.no-alerts {
    .alert-widget {
      display: none;
    }
    .enterprise-nav ul li:last-child {
      margin-right: 0;
    }
  }
}
