//==================================================================
// Typography Partial
// Styles for overall typography and misc. content components.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Typography
//
// To help enhance readability across all devices, we will use the various
// weights from Transport New, a font family originally designed for traffic 
// signage in Europe. We’ll accent that family with Georgia family, as needed. 
// We’ll use a 16-px baseline, tiered structure for heading sizes.
// 
// Styleguide 1.3

// Typefaces
// 
// <div class="styleguide typography">
// <h6>Transport New • Heavy</h6>
// <p class="heavy">ABCDEFGHIJKLMNOPQRSTUVWXYZ<br/>
// abcdefghijklmnopqrstuvwxyz<br/>
// 0123456789</p>
// <h6>Transport New • Medium</h6>
// <p class="medium">ABCDEFGHIJKLMNOPQRSTUVWXYZ<br/>
// abcdefghijklmnopqrstuvwxyz<br/>
// 0123456789</p>
// <h6>Transport New • Light</h6>
// <p class="light">ABCDEFGHIJKLMNOPQRSTUVWXYZ<br/>
// abcdefghijklmnopqrstuvwxyz<br/>
// 0123456789</p>
// <h6>Georgia • Italic</h6>
// <p class="georgia">ABCDEFGHIJKLMNOPQRSTUVWXYZ<br/>
// abcdefghijklmnopqrstuvwxyz<br/>
// 0123456789</p>
// </div> 
// 
// Styleguide 1.3.1

// Hierarchy
// 
// Markup: 
// <h1>Heading 1 - 48px</h1>
// <h2>Heading 2 - 36px</h2>
// <h3>Heading 3 - 24px</h3>
// <h4>Heading 4 - 21px</h4>
// <h5>Heading 5 - 18px</h5>
// <h6>Heading 6 - 16px</h6>
// <p>Body paragraph - 16px</p>
// 
// Styleguide 1.3.2

// Custom Fonts
// =================================================================
@font-face {
  font-family: 'TransportNewHeavy';
  src: url('fonts/TransportNewHeavy_gdi.eot');
  src: url('fonts/TransportNewHeavy_gdi.eot?#iefix') format('embedded-opentype'),
    url('fonts/TransportNewHeavy_gdi.woff') format('woff'),
    url('fonts/TransportNewHeavy_gdi.ttf') format('truetype'),
    url('fonts/TransportNewHeavy_gdi.otf') format('opentype'),
    url('fonts/TransportNewHeavy_gdi.svg#TransportNewHeavy') format('svg');
}
@font-face {
  font-family: 'TransportNewLight';
  src: url('fonts/TransportNewLight_gdi.eot');
  src: url('fonts/TransportNewLight_gdi.eot?#iefix') format('embedded-opentype'),
    url('fonts/TransportNewLight_gdi.woff') format('woff'),
    url('fonts/TransportNewLight_gdi.ttf') format('truetype'),
    url('fonts/TransportNewLight_gdi.otf') format('opentype'),
    url('fonts/TransportNewLight_gdi.svg#TransportNewLight') format('svg');
}
@font-face {
  font-family: 'TransportNewMedium';
  src: url('fonts/TransportNewMedium_gdi.eot');
  src: url('fonts/TransportNewMedium_gdi.eot?#iefix') format('embedded-opentype'),
    url('fonts/TransportNewMedium_gdi.woff') format('woff'),
    url('fonts/TransportNewMedium_gdi.ttf') format('truetype'),
    url('fonts/TransportNewMedium_gdi.otf') format('opentype'),
    url('fonts/TransportNewMedium_gdi.svg#TransportNewMedium') format('svg');
}

// Global
// =================================================================
html, body {
  background-color: $color_core_blue;
  font-family: $primaryFont;
  line-height: 1.5;
  @include rem(font-size, 16px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: normal;
  backface-visibility: hidden;
}

// Headings
// =================================================================
h1, h2, h3, h4, h5, h6 {
  color: $color_core_blue;
  font-family: $primaryFontMedium;
  font-weight: normal;
}

h1 {
  line-height: 1.25;
  word-break: break-word;
  @include rem(margin, 30px 0);
  @include rem(font-size, 48px);
}
h2 { @include rem(font-size, 36px); }
h3 { @include rem(font-size, 24px); }
h4 { @include rem(font-size, 21px); }
h5 { @include rem(font-size, 18px); }
h6 { @include rem(font-size, 16px);   
}

