// Calendar
//
// Displays a mini-calendar widget that allows users to select a specific date.
//
// Markup:
// <div id="datepicker-demo" class="ui-datepicker-wrapper"></div>
// 
// Styleguide 4.15

.ui-datepicker-wrapper {
  //border: 1px solid $color_core_gray;
  overflow: hidden;
  @include rem(margin-bottom, 30px);
  @include rem(border-radius, $border_radius);
  transition: opacity $animation-speed $animation-easing;

  @include media($tablet) {
    @include span-columns(3 of 6);
    float: none;
    margin: 0 auto;
    @include rem(margin-bottom, 30px);
  }
  @include media($desktop) {
    @include span-columns(4 of 12);
    @include omega();
    //float: right;
    @include rem(margin-top, 34px);
  }

  // Active States
  &.is-inactive {
    @extend .visuallyhidden;
    opacity: 0;
  }
  &.is-active {
    opacity: 1;
  }
}

.ui-datepicker-selected-day {
  background-color: $color_core_blue;
  color: #fff;
  @include rem(padding, 30px);
  @include rem(font-size, 32px);

  .week-day {
    display: block;
    @include rem(font-size, 21px);
  }
}

.ui-datepicker {
  @include rem(width, 300px);
  background: $color_core_white;
  padding: 0;
  border: 1px solid $color_core_light_gray;
  overflow: hidden;
  

  .ui-datepicker-header {
    background-color: $color_core_gray;
    color: #fff;
    @include rem(padding, 5px 0);

    .ui-datepicker-title {
      margin: 0;
      font-family: $primaryFontMedium;
      vertical-align: sub;
      @include rem(line-height, 40px);
    }
  }
  .ui-datepicker-prev,
  .ui-datepicker-next {
    @extend [ class *= " icon-" ];
    top: 25%;
    color: #fff;
    //position: relative;
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    @include rem(width, 32px);
    @include rem(height, 32px);
    @include rem(margin-top, -16px);
    @include rem(font-size, 27px);
    transition: all $animation-speed $animation-easing;

    &:before {
      color: #fff;
      top: -2px;
      left: -2px;
      position: absolute;
      text-align: center;
      @include rem(width, 32px);
      @include rem(height, 32px);
    }

    &:hover {
      box-shadow: 0 0 0 5px rgba($color_core_white, .2);
    }

    span {
      display: none;
    }
  }
  .ui-datepicker-prev {
    @extend .icon-chevron-left;
    @include rem(left, 20px);
  }
  .ui-datepicker-next {
    @extend .icon-chevron-right;
    @include rem(right, 20px);

     &:before {
      left: -1px;
    }

  }

  // Calendar
  // -------------------------------------------------------------
  .ui-datepicker-calendar {
    margin: 0;

    th, td {
      padding: 0;
      border: 0;
      text-align: center;
    }
    th span, td span, td a {
      display: block;
      text-align: center;
      @include rem(padding, 10px);
      @include rem(border-radius, $border_radius);
      transition: all $animation-speed $animation-easing;
    }
    thead th, thead td {
      background: none;
      color: $color_core_gray;
    }
    tbody td {
      cursor: pointer;
    }
    tbody td a:hover {
      background-color: $color_core_off_white;
    }
    tbody td a.ui-state-active {
      background-color: $accent_link;
      color: #fff;
    }
    tbody td.prev-month,
    tbody td.next-month {
      color: $color_core_light_gray;
    }
    tbody tr:nth-child(even) th,
    tbody tr:nth-child(even) td {
      background: none;
    }

  }

}