// Content
// 
// Content area specific style.
// 
// Styleguide 2.5

// Page Title
//
// Markup:
// <h1 class="page-title">Page Title</h1>
//
// Styleguide 2.5.1

.page-title {
  &:after {
    content: "";
    display: block;
    background-color: $accent_page_title;
    @include rem(height, 10px);
    @include rem(margin-top, 21px);

    @include media($tablet) {
      width: 50%;
    }
    @include media($desktop) {
      width: 260px;
    }
  }
}

// Section Title
// 
// Markup:
// <h1 class="section-title">Section Title</h1>
//
// Styleguide 2.5.2

.section-title {
  //margin: 0;
  @include rem(font-size, 21px);

  @include media($tablet) {
    @include rem(font-size, 24px);
  }
  @include media($desktop) {
    @include rem(font-size, 36px);
  }
  
  &:after {
    content: "";
    display: block;
    background-color: $color_core_blue;
    @include rem(height, 4px);
    @include rem(margin-top, 10px);
  }

}