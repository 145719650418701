//==================================================================
// Search Form Partial
// Styles for the search form.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Search Bar
//
// Global search bar
// 
// <div class="search">
//   <form>
//     <input type="search" placeholder="Search..." />
//     <input type="submit" value="" /><span class="icon-search" aria-hidden="true"></span>
//   </form>
//   <div class="popular">
//     <h3>Popular Searches on ncdcr.gov:</h3>
//     <ul class="trending">
//       <li><a href="#">Historical Newspapers</a></li>
//       <li><a href="#">Shop NC Art</a></li>
//       <li><a href="#">Raleigh Exhibits</a></li>
//       <li><a href="#">History Museum</a></li>
//       <li><a href="#">Blackbeard</a></li>
//     </ul>
//   </div>
// </div>
// 
// Styleguide 4.19

.search {
  display: inline-block;
  width: 100%;
  position: relative;
  font-size: 0;
  border-radius: $border_radius;
  overflow: hidden;

  form {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  input[type="search"] {
    float: left;
    border: 0;
    box-shadow: none;
    @include rem(height, 40px);
    @include rem(padding, 10px);

    &:hover {
      box-shadow: 0 0 0 1px $color_core_light_gray;
    }
    &:focus {
      box-shadow: 0 0 0 1px $color_core_light_gray;
      
      @include media($desktop) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;  
      }      
    }
  }
  span {
    vertical-align: middle;
    position: absolute;
    right: 0;
    height: 100%;
    border-radius: $border_radius;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background: $color_core_off_white;
    color: $color_core_gray;
    @include rem(width, 50px);
    @include rem(font-size, 30px);

    &:before {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -15px;
    }
  }

  input[type="submit"] {
    position: absolute;
    right: 0;
    background: transparent;
    width: 50px;
    border-radius: $border_radius;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    line-height: 40px;
    padding: 0;
    margin: 0;
    z-index: 20;
  }

  .popular {
    display: none;

    @include media($desktop) {
      display: block;
      transition: all $animation-speed $animation-easing;
      max-height: 0px;
      overflow: hidden;
      font-size: 14px;
      box-shadow: 0 0 0 1px transparent;
      background: $color_core_white;
      border-bottom-left-radius: $border_radius;
      border-bottom-right-radius: $border_radius;
      h3 {
        background: $color_core_gray;
        color: $color_core_white;
        padding: 10px 20px;
        margin: 0;
        font-size: 18px;
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        line-height: 30px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: inline-block;
          width: 100%;
          border-bottom: 1px solid $color_core_light_gray;
          margin: 0;
          a {
            display: inline-block;
            width: 100%;
            padding: 10px 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
  &.show-results .popular {
    max-height: 300px;
    box-shadow: 0 0 0 1px $color_core_light_gray;
    overflow-y: scroll;
  
    li:hover {
      background-color: $color_core_off_white;
    }
  }
  .ui-autocomplete {
    padding-top: 50px;
    padding-left: 30px;
  }
}