//
// Mixins
//
@mixin control() {
  display: block;
  position: absolute;
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  background-color: $color_core_gray;
}

@mixin control-open() {
  content: '+';
  //background-color: #31b131;
}

@mixin control-close() {
  content: '-';
  line-height: 21px;
  //background-color: #d33333;
}

//
// Table styles
//
table.dataTable {
  // Styling for the `inline` type
  &.dtr-inline.collapsed tbody {
    td:first-child,
    th:first-child {
      position: relative;
      padding-left: 40px;
      cursor: pointer;

      &:before {
        top: 50%;
        left: 8px;
        height: 24px;
        width: 24px;
        margin-top: -12px;
        @include control;
        @include control-open;
      }

      &.dataTables_empty:before {
        display: none;
      }
    }

    tr.parent {
      td:first-child:before,
      th:first-child:before {
        @include control-close;
      }
    }

    tr.child td:before {
      display: none;
    }
  }

  // Styling for the `column` type
  &.dtr-column tbody {
    td.control,
    th.control {
      position: relative;
      cursor: pointer;

      &:before {
        top: 50%;
        left: 50%;
        height: 16px;
        width: 16px;
        margin-top: -10px;
        margin-left: -10px;
        @include control;
        @include control-open;
      }
    }

    tr.parent {
      td.control:before,
      th.control:before {
        @include control-close;
      }
    }
  }

  // Child row styling
  tr.child {
    padding: 0.5em 1em;

    &:hover {
      background: transparent !important;
    }

    ul {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        border-bottom: 1px solid #efefef;
        padding: 0.5em 0;
        white-space: nowrap;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    span.dtr-title {
      display: inline-block;
      min-width: 75px;
      font-weight: bold;
    }

    span.dtr-data {
    }
  }
}
