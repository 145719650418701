// Paragraphs
//
// The p element is one of the most commonly used building blocks of HTML.
// 
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam egestas odio tortor, sed vehicula nunc lobortis rutrum. Cras ultrices luctus purus non malesuada. Morbi accumsan, justo ut venenatis aliquet, risus elit sollicitudin tellus, eu vestibulum velit odio a justo.</p>
// 
// Styleguide 3.1

p {
  @include rem(margin, 0 0 30px);
}

strong {
  font-weight: normal;
  font-family: $primaryFontMedium;
}

// Links
//
// The a element is usually referred to as a link.
// 
// Markup:
// <a href="#" title="link title">This is a default link</a>
// 
// Styleguide 3.2

a {
  color: $accent_link;
  transition: all $animation-speed $animation-easing;

  &:hover {
    color: saturate(lighten($accent_link, 5), 10);
    text-decoration: none;
  }
}

// Blockquote
//
// The blockquote element is a mechanism for marking up a block of text quoted from a person or another document or source.
// 
// Markup:
// <blockquote>
//   <p>Design is not just what it looks like and feels like. Design is how it works.</p>
//   <footer>
//     <cite>Steve Jobs</cite>
//   </footer>
// </blockquote>
// 
// Styleguide 3.3

blockquote {
  @include rem(padding, 48px);
  @include rem(margin, 24px 0);
  border-left: 4px solid $color_core_light_gray;
  p {
    margin: 0;
    color: $color_core_gray;
    font-family: $accentFont;
    font-style: italic;
    @include rem(font-size, 24px);
    line-height: 1.25;
  }
  cite {
    display: block;
    margin-top: 8px;
    font-style: normal;
  }
}

// Line Rule
// 
// The hr element creates in the document a highly visible break that renders as a slim horizontal line running the width of the area to which it’s applied.
// 
// Markup:
// <hr>
// 
// Styleguide 3.4

hr {
  height: 1px;
  background-color: $color_core_off_white;
  border: 0;
}

// Lists
// 
// Element that groups related content together. They're part of everyday life and come in multiple formats.
// 
// Styleguide 3.5

ul, ol, dl {
}

// Unordered List
//
// The ul element, the name for which is an abbreviation of unordered list, is used to group a collection of items together in a list, but in a way that doesn’t suggest an order of precedence or importance.
//
// Markup:
// <ul>
//   <li>Lorem ipsum dolor sit amet</li>
//   <li>Consectetur adipiscing elit</li>
//   <li>Integer molestie lorem at massa</li>
//   <li>Facilisis in pretium nisl aliquet</li>
//   <li>Nulla volutpat aliquam velit</li>
//   <li>Phasellus iaculis neque</li>
//   <li>Purus sodales ultricies</li>
//   <li>Vestibulum laoreet porttitor sem</li>
//   <li>Ac tristique libero volutpat at</li>
//   <li>Faucibus porta lacus fringilla vel</li>
//   <li>Aenean sit amet erat nunc</li>
//   <li>Eget porttitor lorem</li>
// </ul>
//
// Styleguide 3.5.1.

ul {
}

ul li {
  @include rem(margin-top, 16px);

  &:first-child {
    margin: 0;
  }
}

// Ordered List
//
// The ol element is similar to the ul element in that it’s used to group a collection of items together in a list.
//
// Markup:
// <ol>
//   <li>Lorem ipsum dolor sit amet</li>
//   <li>Consectetur adipiscing elit</li>
//   <li>Integer molestie lorem at massa</li>
//   <li>Facilisis in pretium nisl aliquet</li>
//   <li>Nulla volutpat aliquam velit</li>
//   <li>Faucibus porta lacus fringilla vel</li>
//   <li>Aenean sit amet erat nunc</li>
//   <li>Eget porttitor lorem</li>
// </ol>
//
// Styleguide 3.5.2

ol {
}

ol li {
  @include rem(margin-top, 16px);

  &:first-child {
    margin: 0;
  }
}

// Definition List
//
// If you want to list a series of items that essentially have a title and a description of some kind (that is, each item has two parts), use the definition list dl element.
//
// Markup:
// <dl>
//   <dt>Definition List</dt>
//   <dd>A number of connected items or names written or printed consecutively, typically one below the other.</dd>
//   <dt>This is a term.</dt>
//   <dd>This is the definition of that term, which both live in a dl.</dd>
//   <dt>Here is another term.</dt>
//   <dd>And it gets a definition too, which is this line.</dd>
//   <dt>Here is term that shares a definition with the term below.</dt>
//   <dd>And it gets a definition too, which is this line.</dd>
// </dl>
//
// Styleguide 3.5.3

dl {
}

dt {
  font-family: $primaryFontMedium;
}

dd {
  margin: 0 0 16px;
}

