form {}
legend {}

// Fieldsets / Wrappers
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
.field, .fieldpair, .fieldgroup {
  @include rem(margin, 0 0 16px);

  &.no-label {
    @include media($desktop){
      @include rem(margin-top, 25px);
    }
  }

  .field-note {
    font-weight: normal;
    @include rem(font-size, 15px);
    color: $color_accent_cool_gray;
  }
}

// labels and inputs
label, legend {
  display: block;
  padding-bottom: 3px;
  font-family: $primaryFontMedium;
  font-weight: normal !important;

  &.required::before {
    content: '*';
    color: $color_alert_red;
    font-size: 18px;
    margin-right: 1px;
  }
}

input {
  &[type="text"],
  &[type="search"],
  &[type="password"],
  &[type="email"],
  &[type="file"],
  &[type="number"],
  &[type="tel"],
  &[type="url"] {
    width: 100%;
    border: 0;
    border-radius: $border_radius;
    box-shadow: 0 0 0 1px $color_core_light_gray;
    font-family: $primaryFont;
    -webkit-appearance: none;
    transition: all $animation-speed $animation-easing;
    @include rem(padding, 15px);
    @include rem(font-size, 14px);

    &:hover {
      box-shadow: 0 0 0 1px darken($color_core_light_gray, 10%);
    }
    &:focus {
      box-shadow: 0 0 0 1px $color_alert_blue;
    }
  }
}

// datepickers
.input-range {
  > input {
    display: inline;
    width: 45%;
  }
}

// Invalid Field (default)
.error-message {
  display: none;
  position: relative;
  font-size: 14px;
  color: $color_alert_red;

  span {
    position: absolute;
    right: 10px;
    top: -43px;
    font-size: 24px;
  }
}

// Invalid Field (active)
.invalid {
  display: inline-block;

  input {
    padding: 14px;
    padding-right: 40px;
    background: lighten($color_alert_red, 50%);
    box-shadow: 0 0 0 2px $color_alert_red;

    &:hover, &:focus {
      box-shadow: 0 0 0 2px $color_alert_red;
    }
  }

  .error-message {
    display: block;
    margin-top: 5px;
  }
}

.append-icon {
  position: relative;

  .icon {
    position: absolute;
    top: 4px;
    left: 0;
    background: $color_core_blue;
    display: inline-block;
    height: 51px;
    width: 60px;
    color: white;
    vertical-align: middle;
    border-radius: 5px 0 0 5px;
    text-align: center;
    line-height: 50px;
  }
  input {
    @include rem(margin-left, 60px);
    width: calc(100% - 60px);
    border-radius: 0 3px 3px 0;
  }

  &.append-icon--right {
    .icon {
      top: -1px;
      right: 0;
      left: auto;
      border-radius: 0 5px 5px 0;
    }

    input {
      @include rem(margin-right, 60px);
      margin-left: 0;
      border-radius: 3px 0 0 3px;
    }
  }
}

// selects
.select--language {
  margin: 16px 0;
  .selectboxit-option-first {
    display: none;
  }
  .selectboxit-option-icon-container {
    color: $color_alert_blue;
  }
}

.select {
  + .no-label {
    @include media($tablet){
      @include rem(margin-top, 28px);
    }
  }
  &.inline {
    display: inline-block;
  }
  &.small {
    width: 250px;
  }
}

// radios
.radios {
  ul {
    padding-left: 0;

    @include media($phablet){
      padding-left: 40px;
    }
  }

  li {
    display: block;
    @include rem(margin, 0 0 16px);
  }
  li:first-child {
    @include rem(margin, 0 0 16px);
  }
  .radio, input[type="radio"] {
    position: absolute;
    left: -9999px;
  }
  .radio + label,
  input[type="radio"] + label {
    display: inline-block;
    position: relative;
    padding-left: 40px;
    line-height: 24px;
    cursor: pointer;
    @extend .icon-radio-button-off;
    &:before {
      font-family: 'icomoon';
      font-size: 24px;
      position: absolute;
      display: block;
      color: $color_core_gray;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      transition: all $animation-speed $animation-easing;
    }
    &:hover {
      &:before {
        box-shadow: 0 0 0 5px rgba($color_alert_blue, .2);
        color: $color_alert_blue;
      }
    }
  }
  input[type="radio"]:checked + label {
    @extend .icon-radio-button-on;
    &:before {
      color: $color_alert_blue;
    }

  }

  &.ghost {
    .radio + label,
    input[type="radio"] + label {
      &:before {
        color: $color_core_white;
      }
    }
  }

  label + input[type="text"] {
    @include rem(margin-top, 5px);
    width: 85%;
    @include rem(margin-left, 35px);

    @include media($tablet){
      @include rem(margin-left, 40px);
      width: 96.5%;
    }
  }
}

// checkboxes
.checkboxes {
  ul {
    padding-left: 0;

    @include media($phablet){
      padding-left: 40px;
    }
  }

  li {
    display: block;
    @include rem(margin, 0 0 16px);
  }
  li:first-child {
    @include rem(margin, 0 0 16px);
  }

  .checkbox, input[type="checkbox"] {
    position: absolute;
    left: -9999px;
  }

  .checkbox + label,
  input[type="checkbox"] + label {
    display: inline-block;
    position: relative;
    padding-left: 40px;
    line-height: 24px;
    cursor: pointer;
    &:after {
      position: absolute;
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      line-height: 24px;
      border: 2px solid $color_core_gray;
      top: 0;
      left: 0;
      border-radius: $border_radius;
      transition: all $animation-speed $animation-easing;
    }
    &:hover {
      &:after {
        border: 2px solid $color_alert_blue;
        box-shadow: 0 0 0 5px rgba($color_alert_blue, .2);
      }
    }
  }

  input[type="checkbox"]:checked + label {
    @extend .icon-check;

    &:before {
      font-family: 'icomoon';
      font-size: 24px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      color: $color_core_white;
    }
    &:after {
      background: $color_alert_blue;
      border: 2px solid $color_alert_blue;
    }
  }

  label + input[type="text"], label + .append-icon {
    @include rem(margin-top, 5px);
    width: 85%;
    @include rem(margin-left, 35px);

    @include media($tablet){
      @include rem(margin-left, 40px);
      width: 96.5%;
    }
  }
}

// textareas
textarea {
  width: 100%;
  border: 0;
  border-radius: $border_radius;
  box-shadow: 0 0 0 1px $color_core_light_gray;
  resize: none;
  transition: all .15s ease-in-out;
  @include rem(padding, 15px);
  @include rem(margin, 0 0 16px);
  @include rem(font-size, 14px);

  &:hover {
    box-shadow: 0 0 0 1px darken($color_core_light_gray, 10%);
  }
  &:focus {
    box-shadow: 0 0 0 1px $color_alert_blue;
  }
}

// autocomplete
.ui-autocomplete-input {
  &:focus {
    border-radius: 4px 4px 0 0;
  }
}

.ui-autocomplete {
  background: $color_core_white;
  &.ui-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 87% !important;
    @include rem(max-width, 1140px);
    border-bottom: 1px solid $color_alert_blue;
  }
  .ui-menu-item {
    margin: 0 0 0 -1px;
    &:first-child {
      a {
        margin-top: -1px;
      }
    }
    a {
      display: block;
      @include rem(padding, 12px 10px);
      border-right: 1px solid $color_alert_blue;
      border-left: 1px solid $color_alert_blue;
    }
    &:hover {
      background-color: $color_alert_blue;
      a {
        color: $color_core_white;
      }
    }
  }
}

// form nav
.form-controls {
  margin: 3em 0 1em 0;

  > div {
    @include span-columns(4);
    @include omega(3n);

    text-align: center;
    &:first-child  { text-align: left;  }
    &:last-child   { text-align: right; }
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}