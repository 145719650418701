//==================================================================
// Alerts Partial
// Styles that help communicate success, warnings, failures, etc.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Notifications
//
// Notifications provide lightweight feedback about an operation. They show a brief message at the top of the screen. Notifications can contain an action.
// 
// Markup:
// <div id="alerts" class="wrapper group alerts"> 
//   <!-- Repeatable if necessary -->
//   <div class="alert-box {$modifiers}">
//     <span class="alert-icon" aria-hidden="true"></span>
//     <div class="message">
//       <span class="alert-type">Alert Type</span>
//       We use cookies to ensure you get the best experience 
//     </div>
//   </div>
//   <!-- end Repeatable -->
// </div>
// 
// .info	              - Info alert
// .success             - Success alert
// .warning             - Warning alert
// .breaking            - Breaking news
//
// Styleguide 4.1

.alerts {
  padding: 0;
  @include rem(margin, 0 0 30px);

  @include media($desktop) {
    @include rem(margin, 30px auto 0);
  }

  &.is-hidden {
    display: none !important;
  }

  .owl-pagination {
    text-align: center;

    @include media($desktop) {
      position: absolute;
      right: 0;
      z-index: 1000;
      @include rem(bottom, -24px);
    }

    .owl-page {
      display: inline-block;
      vertical-align: middle;
      @include rem(margin, 0 4px);
    }
    .owl-page span {
      display: block;
      background-color: $color_core_off_white;
      border-radius: 50%;
      @include rem(width, 8px);
      @include rem(height, 8px);
    }
    .owl-page.active span {
      background-color: $color_core_gray;
    }

  }
  .owl-buttons {
    display: none;

    @include media($desktop) {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      height: 100%;
      border-left: 1px solid rgba($color_core_white, .25);
      color: $color_core_white;
      font-size: 0;
      line-height: 0;
      opacity: 1;
      transition: opacity $animation-speed $animation-easing;
    }

    &.fadeIn {
      @include media($desktop) {
        animation: fadeIn $animation_speed*3 $animation_easing;
      }
    }

    div {
      @include media($desktop) {
        position: relative;
        display: inline-block;
        height: 100%;
        border-left: 1px solid rgba($color_core_white, .25);
        @include rem(padding, 0 30px);
        @include rem(font-size, 16px);
      }
    }
    div:first-child {
      @include media($desktop) {
        border: 0;
      }
    }

    div i {
      @include media($desktop) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }
}

.alert-box {
  color: $color_core_white;
  width: 100%;
  background: $color_core_blue;
  overflow: hidden;
  transition: all $animation-speed $animation-easing;
  @include rem(margin, 20px 0 30px);
  @include rem(padding-top, 20px);

  @include media($desktop) {
    @include rem(border-radius, $border_radius);
  }

  &.is-hidden {
    display: none !important;
  }
  //.wrapper {
    vertical-align: middle;
    position: relative;

    .alert-icon {
      position: absolute;
      display: block;
      height: 100%;
      width: 50px;
      background: rgba($color_core_black, .25);
      top: 0;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -15px;
        margin-left: -15px;
        font-size: 30px;
      }
      &:after {
        position: absolute;
        content: '';
        display: block;
        border: 10px solid transparent;
        border-left: 10px solid rgba($color_core_black, .25);
        width: 10px;
        height: 10px;
        top: 50%;
        margin-top: -10px;
        left: 50px;
      }
    }
    .message {
      padding: 20px;
      padding-left: 70px;
      padding-right: 40px;
      width: 100%;
      // commented out overflow, causes a scrollbar on windows browsers.
      //overflow: auto;
      
      @include media($desktop) {
        padding-right: 140px;
      }
    }
    .alert-type {
      text-transform: uppercase;
      font-family: $primaryFontMedium;
      position: relative;
      padding-right: 10px;
      
      &:before {
        position: absolute;
        content: "\00b7";
        display: block;
        right: 0;
      }
    }
  //}

  a {
    color: $color_core_white;

    &.alert-dismiss {
      position: absolute;
      top: 0;
      height: 100%;
      @include rem(right, 20px);
      @include rem(padding-left, 40px);
      
      @include media($desktop) {
        top: 50%;
        height: auto;
        @include rem(margin-top, -12px);
      }

      span {
        
        &:first-child {
          font-size: 33px;
          position: absolute;
          left: 0;
          top: 50%;
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin-top: -15px;
          text-align: center;
          border-radius: 50%;
          transition: all $animation-speed $animation-easing;
        
        }
        &:last-child {
          display: none;
          
          @include media($desktop) {
            display: inline-block;
            text-decoration: underline;
          }
        }
      }

    }
    &.alert-dismiss:hover span:first-child {
      color: rgba(#fff, .8);
    }
    &.alert-dismiss:hover span:last-child {
      text-decoration: none;
    }

  }

  // Info Alert
  &.info {
    background: $color_alert_blue;  
    .alert-icon {
      @extend [class^="icon-"];
      @extend .icon-info-outline;
    }

  }

  // Success Alert
  &.success {
    background: $color_alert_green;  
    .alert-icon {
      @extend [class^="icon-"];
      @extend .icon-check;
    }
  }

  // Warning Alert
  &.warning, &.error {
    background: $color_alert_orange;  
    .alert-icon {
      @extend [class^="icon-"];
      @extend .icon-warning;
    }
  }  

  // Breaking Alert
  &.breaking {
    background: $color_alert_red;  
    .alert-icon {
      @extend [class^="icon-"];
      @extend .icon-error;
    }
  }

  // Outdated Browser
  &.outdated-browser {
    text-align: center;

    h3 {
      margin-bottom: 0;
      color: $color_core_white;
    }
  }

}

// Multiple Notifications
//
// Combines multiple notifications into a single, interactive carousel. 
// 
// Markup: 
// <div id="alerts" class="wrapper group alerts">
//   <div class="alert-box">
//     <span class="alert-icon" aria-hidden="true"></span>
//     <div class="message">
//       <span class="alert-type">Alert Type</span>
//       We use cookies to ensure you get the best experience 
//     </div>
//   </div>
//   <div class="alert-box info">
//     <span class="alert-icon" aria-hidden="true"></span>
//     <div class="message">
//       <span class="alert-type">Alert Type</span>
//       We use cookies to ensure you get the best experience 
//     </div>
//   </div>
//   <div class="alert-box success">
//     <span class="alert-icon" aria-hidden="true"></span>
//     <div class="message">
//       <span class="alert-type">Alert Type</span>
//       We use cookies to ensure you get the best experience 
//     </div>
//   </div>
//   <div class="alert-box warning">
//     <span class="alert-icon" aria-hidden="true"></span>
//     <div class="message">
//       <span class="alert-type">Alert Type</span>
//       We use cookies to ensure you get the best experience 
//     </div>
//   </div>
//   <div class="alert-box breaking">
//     <span class="alert-icon" aria-hidden="true"></span>
//     <div class="message">
//       <span class="alert-type">Alert Type</span>
//       We use cookies to ensure you get the best experience 
//     </div>
//   </div>
// </div>
//
// Styleguide 4.1.1


// Notifications Widget
//
// Displayed in the header to toggle alerts 
// 
// Markup: 
// <div class="alert-widget">
//   <button>
//     <span class="icon-notifications" aria-hidden="true"></span>
//     <div class="alert-count">2</div>
//   </button>
// </div>
//
// Styleguide 4.1.2

.alert-widget {

  @include media($tablet) {
    min-width: 80px;
  }
  display: inline-block;
  // background: $color_core_blue;
  padding:15px 5px;
  vertical-align: middle;
  text-align: center;
  height: 60px;

  button {
    background:none;
  }
  span {
    color:$color_core_white;
    vertical-align: middle;
    display: inline-block;
    font-size: 24px;
  }
  .alert-count {
    display: none;
    @include media($tablet) {
      display: inline-block;
    }
    position: relative;
    vertical-align: middle;
    text-align: center;


    //border-radius: 50%;
    padding:0;
    height: 25px;
    width: 25px;
    font-family: $primaryFontMedium;
    line-height: 25px;
    //background: $color_alert_yellow;
    color: $color_core_white;
    transition: all $animation-speed $animation-easing;
  }
}
