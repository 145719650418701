//==================================================================
// Animations Partial
// Custom keyframed animations to be used in the site/application
// (c) Atlantic BT. All Rights Reserved.
//==================================================================
@keyframes slideIn {
  from {
    transform: translate3d(-5%, -50%, 0);
  }
  to {
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes slideUp {
  from {
    transform: translate3d(-50%, -40%, 0);
  }
  to {
    transform: translate3d(-50%, -50%, 0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}