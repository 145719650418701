// Progress
//
// Display status of a determinate or indeterminate process.
// 
// Markup: 
// <div class="progress {$modifiers}" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuetext="50%" aria-valuemax="100">
//   <span class="progress-max">
//     <span class="progress-now" style="width: 50%;"></span>
//   </span>
//   <span class="progress-text">50%</span>
// </div> 
// 
// .progress-demo           - Change value to 37 with $('.progress-demo').setProgress(37);
// .info                    - Info progress
// .error                   - Error progress
// .success                 - Success progress
// .alert                   - Alert progress
//
// Styleguide 4.6

.progress {
  position: relative;
  padding-right: 50px;
  
  .progress-max {
    width: 100%;
    background: $color_core_off_white;
    float: left;
    height: 20px;
    border-radius: $border_radius;
  }
  .progress-now {
    display: inline-block;
    background: $color_core_blue;
    height: 20px;
    border-bottom-left-radius: $border_radius;
    border-top-left-radius: $border_radius;
    transition: width 1.15s linear;
  }
  .progress-text {
    position: absolute;
    width: 50px;
    text-align: right;
  }
  &.info {
    .progress-now {
      background: $color_alert_blue;
    }
  }
  &.error {
    .progress-now {
      background: $color_alert_orange;
    }
  }
  &.success {
    .progress-now {
      background: $color_alert_green;
    }
  }
  &.alert {
    .progress-now {
      background: $color_alert_red;
    }
  }

}