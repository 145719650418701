// Logos
//
// Agencies have specified logos for their website. They will all display the NC iconmark along with their agency name. These are to remain consistent across all agencies as they migrate to the new theme.
// 
// Styleguide 1.1

// Enterprise
//
// <div style="padding: 40px; background-color: #092940;">
//   <div><img src="img/l_ncgov-stacked.png" style="height: 100px; border-radius: 0;" /></div>
// </div> 
// <br>
// <div style="padding: 40px; background-color: #092940;">
//   <div><img src="img/l_ncgov-wordmark.svg" style="height: 40px; border-radius: 0;" /></div>
// </div> 
//
// Styleguide 1.1.1

// Agencies
// 
// <div><small style="color: #999;">Example agency logo:</small></div>
// <div style="padding: 40px; background-color: #092940;">
//   <div><img src="img/l_agency-ncdcr.svg" style="height: 40px; border-radius: 0;" /></div>
// </div> 
// 
// Styleguide 1.1.2


.logo {
  text-decoration: none;
  color: $color_core_blue;
  display: inline-block;
  margin-right: 20px;

  img {
    display: inline-block;
    vertical-align: middle;
    border-radius: 0;
  }
  em {
    display: inline-block;
    font-style: normal;
    border-left: 1px solid $color_core_light_gray;
    padding: 20px;
    max-width: 230px;
    font-size: 18px;
    vertical-align: middle;
  }
}