//==================================================================
// Buttons Partial
// Styles for various traditional actions.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Call To Actions
//
// A majority of buttons in the site are built from the same base class.
// 
// Styleguide 3.6

// Buttons
// 
// Markup: 
// <a href="#" class="button {$modifiers}">Link Button</a>
// <button class="button {$modifiers}">Button Element</button>
// <input type="submit" class="button {$modifiers}" value="input[type='submit']"/>
// 
// .accent          - Solid style / accent color
// .ghost				    - Ghost style / core color
// .ghost.accent		- Ghost style / accent color
// .ghost.inverted	- Ghost style / inverted - white border
// .video           - Video icon, left side
// .back            - Back style / arrow is on left pointing left
//
// Styleguide 3.6.1

input[type=button].button,
input[type=submit].button,
button.button,
a.button,
button[type=submit] {
  border-radius: $border_radius;
  display: inline-block;
  text-align: left;
  padding: 10px 15px;
  line-height: 1.5;
  background-color: $color_core_blue;
  border: 2px solid $color_core_blue;
  color: $color_core_white;
  text-decoration: none;
  vertical-align: top;
  position: relative;
  transition: all $animation-speed $animation-easing;

  &.small {
    padding: 3px 10px;
  }

  &.left-arrow {
    @include rem(padding-left, 45px);
    &:before {
      @extend .icon-arrow-back;
      font-family: 'icomoon';
      @include rem(font-size, 24px);
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -17px;
    }
  }

  &.right-arrow {
    @include rem(padding-right, 45px);
    &:before {
      @extend .icon-arrow-forward;
      font-family: 'icomoon';
      @include rem(font-size, 24px);
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -17px;
    }
  }

  // Labels
  span {
    display: inline-block;
    vertical-align: middle;
  }

  // Icon sizes
  [class^="icon-"], [class*=" icon-"] {
    @include rem(font-size, 24px);
  }

  // Animate arrow icon
  span.icon-arrow-forward {
    @include rem(margin-left, 15px);
    transition: all 0.15s ease-in-out;
  }

  // Hover
  &:hover {
    box-shadow: 0 0 0 5px rgba($color_core_blue, .2);
  }

  // Accent format
  &.accent {
    background-color: $accent_link;
    border-color: $accent_link;

    &:hover {
      box-shadow: 0 0 0 5px rgba($accent_link, .2);
    }
  }

  // Ghost format
  &.ghost {
    color: $color_core_blue;
    background: none;
    border: 2px solid $color_core_blue; 
  
    &:hover {
      box-shadow: 0 0 0 5px rgba($color_core_blue, .2);
    }
  }

  // Ghost Accent format
  &.ghost.accent {
    color: $accent_link;
    border: 2px solid $accent_link;

    &:hover {
      box-shadow: 0 0 0 6px rgba($accent_link, .2);
    }
  }

  // Ghost Inverted format
  &.ghost.inverted {
    color: white;
    border: 2px solid white;
    background: transparent;

    &:hover {
      box-shadow: 0 0 0 5px rgba(#fff, .2);
    }
  }

  // Video button
  &.video {
    @include rem(padding, 10px 15px 10px 45px);

    &:before {
      @extend .icon-play-circle-outline;
      left: 10px;
      right: auto;
    }
  }

  // DISABLED state
  &:disabled {
    opacity: .25;
    cursor: not-allowed;
  
    &:hover {
      box-shadow: none;
    }
  }

  // remove unwanted ext icons
  &.ext .ext {
    display: none !important;
  }

  &.align-right {
    float: right;
    @include rem(margin, 16px 0 16px 16px);
  }

  &.align-left {
    float: left;
    @include rem(margin, 16px 16px 16px 0);
  }
  
}

// Spacing for arrow icons that are not used for input[type=submit]
button.button, a.button, button[type=submit] {
  &.back {
    padding-right: 15px;
    padding-left: 45px;
  }

  .button-group & {
    padding-right: 15px;
    padding-left: 15px;
    &:before {
      display: none;
    }
  }
}
// input[type=submit].button {
//   padding: 11px 20px; //offset to match button, a tags
// }
input[type=submit].button.video {
  @include rem(padding-left, 15px);
}

// Button Groups
// 
// Markup:
// <div class="button-group">
//   <a href="#" class="button">Link Button</a>
//   <a href="#" class="button ghost">Link Button</a>
// </div>
// 
// Styleguide 3.6.2

.button-group {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
  @include rem(margin, 0 0 30px);

  input[type=button].button,
  input[type=submit].button,
  button.button,
  a.button {
    line-height: 1.5;
    border-radius: 0;
    @include rem(font-size, 16px);
  }

  input[type=button].button:first-child,
  input[type=submit].button:first-child,
  button.button:first-child,
  a.button:first-child {
    border-top-left-radius: $border_radius;
    border-bottom-left-radius: $border_radius;
  }

  input[type=button].button:last-child,
  input[type=submit].button:last-child,
  button.button:last-child,
  a.button:last-child {
    border-top-right-radius: $border_radius;
    border-bottom-right-radius: $border_radius;
  }

}

// jQuery UI overrides
.editable-buttons button.ui-button-icon-only {
  width: auto !important;
  height: auto !important;
  span {
    text-indent: 0;
  }
  &:before {
    display: none;
  }
}

.ui-tooltip {
  button {
    padding: 2px 8px 4px !important;
    border-radius: $border_radius;

    &.editable-cancel {
      color: $accent_link;
      border: 2px solid $accent_link;
      background: $color_core_white;

      &:hover {
        box-shadow: 0 0 0 4px rgba($accent_link, .2);
      }
    }
  }

  .ui-button-icon-only .ui-button-text, .ui-button-icons-only .ui-button-text {
    padding: 0;
  }

  .editable-buttons {
    display: block;
    text-align: right;
    @include rem(margin, 15px 0 0 0);
  }
}
