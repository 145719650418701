// Link Blocks
// 
// Various styles of a group of links.
// 
// Styleguide 4.13


// Section Navigation
// 
// Third level navigation lists to be placed in a vertical space within pages.
// 
// Markup:
// <nav class="section-nav">
//   <h3 class="nav-title">Browse Section</h3>
//   <ul>
//     <li><a href="#">Art</a></li>
//     <li><a href="#">History</a></li>
//     <li><a href="#">Libraries</a></li>
//     <li><a href="#">Leadership</a></li>
//     <li class="current-page-ancestor">
//       <a href="#">Special Programs</a>
//       <ul>
//         <li><a href="#">Awards Gala</a></li>
//         <li class="current-page"><a href="#">Poet Laureate</a></li>
//         <li><a href="#">Queen Anne's Revenge</a></li>
//         <li><a href="#">Art that Moves You</a></li>    
//       </ul>
//     </li>
//     <li><a href="#">Blog</a></li>
//     <li><a href="#">Shop</a></li>  
//   </ul>
// </nav>
// 
// Styleguide 4.13.1

.section-nav {
  position: relative;
  background-color: $color_core_blue;
  border-radius: $border_radius;
  color: $color_core_white;
  @include rem(padding, 30px);
  @include rem(margin-bottom, 30px);

  // Title
  .nav-title {
    @extend .section-title;
    color: $color_core_white;
    margin-top: 0;

    &:after {
      background-color: $color_core_white;
    }
  }

  // Menu Items
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    
    li {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid lighten($color_core_blue, 5%);
      margin: 0;

      &:first-child {
        border-top: 1px solid lighten($color_core_blue, 5%);
      }
      a {
        color: $color_core_white;
        display: block;
        padding: 15px 0;
        vertical-align: middle;
        font-family: $primaryFontMedium;
        transition: all $animation-speed $animation-easing;

        @include media($desktop) {
          // display: inline-block;
        }
      }
    }
    li.current-page,
    li.current-page-ancestor {
      > a {
        width: 100%;
        position: relative;        
        text-decoration: none;
        color: $color_core_off_white;
        font-family: $primaryFontHeavy;
        @include rem(padding, 15px 30px);
        @include rem(margin, 0 -30px);
        &:before {
          position: absolute;
          content: '';
          display: block;
          left: 0;
          top: 0;
          right: -60px;
          height: 100%;
          background: rgba($color_core_off_white, .25);
        }
      }

      li.current-page > a,
      li.current-page-ancestor > a {
        color: $color_core_white;
        font-family: $primaryFontHeavy;
        &:before {
          background: none;
        }
      }
    }
  }
  ul ul {
    margin: 0;
    //background-color: lighten($color_core_blue, 10%);
    @include rem(padding, 0 30px);
    @include rem(margin, 0 -30px);

    li {
      border-color: $color_core_blue;
      @include rem(padding-left, 20px);
      a {
        font-family: $primaryFont;
      }
    }
  }
}

// Quick Links
// 
// Displays a list of links
// 
// Markup:
// <section class="quick-links {$modifiers}">
//   <h3 class="section-title">Quick Links</h3>
//   <ul>
//     <li>
//       <a href="#">
//        Link to content 1
//       </a>
//     </li>
//     <li>
//       <a href="#">
//         Link to content 2
//       </a>
//     </li>
//     <li>
//       <a href="#">
//         Link to content 3
//       </a>
//     </li>
//     <li>
//       <a href="#">
//         Link to content 4
//       </a>
//     </li>
//   </ul>
// </section>
// 
// .primary.inverted  - Primary bg  w/ inverted foreground 
// .accent.inverted   - Accent bg w/ inverted foreground 
//
// Styleguide 4.13.2

.quick-links {
  border-radius: $border_radius;
  .section-title {
    margin-top: 0;
    @include rem(font-size, 24px);
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  
    li {}
    li a {
      display: block;
    }
  }
  ul + a {
    @include rem(margin-top, 30px);
  }

  &.inverted {
    color: $color_core_white;
    .section-title {
      color: $color_core_white;
    }
    .section-title:after {
      background-color: $color_core_white;
    }
    a {
      color: $color_core_white;
    }
  }
  &.primary {
    @include rem(padding, 30px);
    background: $color_core_blue;
  }
  &.accent {
    @include rem(padding, 30px);
    background: $accent_link_block;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .sidebar &,
  .secondary-content & {
    @include rem(margin-bottom, 30px);
    
    ul {
      @include rem(margin-bottom, 30px);
    }
  }
}


// Quick Links - Dates
// 
// Displays a list of dates
// 
// Markup:
// <section class="quick-links {$modifiers}">
//   <h3 class="section-title">Quick Dates</h3>
//   <ul>
//     <li itemscope itemtype="http://schema.org/Thing">
//       <a href="#" itemprop="url" >
//        Link to content 1
//       </a>
//       <time datetime="2015-01-05T08:00Z">May 25</time>
//     </li>
//     <li itemscope itemtype="http://schema.org/Thing">
//       <a href="#" itemprop="url" >
//         Link to content 2
//       </a>
//       <time datetime="2015-01-05T08:00Z">May 30</time>
//     </li>
//     <li itemscope itemtype="http://schema.org/Thing">
//       <a href="#" itemprop="url" >
//         Link to content 3
//       </a>
//       <time datetime="2015-01-05T08:00Z">Jun 1</time>
//     </li>
//     <li itemscope itemtype="http://schema.org/Thing">
//       <a href="#" itemprop="url" >
//         Link to content 4
//       </a>
//       <time datetime="2015-01-05T08:00Z">Jul 1</time>
//     </li>
//   </ul>
// </section>
// 
// .primary.inverted  - Primary bg  w/ inverted foreground 
// .accent.inverted   - Accent bg w/ inverted foreground 
//
// Styleguide 4.13.2.1

.quick-links { 
  time {
    color: $color_core_gray;
    font-family: $primaryFontMedium;
    text-transform: uppercase;
    @include rem(font-size, 14px);
  }
  &.inverted time {
    color: rgba($color_core_white, .75);
  }
}


// Link lists
// 
// Displays a list of links
// 
// Markup:
// <section class="link-lists {$modifiers}">
//   <h3 class="link-lists-title">Link list</h3>
//   <ul>
//     <li>
//       <a href="#">
//        Link to content 1
//       </a>
//     </li>
//     <li>
//       <a href="#">
//         Link to content 2
//       </a>
//     </li>
//   </ul>
// </section>
// 
// .primary.inverted  - Primary bg  w/ inverted foreground 
// .accent.inverted   - Accent bg w/ inverted foreground    
//
// Styleguide 4.13.3

.link-lists {
  display: inline-block;
  width: 100%;
  height: 100%;
  .link-lists-title {
    color: $color_core_blue;
    font-family: $primaryFontMedium;
    @include rem(font-size, 18px);
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
    a {
      //font-family: $primaryFontMedium;
      position: relative;
    }
  }
  // border-bottom: 1px solid $color_core_light_gray;
}

// Social Links
// 
// Displays a list of social links. Links automatically adjust contrast based on the containing band's theme.
// 
// Markup:
// <section class="social-links {$modifiers}">
//   <h3 class="section-title">Follow Us</h3>
//   <ul>
//     <li>
//       <a href="http://www.facebook.com/example">
//         <span class="icon-facebook" aria-hidden="true"></span> <span>Facebook</span>
//       </a>
//     </li>
//     <li>
//       <a href="http://www.twitter.com/example">
//         <span class="icon-twitter" aria-hidden="true"></span> <span>Twitter</span>
//       </a>
//     </li>
//     <li>
//       <a href="http://www.youtube.com/example">
//         <span class="icon-youtube" aria-hidden="true"></span> <span>YouTube</span>
//       </a>
//     </li>
//     <li>
//       <a href="http://www.example.com/feed">
//         <span class="icon-feed" aria-hidden="true"></span> <span>RSS Feed</span>
//       </a>
//     </li>
//   </ul>
// </section>
// 
// Styleguide 4.13.4

.social-links {
  p { @include rem(margin-bottom, 10px); }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    
    li {
      display: block;
      @include rem(margin, 0 0 10px);

      @include media($phablet) {
        display: inline-block;
        width: 50%;
      }
      @include media($tablet) {
        width: auto;
      }
      
      a {
        color: $color_core_blue;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        
        &:hover span:first-child {
          box-shadow: 0 0 0 5px rgba($color_core_blue, 0.2);
        }
        &:hover span:last-child {
          border-bottom: 1px solid transparent;
        }

        span {
          transition: all $animation-speed $animation-easing;
          display: inline-block;
          vertical-align: middle;
          
          &:first-child {
            font-size: 24px;
            border-radius: 50%;
            border: 2px solid $color_core_blue;
            padding: 10px;
            @include rem(padding, 10px);
          }
          &:last-child {
            border-bottom: 1px solid rgba($color_core_blue, 0.5);
            line-height: 1;
            @include rem(margin-left, 5px);
            @include rem(margin-right, 20px);
          }
        }
      }
    }
  }
  .sidebar &,
  .secondary-content & {
    .section-title {
      margin-top: 0;
      @include rem(font-size, 24px);
    }
  }
}

// Multi-column social links if inside a band
.band .social-links li {
  @include media($phablet) {
    width: 50%;
  }
}

// Invert social links if inside a dark band
.band.theme-core-black,
.band.theme-core-blue,
.band.theme-core-gray,
.band.theme-core-light-gray,
.band.theme-accent-light-blue,
.band.theme-accent-blue,
.band.theme-accent-indigo,
.band.theme-accent-deep-purple,
.band.theme-accent-purple,
.band.theme-accent-fuchsia,
.band.theme-accent-pink,
.band.theme-accent-red,
.band.theme-accent-orange,
.band.theme-accent-olive,
.band.theme-accent-forest,
.band.theme-accent-green {

  .social-links {
    .section-title {
      color: $color_core_white;
    }
    .section-title:after {
      background-color: $color_core_white;
    }
    ul {
      li {
        a {
          color: $color_core_white;
          span:first-child {
            border: 2px solid $color_core_white;
          }
          span:last-child {
            border-bottom: 1px solid rgba($color_core_white, 0.5);
          }
          &:hover span:first-child {
            box-shadow: 0 0 0 5px rgba($color_core_white, 0.2);
          }
          &:hover span:last-child {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }

}