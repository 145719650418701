// data base search page styles
.p-database-search {
  .selectboxit-container {
    width: 100%;
  }

  .selectboxit-container .selectboxit {
    width: 100%;
  }

  .field {
    @include rem(margin, 0 0 10px);

    &:last-of-type {
      @include rem(margin-bottom, 20px);
    }
  }

  label {
    @include rem(font-size, 14px);
  }

  input {
    &[type="text"],
    &[type="search"],
    &[type="password"],
    &[type="email"],
    &[type="file"],
    &[type="number"],
    &[type="tel"] {
      @include rem(padding, 5px 10px);
      @include rem(font-size, 14px);
    }
  }

  /* Drop Down down arrow container */
  .selectboxit-arrow-container {
    height: 29px !important;
    line-height: 29px !important;
  }

  /* Button Text */
  .selectboxit-text {
    height: 29px !important;
    line-height: 29px !important;
  }

  .selectboxit .selectboxit-option-icon-container {
    height: 29px !important;
    line-height: 29px !important;
  }

  .ui-datepicker select.ui-datepicker-month,
  .ui-datepicker select.ui-datepicker-year {
    display: inline-block;
  }
}