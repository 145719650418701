//==================================================================
// Global Settings
// Central location for project-level variables.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Breakpoints
$phone_px: 320px;
$phablet_px: 480px;
$tablet_px: 768px;
$desktop_px: 1024px;
$desktop_wide_px: 1280px;

// Media Queries
$phone: new-breakpoint(min-width $phone_px 2);
$phablet: new-breakpoint(min-width $phablet_px 2);
$tablet: new-breakpoint(min-width $tablet_px 6);
$desktop: new-breakpoint(min-width $desktop_px 12);
$desktop_wide: new-breakpoint(min-width $desktop_wide_px 12);

// Columns
$column: golden-ratio(1em, 3) !default;
$gutter: golden-ratio(1em, 3) !default;
$fg-column: $column;
$fg-gutter: $gutter;

// Animations
$animation_speed: .15s !default;
$animation_easing: ease-in-out !default;

// Border Radius
$border_radius: 4px !default;

// Fonts
$primaryFont: 'TransportNewLight', Helvetica, Arial, sans-serif;
$primaryFontMedium: 'TransportNewMedium', Helvetica, Arial, sans-serif;
$primaryFontHeavy: 'TransportNewHeavy', Helvetica, Arial, sans-serif;
$accentFont: 'Georgia', serif;

////////////////////////////
// Color Settings (Defaults)
$accent_link: $color_accent_blue !default;
$accent_link_block: $color_accent_blue !default;
$accent_page_title: $color_accent_blue !default;
$accent_banner_slider: $color_accent_blue !default;
$accent_email_signup_color: $color_accent_blue !default;
$accent_email_signup_bg: url('../img/bg_poly-accent-blue.jpg') !default;
$accent_stat_card_value: $color_accent_blue !default;
$service_card_color: $color_core_blue !default;
$service_card_poly: url('../img/bg_poly-core-blue.jpg') !default;
$block_icon_color: $color_core_gray !default;
$block_icon_poly: url('../img/bg_poly-core-gray.jpg') !default;
////////////////////////////

///////////////////////////
// Menu Settings (Defaults)
$menu-bg-color: $color_core_blue !default;
$menu-bg-poly: url('../img/bg_poly-core-blue.jpg') !default;
///////////////////////////

///////////////////////////
// Logo Settings (Defaults)
$agency_logo: url('../img/l_agency-ncgov.svg') !default;
$agency_logo_png: url('../img/l_agency-ncgov.png') !default;
///////////////////////////