//==================================================================
// Global Partial
// Styles for global layout.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

// Global Layout
// =================================================================

// Grid system
// 
// A robust grid system will allow us to customize the layout composition
// for components in various ways on different device screen sizes. 
// Columns will be percentage-based and we’ll work with consistent 
// margins and variable gutters.
// 
// Styleguide 2.1

// Smartphone
// 
// <div class="grid smartphone"><div>%</div><div>%</div></div>
// 
// Styleguide 2.1.1

// Tablet
// 
// <div class="grid tablet"><div>%</div><div>%</div><div>%</div><div>%</div><div>%</div><div>%</div></div>
// 
// Styleguide 2.1.2

// Desktop
// 
// <div class="grid desktop">
//   <div>%</div><div>%</div><div>%</div><div>%</div><div>%</div><div>%</div>
//   <div>%</div><div>%</div><div>%</div><div>%</div><div>%</div><div>%</div>
// </div>
// 
// Styleguide 2.1.3

.header-container,
.content-container,
.footer-container {
  min-width: 1280px;
}

main.content-container {
  background-color: $color_core_white;
}

.content-container {
  @include rem(margin-top, 60px);

  @include media($desktop) {
    margin-top: 0;
  }
}

.wrapper {
  @include outer-container;
  @include rem(padding, 0 20px);
  @include rem(max-width, 1280px);
}

// Page
//
// <ul>
//   <li><a href="templates/content-1-column.html">1-column</a></li>
//   <li><a href="templates/content-2-column.html">2-column</a></li>
//   <li><a href="templates/content-3-column.html">3-column</a></li>
// </ul>
//
// Styleguide 2.7.6

.article-sidebar {
  .article {
      @include span-columns(8.5 of 12);
  }
  .sidebar {
    @include span-columns(3.5 of 12);
  }
}

.article-2-sidebars {
  @include media($desktop) {
    @include display(flex);
  }

  .article {
    @include media($tablet) {
      @include span-columns(3 of 6);
    }
    @include media($desktop) {
      float: none;
      width: percentage(552/1140);
      margin: 0 percentage(36/1140);
      @include order(2);
    }
  }
  .sidebar {
    @include media($tablet) {
      @include span-columns(3 of 6);
      margin-right: 0;
    }
    @include media($desktop) {
      float: none;
      width: percentage(258/1140);
      @include order(3);
    }
  }
  .sidebar + .sidebar {
    @include media($tablet) {
      @include span-columns(6 of 6);
    }
    @include media($desktop) {
      float: none;
      width: percentage(258/1140);
      @include order(1);
    }
  }
}
// Page Templates - NC Governor
//
// <ul>
//   <li><a href="templates/people-governor.html">Governor - Cabinet</a></li>
//   <li><a href="templates/governor-initiatives.html">Governor - Initiatives</a></li>
//   <li><a href="templates/governor-contact.html">Governor - Contact</a></li>
// </ul>
//
// Styleguide 2.7.6.1

// Page Templates - NC DCR 
//
// <ul>
//   <li><a href="templates/dcr-museums.html">NC DCR - Museums</a></li>
//   <li><a href="templates/dcr-news.html">NC DCR - News</a></li>
// </ul>
//
// Styleguide 2.7.6.2

// Page Templates - NC DHHS
//
// <ul>
//   <li><a href="templates/hhs-providers.html">NC DHHS - Providers</a></li>
//   <li><a href="templates/hhs-divisions.html">NC DHHS - Divisions</a></li>
//   <li><a href="templates/hhs-division-detail.html">NC DHHS - Division Detail</a></li>
// </ul>
//
// Styleguide 2.7.6.3

// Page Templates - NC OITS
//
// <ul>
//   <li><a href="templates/its-about.html">NC OITS - About</a></li>
//   <li><a href="templates/its-initiatives.html">NC OITS - Initiatives</a></li>
//   <li><a href="templates/its-services.html">NC OITS - Services</a></li>
//   <li><a href="templates/its-service-categories.html">NC OITS - Services Categories</a></li>
//   <li><a href="templates/its-services-detail.html">NC OITS - Service Detail</a></li>
//   <li><a href="templates/its-contact.html">NC OITS - Contact</a></li>
// </ul>
//
// Styleguide 2.7.6.4

// Page Templates - NC OSHR
//
// <ul>
//   <li><a href="templates/oshr-about.html">NC OSHR - About</a></li>
//   <li><a href="templates/oshr-benefits.html">NC OSHR - Benefits</a></li>
//   <li><a href="templates/oshr-employees.html">NC OSHR - Employees</a></li>
//   <li><a href="templates/oshr-ncflex.html">NC OSHR - NC Flex</a></li>
//   <li><a href="templates/oshr-policies.html">NC OSHR - Policies</a></li>
// </ul>
//
// Styleguide 2.7.6.5

// Page Templates - NC OSBM
//
// <ul>
//   <li><a href="templates/osbm-about.html">NC OSBM - About</a></li>
//   <li><a href="templates/osbm-budget.html">NC OSBM - Budget</a></li>
//   <li><a href="templates/osbm-facts-figures.html">NC OSBM - Facts &amp; Figures</a></li>
//   <li><a href="templates/osbm-statistics.html">NC OSBM - Statistics Archive</a></li>
//   <li><a href="templates/osbm-statistics-detail.html">NC OSBM - Statistics Detail</a></li>
// </ul>
//
// Styleguide 2.7.6.6

// Page Templates - NC DENR
//
// <ul>
//   <li><a href="templates/denr-outreach.html">NC DENR - Outreach &amp; Education</a></li>
//   <li><a href="templates/denr-permits.html">NC DENR - Permits &amp; Regulations</a></li>
//   <li><a href="templates/denr-division-landing-air-quality.html">NC DENR - Division of Air Quality</a></li>
//   <li><a href="templates/denr-division-landing-coastal-mgmt.html">NC DENR - Division of Coastal Management</a></li>
//   <li><a href="templates/denr-division-landing-energy.html">NC DENR - Division of Energy, Mineral and Land Resources</a></li>
//   <li><a href="templates/denr-division-landing-env-assistance.html">NC DENR - Division of Environmental Assistance and Customer Service</a></li>
//   <li><a href="templates/denr-division-landing-mitigation.html">NC DENR - Division of Mitigation Services</a></li>
//   <li><a href="templates/denr-division-landing-waste-mgmt.html">NC DENR - Division of Waste Management</a></li>
//   <li><a href="templates/denr-division-landing-water-resources.html">NC DENR - Division of Water Resources</a></li>
//   <li><a href="templates/denr-division-landing-marine.html">NC DENR - Division of Marine Fisheries</a></li>
//   <li><a href="templates/denr-division-landing-geo-survey.html">NC DENR - NC Geological Survey</a></li>
//   <li><a href="templates/denr-org-chart.html">NC DENR - Org Chart</a></li>
// </ul>
//
// Styleguide 2.7.6.7


// Page Templates - NC DPS
//
// <ul>
//   <li><a href="templates/dps-about.html">NC DPS - About</a></li>
//   <li><a href="templates/dps-prisons.html">NC DPS - Prisons</a></li>
//   <li><a href="templates/dps-services.html">NC DPS - Services</a></li>
//   <li><a href="templates/dps-emergency-management.html">NC DPS - Emergency Management</a></li>
//   <li><a href="templates/dps-law-enforcement.html">NC DPS - Law Enforcement</a></li>
//   <li><a href="templates/dps-adult-correction.html">NC DPS - Adult Correction</a></li>
// </ul>
//
// Styleguide 2.7.6.8


// Page Templates - NC DOA
//
// <ul>
//   <li><a href="templates/doa-citizens.html">NC DOA - Citizens</a></li>
//   <li><a href="templates/doa-businesses.html">NC DOA - Businesses</a></li>
//   <li><a href="templates/doa-govt-agencies.html">NC DOA - Government Agencies</a></li>
//   <li><a href="templates/doa-advocacy.html">NC DOA - Advocacy</a></li>
//   <li><a href="templates/doa-division-council-women.html">NC DOA - Division - Council for Women</a></li>
//   <li><a href="templates/doa-division-federal-surplus.html">NC DOA - Division - Federal Surplus Property</a></li>
//   <li><a href="templates/doa-division-hub.html">NC DOA - Division - HUB</a></li>
//   <li><a href="templates/doa-division-human-relations.html">NC DOA - Division - Human Relations Commission</a></li>
//   <li><a href="templates/doa-division-motor-fleet.html">NC DOA - Division - Motor Fleet</a></li>
//   <li><a href="templates/doa-division-non-public-education.html">NC DOA - Division - Non-public Education</a></li>
//   <li><a href="templates/doa-division-purchase-contract.html">NC DOA - Division - Purchase & Contract</a></li>
//   <li><a href="templates/doa-division-state-construction.html">NC DOA - Division - State Construction</a></li>
//   <li><a href="templates/doa-division-state-parking.html">NC DOA - Division - State Parking</a></li>
//   <li><a href="templates/doa-division-state-property.html">NC DOA - Division - State Property</a></li>
//   <li><a href="templates/doa-division-state-surplus.html">NC DOA - Division - State Surplus</a></li>
//   <li><a href="templates/doa-division-youth-advocacy.html">NC DOA - Division - Youth Advocacy</a></li>
//   <li><a href="templates/doa-division-indian-affairs.html">NC DOA - Division - Indian Affairs</a></li>
// </ul>
//
// Styleguide 2.7.6.9


// Media Gallery
//
// <ul>
//   <li><a href="templates/media-gallery.html">Media Gallery Landing</a></li>
//   <li><a href="templates/media-gallery-detail.html">Media Gallery Detail</a></li>
// </ul>
//
// Styleguide 2.7.8


// Contact
//
// <ul>
//   <li><a href="templates/contact.html">Contact</a></li>
// </ul>
//
// Styleguide 2.7.11


// News/Blog
//
// <ul>
//   <li><a href="templates/news.html">News/Blog Archive</a></li>
//   <li><a href="templates/news-article.html">News/Blog Article Detail</a></li>
// </ul>
//
// Styleguide 2.7.12

// Employee Directory
//
// <ul>
//   <li><a href="templates/employee-detail.html">Employee Directory Detail</a></li>
// </ul>
//
// Styleguide 2.7.12
