// Contextual Flyout Panel
//
// Displays info when activated, positioned on/near edge of page.
// 
// Markup: 
// <button class="flyout-trigger">Open Menu</button>
// <div class="flyout-panel" id="myFlyout">
//   <div aria-label="First level">
//     <button class="flyout-trigger close-trigger" aria-hidden="true"><span class="icon-close" aria-hidden="true"></span> Close</button>
//     <nav class="" role="navigation" aria-label="Filters">
//       <h3>Title or text</h3>
//       <ul>
//         <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//         <li><a href="#">Home</a></li>
//         <li>
//           <a href="#">About <span class="icon-chevron-right" aria-hidden="true"></span></a>
//           <div aria-label="Second level">
//             <h3 aria-hidden="true">About</h3>
//             <ul>
//               <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//               <li><a href="#">Overview</a></li>
//               <li><a href="#">Our Divisions</a></li>
//               <li><a href="#">Leadership Team <span class="icon-chevron-right" aria-hidden="true"></span></a>
//                 <div aria-label="Third level">
//                   <h3>Leadership Team</h3>
//                   <ul>
//                     <li class="flyout-trigger back-trigger" aria-hidden="true"><button href="#"><span class="icon-chevron-left" aria-hidden="true"></span> Back</button></li>
//                     <li><a href="#">Overview</a></li>
//                     <li><a href="#">Contact</a></li>
//                   </ul>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </li>
//         <li><a href="#">Education</a></li>
//         <li><a href="#">Resources</a></li>
//         <li><a href="#">News</a></li>
//       </ul>
//     </nav>
//   </div>
// </div>
// 
// Styleguide 4.2

.flyout-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  z-index: -1;

  > div {

    background: $color_core_blue;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(100%);

    @include media($tablet) {
      width: 300px;
      transform: translate3d(0, -300px, 0);
      overflow-x: hidden;
    }
    @include media($desktop) {
      transition: none !important;
    }

  }
  li > div {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background: $color_core_blue;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
  }
  &.active {
    z-index: 100;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    overflow-y: auto;
    //transition: all $animation-speed $animation-easing;
    
    div {
      transition: all $animation-speed $animation-easing;
    }
    div.active {
      transform: translateY(0);
      z-index: 100;
      @include media($tablet) {
        transform: translateX(0);
      }
    }
  }
  .sub-active {
    overflow: hidden;
  }
  .wrapper {
    padding: 0;
    margin: 0;
  }
}


