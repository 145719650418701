// Colors
//
// Enterprise color palettes should be used as a baseline across all
// institutions. Global notifications have their own separate palette 
// and are based on the severity of the message. Institutions can 
// include additional colors that can used to accent the core color palette.
// 
// Styleguide 1.2

// Core Palette
// 
// <ul class="styleguide colors">
//  <li class="color_core_black"><h5>Black</h5><p>#0b0c0c<br/>11, 12, 12</p></li>
//  <li class="color_core_blue"><h5>Blue</h5><p>#092940<br/>9, 41, 64</p></li>
//  <li class="color_core_gray"><h5>Gray</h5><p>#5f6c7b<br/>95, 108, 123</p></li>
//  <li class="color_core_light_gray"><h5>Light Gray</h5><p>#95a0ad<br/>149, 160, 173</p></li>
//  <li class="color_core_off_white"><h5>Off White</h5><p>#ecf0f3<br/>236, 240, 243</p></li>
//  <li class="color_core_white"><h5>White</h5><p>#ffffff<br/>255, 255, 255</p></li>
// </ul>
// 
// Styleguide 1.2.1

// Accent Palette
// 
// <ul class="styleguide colors">
//  <li class="color_accent_cool_gray"><h5>Cool Gray</h5><p>#6a7681<br/>106, 118, 129</p></li>
//  <li class="color_accent_warm_gray"><h5>Warm Gray</h5><p>#757575<br/>117, 117, 117</p></li>
//  <li class="color_accent_light_blue"><h5>Light Blue</h5><p>#3f7d97<br/>63, 125, 151</p></li>
//  <li class="color_accent_blue"><h5>Blue</h5><p>#397aac<br/>57, 122, 172</p></li>
//  <li class="color_accent_indigo"><h5>Indigo</h5><p>#00376d<br/>0, 55, 109</p></li>
//  <li class="color_accent_deep_purple"><h5>Deep Purple</h5><p>#662e6b<br/>102, 46, 107</p></li>
//  <li class="color_accent_purple"><h5>Purple</h5><p>#701c45<br/>112, 28, 69</p></li>
//  <li class="color_accent_red"><h5>Red</h5><p>#a83338<br/>168, 51, 56</p></li>
//  <li class="color_accent_orange"><h5>Orange</h5><p>#c05411<br/>192, 84, 17</p></li>
//  <li class="color_accent_olive"><h5>Olive</h5><p>#9a6f09<br/>154, 111, 9</p></li>
//  <li class="color_accent_green"><h5>Green</h5><p>#588023<br/>88, 128, 35</p></li>
//  <li class="color_accent_turquoise"><h5>Turquoise</h5><p>#3c807d<br/>60, 128, 125</p></li>
// </ul>
// 
// Styleguide 1.2.2

// Notification Palette
// 
// <ul class="styleguide colors">
//  <li class="color_alert_blue"><h5>Blue</h5><p>#397aac<br/>57, 122, 172</p></li>
//  <li class="color_alert_green"><h5>Green</h5><p>#588023<br/>88, 128, 35</p></li>
//  <li class="color_alert_orange"><h5>Orange</h5><p>#c05411<br/>192, 84, 17</p></li>
//  <li class="color_alert_red"><h5>Red</h5><p>#a83338<br/>168, 51, 56</p></li>
// </ul>
// 
// Styleguide 1.2.3

// Core Colors
$color_core_black: #0b0c0c;
$color_core_blue: #092940;
$color_core_gray: #6f7785;
$color_core_light_gray: #95a0ad;
$color_core_off_white: #ecf0f3;
$color_core_white: #ffffff;

// Accent Colors
$color_accent_cool_gray: #6a7681;
$color_accent_warm_gray: #757575;
$color_accent_light_blue: #3f7d97;
$color_accent_blue: #397aac;
$color_accent_indigo: #00376d;
$color_accent_deep_purple: #662e6b;
$color_accent_purple: #701c45;
$color_accent_red: #a83338;
$color_accent_orange: #c05411;
$color_accent_olive: #9a6f09;
$color_accent_green: #588023;
$color_accent_turquoise: #3c807d;

// Alert Colors
$color_alert_blue: $color_accent_blue;
$color_alert_green: $color_accent_green;
$color_alert_orange: $color_accent_orange;
$color_alert_red: $color_accent_red;

// Social Colors
$color_twitter_blue: #00aced;