/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: @gregfranko
 */

/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/

/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
  // font: 14px Helvetica, Arial;
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  // white-space: nowrap;
}

/* Button */
.selectboxit-container .selectboxit {
  width: 220px; /* Width of the dropdown button */
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  display: block;
  position: relative;
  transition: all $animation-speed $animation-easing;
}

/* Height and Vertical Alignment of Text */
.selectboxit-container span, .selectboxit-container .selectboxit-options a {
  // height: 30px; /* Height of the drop down */
  // line-height: 30px; /* Vertically positions the drop down text */
  display: block;
}

/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  outline: 0;
}

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}

/* Button Text */
.selectboxit-text {
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  height: 46px !important;
  line-height: 46px !important;
}

.selectboxit .selectboxit-option-icon-container {
  margin-left: 8px;
  height: 46px !important;
  line-height: 46px !important;
}

/* Options List */
.selectboxit-container .selectboxit-options {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 100%; /* Minimum Width of the dropdown list box options */
  *width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  z-index: 10;
  border-radius: 6px;
  text-align: left;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/* Individual options */
.selectboxit-option .selectboxit-option-anchor {
  padding: 0 2px;
  transition: all $animation-speed $animation-easing;
}

/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none;
}

/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
  text-indent: 0; /* Horizontal Positioning of the select box option text */
  margin: 0;
  list-style-type: none;
}

/* The first Drop Down option */
.selectboxit-option-first {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

/* The last Drop Down option */
.selectboxit-option-last {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
  font-weight: bold;
}

/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
  cursor: default;
}

/* Drop Down down arrow container */
.selectboxit-arrow-container {
  /* Positions the down arrow */
  width: 38px;
  position: absolute;
  right: 0;
  height: 46px !important;
  line-height: 46px !important;
}

/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  /* Horizontally centers the down arrow */
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
}

/* Drop Down down arrow for jQueryUI and jQuery Mobile */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
  top: 30%;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
  float: left;
}

.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
  width: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  float: left;
}

.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
  background-color: inherit;
}

/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
  background-position: -64px -16px;
}

/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/
.selectboxit-container {
  font-size: 14px;

}

.selectboxit-btn {
  min-width: 150px;
  background-color: $color_core_white;
  border: 2px solid $color_core_blue;
  border-radius: 4px;

}

.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus,
.selectboxit-btn.selectboxit-enabled:active {
  color: #333333;
  background-color: lighten($color_alert_blue, 50%);
  border: 2px solid $color_alert_blue;

  &.selectboxit-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: none;
  }

}

.selectboxit {
  .selectboxit-arrow-container .selectboxit-arrow {
    font-size: 24px;
    width: 24px;
    top: 50%;
    margin-top: -12px;
    margin-left: 0;
    transition: all $animation-speed $animation-easing;
  }

  &.selectboxit-open .selectboxit-arrow-container .selectboxit-arrow {
    transform: rotate(180deg);

  }
}

.selectboxit .selectboxit-option-icon-container i {
  font-size: 24px;
}

.selectboxit-list {
  background-color: #ffffff;
  border: 2px solid $color_alert_blue;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.selectboxit-container .selectboxit-options {

  /* Set's the drop down options width to the same width as the drop down button */
  width: 210px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  /* Set's the max-height property to only show a subset of the drop down items.
     If you do not set a max-height property, SelectBoxIt will dynamically
     position the dropdown (when opened) to make sure the drop down items are not
     displayed outside of the current window viewport.
  */
  max-height: 240px;

}

.selectboxit-list .selectboxit-option-anchor {
  color: $color_core_blue;

}

.selectboxit-container .selectboxit-options a {
  padding: 10px;
}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: $color_core_white;
  background-color: $color_alert_blue;
}

.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999;
}

select.error + .selectboxit-container {
  .selectboxit-btn {
    background: lighten($color_alert_red, 50%);
    border: 2px solid $color_alert_red;
  }
}