@import "base/colors";

////////////////////////////
// Variable Overrides
$accent_link: $color_accent_blue;
$accent_link_block: $color_accent_blue;
$accent_page_title: $color_accent_blue;
$accent_banner_slider: $color_accent_blue;
$accent_email_signup_color: $color_accent_blue;
$accent_email_signup_bg: url('../img/bg_poly-accent-blue.jpg');
$accent_stat_card_value: $color_accent_blue;

$menu-bg-color: $color_core_blue;
$menu-bg-poly: url('../img/bg_nav-core-blue.jpg');
///////////////////////////

@import "base/imports";

////////////////////////////
// Specific Agency Customizations Below