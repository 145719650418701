/*
 * jb overflow menu layout
 */

/*
 * the widget target must have width and height for this widget to work
 */
.jb-overflowmenu {}

.jb-overflowmenu-helper-postion {
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // overflow: visible;
  // position: absolute;
}

.jb-overflowmenu .jb-overflowmenu-menu-primary {
  /*overflow: hidden; gets set in js we need to detect the height of the menu, then set this */
  @include media($desktop) {
    //overflow-x: hidden;
    @include rem(padding-right, 76px);  
  }  
}

/*holds the overflow menu and label*/
.jb-overflowmenu .jb-overflowmenu-container {
  @include media($desktop) {
    display: inline;
    left: auto;
    bottom: 0px;  
  }  
}

.jb-overflowmenu .jb-overflowmenu-container .jb-overflowmenu-menu-secondary {
  @include media($desktop) {
    left: auto;
    right: 0;
    bottom: auto;
    display: none;
    position: absolute;
    z-index: 100;
    overflow: auto;
    line-height: 1.5;
    background: $color_core_white;
    border: 1px solid $color_core_light_gray;
    border-top: 0;
    @include rem(top, 80px);
    @include rem(width, 250px);
    @include rem(font-size, 16px);
  }

  li {
    @include media($desktop) {
      display: block;  
    }    
  
    a {
      @include media($desktop) {
        color: $accent_link;
        @include rem(padding, 20px);
        @include rem(font-size, 14px);  
      }      
    }
    a:hover {
      @include media($desktop) {
        color: saturate(lighten($accent_link, 5), 10);
        text-decoration: none;  
      }      
    }
  }
}

.jb-overflowmenu .jb-overflowmenu-container .jb-overflowmenu-menu-secondary-handle {
  display: none;

  @include media($desktop) {
    float: right;
    display: block;
    border-right: 1px solid rgba($color_core_off_white, .1);
    border-left: 1px solid rgba($color_core_off_white, .1);
    color: $color_core_white;
    text-decoration: none;
    @include rem(width, 76px);
    @include rem(height, 80px);
    @include rem(padding, 32px 20px);
    @include rem(font-size, 36px);
    @include rem(line-height, 16px);  
  }
  
  &:hover {
    @include media($desktop) {
      background-color: rgba(#000, .25);  
    }    
  }
}

.jb-overflowmenu div.jb-overflowmenu-container {
  @include media($desktop) {
    position: absolute;
    top: 0;
    right: 3.125%;
    @include rem(width, 76px);
  }  
  @include media($desktop_wide) {
    left: 50%;
    @include rem(margin-left, 494px);
  }
}

.jb-overflowmenu .placeholder {
  @include media($desktop) {
    visibility: hidden;  
  }  
}

.jb-overflow-disabled {
  &.jb-overflowmenu .jb-overflowmenu-menu-primary {
    @include media($desktop) {
      padding-right: 0;
    }
  }
  &.jb-overflowmenu div.jb-overflowmenu-container {
    @include media($desktop) {
      display: none;
    }
  }
}