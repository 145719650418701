.orgdiagram {
  outline: 0;
}

.bp-item {
  position: absolute;
  overflow: hidden;
  background-color: $color_core_off_white;
  //font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
  font-family: $primaryFont;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include rem(border-radius, $border_radius);
  transition: box-shadow $animation-speed $animation-easing;

  &:hover {
    box-shadow: 0 0 0 2px rgba(darken($color_core_light_gray, 10), .5);
  }
 
  .bp-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
    color: $color_core_white;
    padding: 0;
    font-family: $primaryFont;
    font-weight: bold;
    text-align: center;
    @include rem(padding, 8px);
    @include rem(font-size, 14px);
  }
  .bp-description {
    color: $color_core_gray;
    font-family: $primaryFont;
    line-height: 1.25;
    text-align: center;
    @include rem(padding, 8px);
    @include rem(font-size, 12px);
  }
  .bp-photo-frame {
    display: none;
    width: auto;
    background: $color_core_gray;
    color: #1c94c4;
  }

  // Vacant template
  &.vacant {
    font-style: italic;
  }

  // Level 0 template
  &.level-0 {

  }

}





.bp-cursor-frame {
  //border: 2px solid #fbd850;
  //background: white url(images/ui-bg_glass_65_ffffff_1x400.png) 50% 50% repeat-x;
  color: #eb8f00;
}

.bp-highlight-frame {
  //border: 1px solid #fbcb09;
  //background: #fdf5ce url(images/ui-bg_glass_100_fdf5ce_1x400.png) 50% 50% repeat-x;
  color: #c77405;
}

.bp-highlight-dot-frame {
  border: 1px solid #fbcb09;
  background: #fdf5ce;
  color: #c77405;
}

.bp-grouptitle-frame {
  background: #0000ff;
  border-width: 0;
  color: white;
  padding: 0;
}

.bp-selectioncheckbox-frame {
  font-size: 12px;
  line-height: 14px;
}

.bp-selectioncheckbox {
  font-size: 12px;
  line-height: 14px;
  vertical-align: middle;
}

.bp-selectiontext {
  font-size: 12px;
  line-height: 14px;
  vertical-align: middle;
}

.bp-readmore {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  text-decoration: none;
}

.bp-label {
  font-size: 10px;
  line-height: 12px;
  vertical-align: middle;
}

.bp-connector-label {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  color: black;
  font-weight: bold;
}

.bp-label-annotation {
  font-size: 16px;
  text-align: center;
}

.bp-badge {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  font-weight: bold;
  font-family: Arial;
  padding: 4px;
  float: left;
}

.bp-printpreview {
  position: absolute;
  overflow: hidden;
  border-right: 1px;
  border-bottom: 1px;
  border-right-style: dotted;
  border-bottom-style: dotted;
  border-right-color: #cccccc;
  border-bottom-color: #cccccc;
}