// Footer
// 
// Every page will contain a footer. It consists of a configurable <strong>Topical Area</strong> and a persistent <strong>Enterprise Area</strong>. Topical area must include the contact section. Up to 3 additional sections may be included in this area, such as social media links.
// 
// Styleguide 2.4

// Footer Navigation
// 
// Markup:
// <div class="footer-container">
//   <footer class="group">
//     <section class="band theme-core-blue topical">
//       <div class="wrapper">
//         <section class="parts-span-third">
//           <section class="contact inverted group">
//             <h3 class="section-title border">Information</h3>
//             <div itemscope itemtype="http://schema.org/Organization">
//               <span itemprop="name">North Carolina Department of Cultural Resources</span>
//               <div itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
//                 <span itemprop="streetAddress">301 N. Wilmington Street</span>,
//                 <span itemprop="addressLocality">Raleigh</span>,
//                 <span itemprop="addressRegion">NC</span>
//                 <span itemprop="postalCode">27601-1058</span>
//               </div>
//               <span itemprop="telephone">(919) 807-7300</span>
//             </div>
//           </section>
//           <section class="feed tweets">
//             <h3 class="section-title">Recent Tweets</h3>
//             <ol>
//               <li class="icon-twitter" itemscope itemtype="http://schema.org/UserTweets">
//                 <header>
//                   <a href="" itemprop="name url">Pat McCrory</a>
//                   <span itemprop="alternateName">@PatMcCroryNC</span> &bull; <span itemprop="startDate">14h</span>
//                 </header>
//                 <div itemprop="description"><a href="#">@Butterball</a> and Shalag US are brining more jobs to our state. Read about this and more in this week's newsletter&hellip;</div>
//               </li>
//               <li class="icon-twitter" itemscope itemtype="http://schema.org/UserTweets">
//                 <header>
//                   <a href="" itemprop="name url">Pat McCrory</a>
//                   <span itemprop="alternateName">@PatMcCroryNC</span> &bull; <span itemprop="startDate">14h</span>
//                 </header>
//                 <div itemprop="description"><a href="#">@Butterball</a> and Shalag US are brining more jobs to our state. Read about this and more in this week's newsletter&hellip;</div>
//               </li>
//             </ol>
//           </section>
//           <section class="social-links">
//             <h3 class="section-title border">Follow Us</h3>
//             <ul>
//               <li>
//                 <a href="http://www.facebook.com/example">
//                   <span class="icon-facebook" aria-hidden="true"></span> <span>Facebook</span>
//                 </a>
//               </li>
//               <li>
//                 <a href="http://www.twitter.com/example">
//                   <span class="icon-twitter" aria-hidden="true"></span> <span>Twitter</span>
//                 </a>
//               </li>
//               <li>
//                 <a href="http://www.youtube.com/example">
//                   <span class="icon-youtube" aria-hidden="true"></span> <span>YouTube</span>
//                 </a>
//               </li>
//               <li>
//                 <a href="http://www.example.com/feed">
//                   <span class="icon-feed" aria-hidden="true"></span> <span>RSS Feed</span>
//                 </a>
//               </li>
//             </ul>
//           </section>
//         </section>
//       </div>
//     </section>
//     <section class="band theme-core-blue pad-small enterprise">
//       <div class="wrapper">
//         <section>
//           <nav>
//             <ul class="group">
//               <li>
//                 <a href="#" title="NC.GOV Home">
//                   <span>NC.GOV Home</span> <span class="icon-chevron-right" aria-hidden="true"></span>
//                 </a>
//               </li>
//               <li>
//                 <a href="#" title="Employee Directory">
//                   <span>Employee Directory</span> <span class="icon-chevron-right" aria-hidden="true"></span>
//                 </a>
//               </li>
//               <li>
//                 <a href="#" title="Help and Support">
//                   <span>Help &amp; Support</span> <span class="icon-chevron-right" aria-hidden="true"></span>
//                 </a>
//               </li>
//               <li>
//                 <a href="#" title="Accessibility">
//                   <span>Accessibility</span> <span class="icon-chevron-right" aria-hidden="true"></span>
//                 </a>
//               </li>
//               <li>
//                 <a href="#" title="Terms of Use">
//                   <span>Terms of Use</span> <span class="icon-chevron-right" aria-hidden="true"></span>
//                 </a>
//               </li>
//               <li>
//                 <a href="#" title="Privacy Policy">
//                   <span>Privacy Policy</span> <span class="icon-chevron-right" aria-hidden="true"></span>
//                 </a>
//               </li>
//             </ul>
//           </nav>
//           <div class="brand">
//             <a href="http://www.nc.gov" title="NC.GOV Home"><img src="../img/l_nc-brand-footer.svg" alt="Nothing Compares. North Carolina"/></a>
//           </div>
//         </section>
//       </div>
//     </section>
//   </footer>
// </div>
// 
// Styleguide 2.4.1

.footer-container {

  // Topical Band
  .topical {
    
    .contact {
      span {}
      [itemprop="name"] {
        font-weight: 700;
        font-size: 22px;
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
      }
      [itemprop="address"] {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
      }
      [itemprop="telephone"] {
        font-weight: 700;
        font-size: 22px;
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  
    .section-title {
      margin-top: 0;

      @include media($desktop) {
        @include rem(font-size, 24px);
      }

      &:after {
        height: 1px;
        margin-top: 0;
      }
    }

  }

  // Enterprise Band
  .enterprise {
    background-color: $color_core_blue;
    text-align: center;
    position: relative;

    > a {
      position: absolute;
      left: 50%;
      @include rem(top, -24px);
      @include rem(width, 48px);
      @include rem(height, 48px);
      @include rem(margin-left, -24px);
    }
    ul {
      padding: 0;
      list-style: none;
      text-transform: uppercase;
      @include rem(margin, 0 0 30px);

      @include media($desktop) {
        @include rem(font-size, 12px);
      }

      li {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid $color_core_blue;
        margin: 0;

        @include media($tablet) {
          @include span-columns(3 of 6);
          @include omega(2n);
        }
        @include media($desktop) {
          float: none;
          border: 0;
          display: inline-block;
          width: auto;
          @include rem(margin, 0 15px);
        }

        &:first-child {
          border-top: 1px solid $color_core_blue;
          @include media($desktop) {
            border: 0;
          }
        }
        &:nth-child(2) {
          @include media($tablet) {
            border-top: 1px solid $color_core_blue;
          }
          @include media($desktop) {
            border: 0;
          }
        }
        &:nth-child(2n) {
          @include media($desktop) {
            @include rem(margin, 0 15px);
          }
        }
        a {
          color: $color_core_white;
          display: inline-block;
          padding: 15px 0;
          text-align: right;
          width: 100%;
          vertical-align: middle;
          transition: all .15s ease-in-out;

          @include media($desktop) {
            padding: 0;
          }

          span {
            transition: all .15s ease-in-out;
            display: inline-block;
            vertical-align: middle;

            &:first-child {
              padding-top: 10px;
              float: left;
              line-height: 1;

              @include media($desktop) {
                padding: 0;
                text-decoration: underline;
              }
            }
            &:first-child:hover {
              @include media($desktop) {
                text-decoration: none;
              }
            }
            &:last-child {
              font-size: 36px;
              margin-right: 15px;

              @include media($desktop) {
                display: none;
              }
            }
          }
          
        }
      }
    }
    .brand a {
      display: inline-block;
      .no-svg & {
        display: inline;
      }
      transition: opacity $animation-speed $animation-easing;
    }
    .brand a:hover {
      opacity: .8;
    }
    .brand img {
      @include rem(width, 286px);
      @include rem(height, 64px);
    }
  }
}