// Pagination
// 
// Pagination is a type of navigation that lets users tap through a series of related pages.
// 
// Styleguide 4.5

// Numerical Pagination
// 
// If pagination has more than 7 pages, use ellipsis at positions 2 and 6
// 
// Markup:
// <nav class="pagination numeric-pagination">
//   <ol>
//     <li class="disabled"><span class="icon-chevron-left" aria-hidden="true"></span> <span>Back</span></li>
//     <li class="active"><a href="#">1<span class="visuallyhidden"> (current)</span></a></li>
//     <li><a href="#">2</a></li>
//     <li><a href="#">3</a></li>
//     <li><a href="#">4</a></li>
//     <li><a href="#">5</a></li>
//     <li><span>&hellip;</span></li>
//     <li><a href="#">13</a></li>
//     <li><a href="#"><span>Next</span> <span class="icon-chevron-right" aria-hidden="true"></span></a></li>
//   </ol>
// </nav>
// <nav class="pagination numeric-pagination">
//   <ol>
//     <li><a href="#"><span class="icon-chevron-left" aria-hidden="true"></span> <span>Back</span></a></li>
//     <li><a href="#">1</a></li>
//     <li><span>&hellip;</span></li>
//     <li><a href="#">6</a></li>
//     <li class="active"><a href="#">7<span class="visuallyhidden"> (current)</span></a></li>
//     <li><a href="#">8</a></li>
//     <li><span>&hellip;</span></li>
//     <li><a href="#">131</a></li>
//     <li><a href="#"><span>Next</span> <span class="icon-chevron-right" aria-hidden="true"></span></a></li>
//   </ol>
// </nav>
// 
// Styleguide 4.5.1

//.pagination {
  // border-top: 3px solid $color_core_off_white;
  // text-align: center;
  // clear: both;
  // @include rem(margin, 30px 0 0);
  
  .pagination {
    text-align: center;
    display: block;
    padding: 0;
    list-style: none;
    margin: 0 0 20px;
    color: $color_core_gray;
    li {
      display: inline-block;
      //margin-right: -4px !important;
      a {
        display: inline-block;
        padding: 7px 10px;
        border-radius: 4px;
        line-height: 24px;
        text-decoration: none;

        &:hover {
          color: #092940;
        }
      }
      &.active {
        span {
          background: none;
          border: 1px solid $accent_link;
          color: $color_core_gray;
          padding: 5px 10px;
          text-decoration: none;
          display: inline-block;
          &:before {
            display: none;
          }
        }
      }
      &.disabled {
        span {
          padding: 5px 10px;
          color: $color_core_light_gray;
        }
        &:first-child {
          span {
            &:first-child {
              padding-left: 0;
              padding-right: 0;
            }
            &:last-child {
              padding-left: 0;
            }
          }
        }
        &:last-child {
          span {
            &:first-child {
              padding-right: 0;
            }
            &:last-child {
              padding-left: 0;
              padding-right: 0;
            }
          }
          [class^="icon-"] {
            float: right;
          }
        }
      }
      &:first-child {
        a {
          padding-left: 0;
          &:before {
            display: none;
          }
          span {
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              &:before {
                content: '';
                position: absolute;
                display: block;
                border-bottom: 1px solid $accent_link;
                width: 100%;
                bottom: 5px;
              }
              &:hover {
                &:before {
                  border: none;
                }
              }
            }
          }
        }
      }
      &:last-child {
        a {
          padding-right: 0;
          &:before {
            display: none;
          }
          span {
            &:first-child {
              &:before {
                content: '';
                position: absolute;
                display: block;
                border-bottom: 1px solid $accent_link;
                width: 100%;
                bottom: 5px;
              }
              &:hover {
                &:before {
                  border: none;
                }
              }
            }
            &:last-child {
              float: right;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  span {
    display: inline-block;
    position: relative;
  }
  [class^="icon-"], [class*=" icon-"] {
    font-size: 30px;
    color: $color_core_gray;
    float: left;
    margin-top: -5px;
  }
  .numeric-pagination {
    position: relative;
    ol {
      width: 100%;
      position: relative;
      li {
        display: none;
        margin: 0;
        &:first-child {
          float: left;
          display: inline-block;
          left: 0;
        }
        &:last-child {
          float: right;
          display: inline-block;
          right: 0;
        }
      }
      @include media($desktop) {
        li {
          display: inline-block;
          &:first-child,
          &:last-child {
            float: none;
          }
        }
      }
    }
    .mobile-pagination {
      position: absolute;
      display: inline-block;
      width: 100px;
      top: 20px;
      left: 50%;
      margin-left: -50px;
      text-align: center;
      div {
        display: inline-block;
        background: $accent_link;
        color: $color_core_white;
        padding: 5px 12px;
        border-radius: 4px;
      }
    }
    @include media($desktop) {
      .mobile-pagination {
        display: none;
      }
    }
  }
//}

// A-Z Pagination
// 
// Markup:
// <nav class="pagination alpha-pagination">
//   <ol>
//     <li ><a href="#">All</a></li>
//     <li class="active"><a href="#">A<span class="visuallyhidden"> (current)</span></a></li>
//     <li><a href="#">B</a></li>
//     <li><a href="#">C</a></li>
//     <li><a href="#">D</a></li>
//     <li><a href="#">E</a></li>
//     <li><a href="#">F</a></li>
//     <li><a href="#">G</a></li>
//     <li class="disabled"><span>H</span></li>
//     <li><a href="#">I</a></li>
//     <li><a href="#">J</a></li>
//     <li><a href="#">K</a></li>
//     <li><a href="#">L</a></li>
//     <li><a href="#">M</a></li>
//     <li><a href="#">N</a></li>
//     <li><a href="#">O</a></li>
//     <li><a href="#">P</a></li>
//     <li><a href="#">Q</a></li>
//     <li><a href="#">R</a></li>
//     <li><a href="#">S</a></li>
//     <li><a href="#">T</a></li>
//     <li><a href="#">U</a></li>
//     <li><a href="#">V</a></li>
//     <li><a href="#">W</a></li>
//     <li><a href="#">X</a></li>
//     <li><a href="#">Y</a></li>
//     <li><a href="#">Z</a></li>
//   </ol>
// </nav>
// 
// Styleguide 4.5.2

.pagination {
  &.alpha-pagination {
    ol {
      li {
        &:first-child,
        &:last-child {
          a {
            &:before {
              display: block;
            }
          }
        }
      }
    }
  }
}